import useTheme from '@material-ui/core/styles/useTheme';
import gql from 'graphql-tag';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {useEffect, useCallback, useState} from 'react';
import TextField from '../../components/TextField';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import MutationEditDialog from '../../fhg/components/data/MutationEditDialog';
import useEditData from '../../fhg/components/edit/useEditData';
import {MASTER_AREAS_INVENTORY_QUERY} from '../inventory/InventoryItemEdit';

import {MASTER_AREA_QUERY} from './AreaView';

const AREA_FRAGMENT = gql`
   fragment areaShortInfo on Area {
      id
      name
      description
      isDeleted
      isCategory
   }
`;

export const UPDATE_AREA = gql`
   mutation UpdateArea($id: Int!, $name: String, $templateId: Int, $description: String, $isCategory: Boolean) {
      area:area_Update(areaId: $id, area:{ name: $name, templateId: $templateId, description: $description,
         isCategory: $isCategory}) {
         ...areaShortInfo
      }
   }
   ${AREA_FRAGMENT}
`;

export const CREATE_AREA = gql`
   mutation CreateArea($name: String!, $templateId: Int!, $description: String, $isCategory: Boolean) {
      area:area_Create(area: {name: $name, templateId: $templateId, description: $description, isCategory: $isCategory}) {
         ...areaShortInfo
      }
   }
   ${AREA_FRAGMENT}
`;

AreaEdit.propTypes = {
   open: PropTypes.bool,
   isCreate: PropTypes.bool,
   useDescription: PropTypes.bool,
   onClose: PropTypes.func,
   area: PropTypes.object,
   templateId: PropTypes.number,    //Isn't being used. Defaults to 1.
   onSuccess: PropTypes.func,       //Callback on successful commit.
   isInventory: PropTypes.bool,
};

/**
 * The dialog to create and edit an Area.
 *
 * Reviewed: 9/19/19
 */
export default function AreaEdit({
   open, isCreate, useDescription = false, onClose, area, templateId = 1,
   onSuccess, isInventory = false, isForm = true
}) {
   const theme = useTheme();

   const [editValues, handleChange, {defaultValues, setDefaultValues}] = useEditData(area);
   const [isSaving, setIsSaving] = useState(false);

   useEffect(() => {
      if (isCreate) {
         setDefaultValues({});
      } else if (area) {
         setDefaultValues(area);
      }
   }, [area, isCreate, setDefaultValues]);

   /**
    * Submits the changes to the user.
    */
   const handleSubmit = useCallback(() => {
      const {name, description, isCategory} = editValues;

      setIsSaving(true);
      return {
         templateId,
         name,
         description: description === (area && area.name) ? null : description,
         id: get(area, 'id'),
         isCategory,
      };
   }, [templateId, area, editValues]);

   /**
    * On successful submit of area information.
    */
   const handleSuccess = useCallback(result => {
      setIsSaving(false);
      onSuccess && onSuccess(result);
   }, [onSuccess]);

   /**
    * Get the queries to refetch after the Area is edited.
    * @return {*[]}
    */
   const getRefetchQueries = () => {
      return [
         {query: MASTER_AREA_QUERY},
         {query: MASTER_AREAS_INVENTORY_QUERY},
      ];
   };

   const propertyName = useDescription ? 'description' : 'name';
   let defaultValue = get(area, propertyName) || get(area, 'name');

   return (
      <MutationEditDialog
         open={open}
         onClose={onClose}
         titleKey={isCreate ? 'area.new.title' : 'area.update.title'}
         submitKey={isCreate ? 'area.new.label' : 'area.update.label'}
         onSubmit={handleSubmit}
         onSuccess={handleSuccess}
         createMutation={CREATE_AREA}
         updateMutation={UPDATE_AREA}
         isCreate={isCreate}
         refetchQueries={getRefetchQueries}
         mutationKey={'area'}
         maxWidth={'xs'}
         fullWidth={true}
         isForm={isForm}
      >
         <TextField
            name={propertyName}
            label={'Name'}
            required
            autoFocus
            fullWidth
            defaultValue={defaultValue}
            value={editValues[propertyName]}
            onChange={handleChange}
            margin='none'
            disabled={isSaving}
         />
         {isInventory && (
            <CheckboxFHG
               key={'isCategory'}
               style={{marginLeft: theme.spacing(1)}}
               name={'isCategory'}
               onChange={handleChange}
               color={'default'}
               labelKey={'equipment.inventory.label'}
               value={'isCategory'}
               defaultChecked={defaultValues.isCategory}
               checked={editValues.isCategory}
               disabled={isSaving}
            />
         )}
      </MutationEditDialog>
   );
}
