import { useState, useEffect } from 'react';
import {loadingAndError} from './DataUtils';
import {useLazyQuery} from '@apollo/client';

export default function useLazyQueryFHG(query, options, prefix='') {
   const [statusComponent, setStatusComponent] = useState(null);

   const [queryFunction, {loading, error, data}] = useLazyQuery(query, options);

   useEffect(() => {
      setStatusComponent(!data ? loadingAndError(loading, error) : null);
      if (error) {
         console.log('useLazyQuery error', error);
      }
   }, [data, loading, error]);

   if (!prefix) {
      return [queryFunction, {loading, error, data, statusComponent}];
   } else {
      return [queryFunction, {[prefix+'Loading']: loading, [prefix + 'Error']: error, [prefix+'Data']: data, [prefix+'StatusComponent']: statusComponent}];
   }
}