import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import {useLayoutEffect, useRef} from 'react';
import {useIntl} from 'react-intl';
import {formatMessage} from '../../../fhg/utils/Utils';

am4core.useTheme(am4themes_animated);

export default function PieChart(props) {
  const intl = useIntl();
  const chartRef = useRef(null);

  useLayoutEffect(() => {
    const chart = am4core.create(chartRef.current, am4charts.PieChart3D);

    chart.data = props.data;

    const title = chart.titles.create();
    title.text = formatMessage(intl, props.titleKey, undefined, undefined);
    title.fontSize = 25;
    title.marginBottom = 10;

    const series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = "value";
    series.dataFields.category = "category";
    series.slices.template.propertyFields.fill = "color";

    return () => {
      chart.dispose();
    };
  }, []);

  return (
      <div ref={chartRef} style={{ width: "100%", height: "300px" }}></div>
  );
}