import {find} from 'lodash';
import {get} from 'lodash';
import {some} from 'lodash';

export function getEquipmentForQuestionType(equipmentList, questionTypeId, questionProperty = 'questionAliases', questionTypeIdPath = 'question.questionTypeId', masterEquipmentList) {
   let filteredEquipmentList = [];

   for (const equipment of equipmentList) {
      const hasSome = some(equipment[questionProperty], alias => get(alias, questionTypeIdPath) === questionTypeId);

      if (hasSome) {
         filteredEquipmentList.push(equipment);
      } else if (masterEquipmentList?.length > 0) {
         if (equipment?.equipmentMasterId) {
            const found = find(masterEquipmentList, {id: equipment?.equipmentMasterId});
            if (found) {
               filteredEquipmentList.push(equipment);
            }
         } else {
            const found = find(masterEquipmentList, {name: equipment?.name});
            if (found) {
               filteredEquipmentList.push(equipment);
            }
         }
      }
   }

   return filteredEquipmentList;
}

export function getAreasForQuestionType(newAreas, questionTypeId, questionProperty = 'questionAliases', questionTypeIdPath = 'question.questionTypeId') {
   let areaList = [];

   for (const area of newAreas) {
      const equipmentList = getEquipmentForQuestionType(area.equipment, questionTypeId, questionProperty, questionTypeIdPath);

      if (equipmentList.length > 0) {
         areaList.push(area);
      }
   }
   return areaList;
}
