import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Grid from '../Grid';
import Typography from '../Typography';

const styles = (theme) => ({
  contentStyle: {
    flex: '1 1 auto',
    overflow: 'auto',
  },
  contentStyleNoSroll: {
    '&:first-child': {
      paddingTop: 10,
    },
    flex: '1 1 auto',
    overflow: 'hidden',
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 0,
  },
  spinnerMargin: {
    marginLeft: theme.spacing(0.5),
  },
  actionStyle: {
    margin: 0,
    padding: theme.spacing(1),
    // backgroundColor: theme.palette.environment.light.level1.base,
    // borderTop: `1px solid ${theme.palette.environment.light.level1.accent}`,
    flex: '0 0 auto',
  },
  fatButtonStyle: {
    // padding: '12px 30px !important',
    margin: `0 0 0 ${theme.spacing(1)}px !important`,
    width: 'unset',
    height: 'unset',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      width: 'auto',
      height: 'auto',
      padding: '12px 24px !important',
    },
  },
  titleStyle: {
    // paddingBottom: theme.spacing(3),
    borderBottom: '1px solid #BFBAAE',
    flex: '0 0 auto',
  },
  innerStyle: {
    paddingTop: theme.spacing(1),
    height: '100%',
  },
  noScroll: {
    flex: '0 0 auto',
  },
  formStyle: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
});

/**
 * The New User dialog which creates a new user.
 */
class ModalDialog extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired, // Styles for the component.
    message: PropTypes.string, // Message to be displayed to the user. Use either message or messageKey but
    //    not both.
    messageKey: PropTypes.string, // Message key of the message to be displayed to the user.
    onSubmit: PropTypes.func, // Called when the user submits/confirms.
    onClose: PropTypes.func, // Called when the user closes/cancels.
    open: PropTypes.bool, // Indicates if the dialog should be open or not.
    title: PropTypes.string, // Title for the confirmation dialog.
    titleKey: PropTypes.string, // Localization key for the Title for the confirmation dialog.
    titleValues: PropTypes.object, // Values for the Title for the confirmation dialog.
    submitLabel: PropTypes.string, // Label for the submit button.
    submitKey: PropTypes.string, // Localization key for the submit button label.
    cancelLabel: PropTypes.string, // Label for the cancel button.
    cancelKey: PropTypes.string, // Localization key for the cancel button label.
    cancelColorStyle: PropTypes.string, // The class specifying the color of the cancel button. Needs color and
    //    background color for all states (e.g. primary-color-button).
    values: PropTypes.object, // Localization values for the message.
    isSaving: PropTypes.bool, // Indicates if the saving progress should be shown.
    isEnabled: PropTypes.bool, // Indicates if the submit button can be enabled. It won't be enabled if
    // isSaving is true.
    submitColorStyle: PropTypes.string, // The class specifying the color of the submit button. Needs color and
    //    background color for all states (e.g. primary-color-button).
    maxWidth: PropTypes.string, // The maximum width of the dialog.
    children: PropTypes.any, // Optional children components.
    isForm: PropTypes.bool, // Is the modal containing a form? If not, the enter key is handled.
    useCaptureKeydown: PropTypes.bool,
    contentsScroll: PropTypes.bool,
    variantSubmit: PropTypes.string,
    variantCancel: PropTypes.string,
  };

  static defaultProps = {
    open: true,
    isSaving: false,
    isEnabled: true,
    submitLabel: 'OK',
    submitKey: 'ok.button',
    cancelLabel: 'Cancel',
    cancelKey: 'cancel.button',
    submitColorStyle: 'primary-color-button',
    cancelColorStyle: 'minimal-cancel-button',
    maxWidth: 'md',
    isForm: false,
    useCaptureKeydown: true,
    contentsScroll: true,
    variantSubmit: 'text',
    variantCancel: 'text',
  };

  constructor(props, context) {
    super(props, context);

    document.addEventListener('keydown', this.handleKey, { capture: props.useCaptureKeydown });
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKey, {
      capture: this.props.useCaptureKeydown,
    });
  }

  handleKey = (event) => {
    const { onClose, open } = this.props;
    if (!event.defaultPrevented && open) {
      if (event.key === 'Escape' && onClose) {
        event.preventDefault();
        onClose(event);
      } else if (!this.props.isForm && event.key === 'Enter') {
        this.handleSubmit(event);
      }
    }
  };

  /**
   * Callback when the dialog is closed.
   * @param event The close dialog click event.
   */
  handleClose = (event) => {
    const { onClose } = this.props;

    if (event && !event.isDefaultPrevented()) {
      event.preventDefault();
      event.stopPropagation();

      onClose && onClose(event);
    }
  };

  handleClickSubmit = (event) => {
    if (event && !event.isDefaultPrevented()) {
      event.preventDefault();
      event.stopPropagation();

      this.handleSubmit(event);
    }
  };

  handleSubmit = (event) => {
    const { onSubmit } = this.props;
    event.preventDefault();
    onSubmit && onSubmit(event);
  };

  render() {
    const {
      classes,
      open,
      onClose,
      isSaving,
      isEnabled,
      titleKey,
      title,
      titleValues,
      submitKey,
      submitLabel,
      submitColor,
      messageKey,
      values,
      message,
      cancelKey,
      cancelLabel,
      submitColorStyle,
      maxWidth,
      fullWidth,
      children,
      isForm,
      cancelColorStyle,
      disableBackdropClick,
      onSubmit,
      buttons,
      contentsScroll,
      fullScreen,
      variantSubmit,
      variantCancel,
    } = this.props;

    const FormComponent = isForm ? 'form' : 'div';

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth}
        onKeyDown={this.handleKey}
        fullWidth={fullWidth}
        disableBackdropClick={disableBackdropClick}
        fullScreen={fullScreen}
      >
        <Grid container direction={'column'} wrap={'nowrap'}>
          <FormComponent onSubmit={this.handleSubmit} className={classes.formStyle}>
            {(title || titleKey) && (
              <DialogTitle disableTypography className={classes.titleStyle}>
                <Typography variant={'h6'} id={titleKey} values={titleValues}>
                  {title}
                </Typography>
              </DialogTitle>
            )}
            <DialogContent
              className={contentsScroll ? classes.contentStyle : classes.contentStyleNoSroll}
            >
              <Grid
                container
                className={contentsScroll ? classes.innerStyle : classes.noScroll}
                direction={'column'}
                wrap={'nowrap'}
              >
                {messageKey && (
                  <Typography id={messageKey} variant="subtitle1" values={values}>
                    {message}
                  </Typography>
                )}
                {children}
              </Grid>
            </DialogContent>
            <DialogActions className={classes.actionStyle} spacing={1}>
              {buttons}
              <Button
                className={`${classes.fatButtonStyle} button ${cancelColorStyle}`}
                disabled={isSaving}
                onClick={this.handleClose}
                onTouchStart={this.handleClose}
                variant={variantCancel}
              >
                <Typography color="inherit" id={cancelKey}>
                  {cancelLabel}
                </Typography>
              </Button>
              {!!onSubmit && (
                <Button
                  variant={variantSubmit}
                  className={`${classes.fatButtonStyle} button ${submitColorStyle}`}
                  type="submit"
                  disabled={isSaving || !isEnabled}
                  onClick={!isForm && this.handleClickSubmit}
                  onTouchStart={!isForm && this.handleClickSubmit}
                  color={submitColor}
                >
                  <Typography color="inherit" id={submitKey}>
                    {submitLabel}
                  </Typography>
                  {isSaving && (
                    <CircularProgress className={classes.spinnerMargin} size={15} thickness={2.5} />
                  )}
                </Button>
              )}
            </DialogActions>
          </FormComponent>
        </Grid>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ModalDialog);
