import {isArray, pick} from 'lodash';
import get from 'lodash/get';
import {useState, useEffect, useCallback} from 'react';
import {v4 as uuid} from 'uuid';
import {editChange, hasValue as hasValueUtil} from '../../utils/Utils';

export default function useEditData(defaultValuesProp, requiredEditValues = ['id', 'uuid'], isArrayType = false, onChange) {
   const [isChanged, setIsChanged] = useState(false);
   const [defaultValues, setDefaultValues] = useState(defaultValuesProp ? defaultValuesProp : isArrayType ? [] : {});
   const [editValues, setEditValues] = useState(
      isArrayType ? [] : !isArray(requiredEditValues) ? requiredEditValues : {});

   useEffect(() => {
      if (defaultValues && !isArrayType) {
         let requiredObject;
         if (isArray(requiredEditValues)) {
            requiredObject = pick(defaultValues, requiredEditValues);
         } else if (typeof requiredEditValues === 'object') {
            requiredObject = {...requiredEditValues};
         } else {
            requiredObject = {...defaultValues};
         }
         setEditValues({...editValues, ...requiredObject});
      }
      //editValues and requiredEditValues cause constant changes.
      // eslint-disable-next-line
   }, [defaultValues, isArrayType]);

   /**
    * Handle onChange events for the inputs.
    *
    * NOTE:
    * Input components MUST have their name set to be set in the editValues.
    *
    * @param event The event that changed the input.
    * @param value The value if the component is an Autocomplete
    * @param reason The reason of the value change if Autocomplete
    * @param newValue The value from the component.
    * @param name the name of the component.
    */
   const handleChange = (event, value, reason, newValue, name) => {
      let useValue = newValue;

      if (isArrayType) {
         handleArrayChange(event);
      } else {
         if (newValue) {
            useValue = {...editValues, ...newValue};
         } else {
            useValue = {...editValues, ...editChange(event, value, reason, true, newValue, name)};
         }

         setEditValues(useValue);

         if (reason !== 'reset') {
            onChange?.(useValue);
            setIsChanged(true);
         }
      }
      return useValue;
   };

   const handleArrayChange = (event) => {
      const index = get(event, 'target.dataset.index');
      const {componentName, newValue} = editChange(event, undefined, undefined, false);

      const edit = editValues[index];
      if (!edit) {
         let requiredValues = {};
         // Is there a default at each index?
         if (isArray(defaultValues)) {
            const defaultValue = get(defaultValues, `[${index}]`)
            if (defaultValue) {
               if (isArray(requiredEditValues)) {
                  requiredValues = pick(defaultValue, requiredEditValues);
               } else {
                  requiredValues = {uuid: defaultValue, id: defaultValue.id};
               }
            }
            //Is there a generic default?
         } else if (defaultValues) {
            if (isArray(requiredEditValues)) {
               requiredValues = pick(defaultValues, requiredEditValues);
               requiredValues.uuid = uuid();
            } else {
               requiredValues = {uuid: uuid(), id: defaultValues.id};
            }
         }
         editValues[index] = {[componentName]: newValue, ...requiredValues};
      } else {
         editValues[index] = {...edit, [componentName]: newValue};
      }
      setEditValues([...editValues]);
      setIsChanged(true);
      onChange?.([...editValues]);
   };

   const handleSelectChange = (value, name) => {
      setEditValues({...editValues, [name]: value});
      setIsChanged(true);
      onChange?.({...editValues, [name]: value});
   }

   const resetValues = useCallback(() => {
      setEditValues(isArrayType ? [] : {});
      setDefaultValues(isArrayType ? [] : {});
      setIsChanged(false);
   }, [isArrayType]);

   /**
    * Get the current value for the named property. If the value has been edited, it will return the edited value even
    * if it is null, and it will return the default value if not edited. If there is no default value, the default
    * value from the parameter is used.
    *
    * @Param path The path to the property
    * @Param defaultValue The default value to use if there isn't a edit or default value already.
    *
    * @type {function(*, *=): *}
    */
   const getValue = useCallback((path, defaultValue) => {
      const editValue = get(editValues, path);
      return editValue !== undefined ? editValue : get(defaultValues, path) || defaultValue;
   }, [editValues, defaultValues]);

   /**
    * Indicates if there is a value set for the property. If the default value is deleted, false will be returned even
    * though there is a defaultValue.
    *
    * @Param name The name of the property
    * @type {function(*=, *=): boolean}
    */
   const hasValue = useCallback((name) => {
      return hasValueUtil(getValue(name));
   }, [getValue]);

   return [
      editValues, handleChange,
      {
         handleSelectChange,
         isChanged,
         setIsChanged,
         setEditValues,
         defaultValues,
         setDefaultValues,
         resetValues,
         getValue,
         hasValue
      }
   ];
}
