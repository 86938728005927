import React, {useCallback, useMemo, useState} from 'react';
import {useDropzone} from 'react-dropzone';

import makeStyles from '@material-ui/core/styles/makeStyles';
import FHGTypography from '../Typography';
import {Close} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
   baseStyle: {
      position: 'relative',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2),
      borderWidth: 2,
      borderRadius: 2,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: theme.palette.type === 'dark' ? '#B5B5B5' : '#fafafa',
      color: theme.palette.type === 'dark' ? '#eeeeee' : '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out'
   },
   thumbsContainer: {
      maxWidth: "100%",
      maxHeight: "150px",
      objectFit: "cover",
      objectPosition: "center"
   },
   clearButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      width: 24,
      height: 24,
      zIndex: 1
   }
}), {name: 'StyledPhotoDropZoneStyles'});


const activeStyle = {
   borderColor: '#2196f3'
};

const acceptStyle = {
   borderColor: '#00e676'
};

const rejectStyle = {
   borderColor: '#ff1744'
};

export default function StyledDropZone({
   image: imageProp,
   maxFiles = 1,
   accept = ['image/*'],
   onDrop,
   labelKey = 'inspect.image.label'
}) {
   const classes = useStyles();
   const [image, setImage] = useState(imageProp);

   const handleDrop = useCallback((accepted, rejected) => {
      let newImage;

      if (accepted && accepted.length > 0) {

         newImage = accepted.map(file => Object.assign(file, {
            blob: URL.createObjectURL(file)
         }))[0];
         setImage(newImage);
      }
      onDrop?.(newImage, rejected);
   }, [onDrop]);

   const {getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} = useDropzone(
      {maxFiles, accept, onDrop: handleDrop});

   const style = useMemo(() => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
   }), [isDragActive, isDragReject, isDragAccept]);

   const clearImage = useCallback((event) => {
      event.stopPropagation();
      event.preventDefault();
      setImage(null);
      onDrop?.(null, null);
   }, [onDrop]);

   return (
      <div className='container'>
         <div {...getRootProps({style, className: classes.baseStyle})}>
            <input {...getInputProps()} />
            {image ? (
                <>
                   <IconButton onClick={clearImage} className={classes.clearButton}><Close/></IconButton>
                   <img className={classes.thumbsContainer} src={image?.blob || image} alt="profile"/>
                </>
            ) : (
               <FHGTypography id={labelKey}/>
            )}
         </div>
      </div>
   );
}

