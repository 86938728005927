/* eslint-disable react/prop-types, react/jsx-handler-names */

import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import NoSsr from '@material-ui/core/NoSsr';

import {withStyles, lighten} from '@material-ui/core/styles';
import {emphasize} from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import {DeleteOutline, Close, EditOutlined} from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import {isEqual, set, isFunction, isObject, memoize, isArray} from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import isObjectLike from 'lodash/isObjectLike';
import PropTypes from 'prop-types';
import React, {createRef} from 'react';
import Select, {createFilter} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import TextField from '../../components/TextField';
import ConfirmButton from './ConfirmButton';
import Grid from './Grid';

const defaultCanDoAction = () => true;
export const EDIT_ACTION = 'edit';
export const DELETE_ACTION = 'delete';

const styles = theme => ({
   root: {
      flex: 1,
      overflow: 'visible',
   },
   input: {
      display: 'flex',
      // position: 'absolute',
      left: 8,
   },
   multiSelect: {
     height: 'auto',
      padding: '8px 14px'
   },
   valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      alignSelf: 'center',
   },
   chip: {
      margin: theme.spacing(0.5, 0.25),
   },
   chipFocused: {
      backgroundColor: emphasize(
         theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
         0.08,
      ),
   },
   noOptionsMessage: {
      padding: theme.spacing(1, 2),
   },
   singleValue: {
      fontSize: 16,
   },
   placeholder: {
      position: 'absolute',
      left: 2,
      fontSize: 16,
      marginLeft: 16,
      opacity: 0.42,
   },
   paper: {
      position: 'absolute',
      zIndex: 10000,
      marginTop: theme.spacing(1),
      left: 0,
      width: '100%',
   },
   divider: {
      height: theme.spacing(2),
   },
   inputStyle: {
      boxSizing: 'content-box',
      width: 2,
      background: '0px center',
      border: 0,
      fontSize: 'inherit',
      opacity: 1,
      outline: 0,
      padding: 0,
      color: 'inherit',
   },
   deleteColorStyle: {
      color: theme.palette.error.main,
      '&:hover': {
         backgroundColor: lighten(theme.palette.error.light, 0.8),
      }
   },
});

function NoOptionsMessage(props) {
   return (
      <Typography
         color="textSecondary"
         className={props.selectProps.classes.noOptionsMessage}
         {...props.innerProps}
      >
         {props.children}
      </Typography>
   );
}

function inputComponent({ inputRef, ...props }) {
   return <div ref={inputRef} {...props} />;
}

function Control(props) {
   return (
      <TextField
         name={props.name+'Control'}
         fullWidth
         style={{zIndex:2}}
         margin={props.margin || 'none'}
         InputProps={{
            inputComponent,
            inputProps: {
               className: classNames(props.selectProps.classes.input, {
                  [props.selectProps.classes.multiSelect]: props.selectProps.isMulti
               }),
               inputRef: props.innerRef,
               children: props.children,
               ...props.innerProps,
            },
         }}
         {...props.selectProps.textFieldProps}
      />
   );
}

function Option(props) {
   return (
      <MenuItem
         buttonRef={props.innerRef}
         selected={props.isFocused}
         component="div"
         style={{
            fontWeight: props.isSelected ? 500 : 400,
            zIndex: 2001,
         }}
         {...props.innerProps}
      >
         {props.children}
      </MenuItem>
   );
}

function Placeholder(props) {
   return (
      <Typography
         color="textSecondary"
         className={props.selectProps.classes.placeholder}
         {...props.innerProps}
      >
         {props.children}
      </Typography>
   );
}

function SingleValue(props) {
   return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
         {props.children}
      </Typography>
   );
}

function ValueContainer(props) {
   return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
   return (
      <Chip
         tabIndex={-1}
         label={props.children}
         className={classNames(props.selectProps.classes.chip, {
            [props.selectProps.classes.chipFocused]: props.isFocused,
         })}
         disabled={props.selectProps.isDisabled}
         onDelete={props.removeProps.onClick}
         onClick={(event) => props.selectProps.onClick(event, props.data)}
         deleteIcon={<CancelIcon {...props.removeProps} />}
      />
   );
}

// function Menu(props) {
//    return (
//       <Paper id={'M'}square className={props.selectProps.classes.paper} {...props.innerProps}>
//          {props.children}
//       </Paper>
//    );
// }

const ClearIndicator = (props) => {

   const handleEdit = event => {
      event.preventDefault();
      event.stopPropagation();

      props.setValue(props.getValue(), "edit-value", {type: EDIT_ACTION});
   }

   const handleDelete = event => {
      event.preventDefault();
      event.stopPropagation();

      props.setValue(props.getValue(), "remove-value", {type: DELETE_ACTION});
   }

   const handleConfirmedDelete = () => {
      props.setValue(props.getValue(), "remove-value", {type: DELETE_ACTION});
   }

   const handleClear = event => {
      event.preventDefault();
      event.stopPropagation();

      props.clearValue();
   }

   let isClearableOption;
   let isDeletableOption;
   let isConfirmDeletableOption;
   let isEditableOption;
   const {value, onCanDoAction} = props.selectProps;

   if (props.selectProps.actionOptions){
      const {isClearable=false, isDeletable=false, isEditable=false, isConfirmDeletable=false}  = props.selectProps.actionOptions;
      isClearableOption = isClearable;
      isDeletableOption = isDeletable && onCanDoAction(value, DELETE_ACTION);
      isConfirmDeletableOption = isConfirmDeletable && onCanDoAction(value, DELETE_ACTION);
      isEditableOption = isEditable && onCanDoAction(value, EDIT_ACTION);
   } else {
      isEditableOption = false;
      isDeletableOption = false;
      isConfirmDeletableOption = false;
      isClearableOption = props.selectProps.isClearable;
   }

   const useValue = isArray(value) ? value[0] : value;
   let type = useValue?.__typename || '';

   //Insert a space between words and make them lower case.
   type = type.replace(/([A-Z])/g, ' $1');
   type = type.toLowerCase();

   return (
      <Grid container item>
         {isClearableOption && (
            <Grid item>
               <IconButton name={props.name+'ClearButton'} onMouseDown={handleClear} onTouchStart={handleClear} size={'small'} i>
                  <Close  style={{cursor: 'pointer'}}/>
               </IconButton>
            </Grid>
         )}
         {isDeletableOption && (
            <Grid item>
               <IconButton name={props.name+'DeleteButton'} onMouseDown={handleDelete} onTouchStart={handleDelete} size={'small'}>
                  <DeleteOutline style={{cursor: 'pointer'}}/>
               </IconButton>
            </Grid>
         )}
         {isConfirmDeletableOption && (
            <Grid item>
               <ConfirmButton onConfirm={handleConfirmedDelete} buttonLabelKey={'select.delete.title'}
                              // style={{padding: 6, color: theme.palette.error.dark}} color={theme.palette.error.dark}
                              component={IconButton} messageKey={'select.delete.confirm'}
                              values={{type, label: useValue.label}}
                              titleKey={'select.delete.title'} size={'small'} confirmProps={{submitColorStyle: props.selectProps.classes.deleteColorStyle}}
               >
                  <DeleteOutline style={{cursor: 'pointer'}}/>
               </ConfirmButton>
            </Grid>
         )}
         {isEditableOption && (
            <Grid item>
               <IconButton name={props.name+'EditButton'} onMouseDown={handleEdit} onTouchStart={handleEdit} size={'small'}>
                  <EditOutlined style={{cursor: 'pointer'}}/>
               </IconButton>
            </Grid>
         )}
      </Grid>
   )
};


const components = {
   Control,
   // Menu,
   MultiValue,
   NoOptionsMessage,
   Option,
   Placeholder,
   SingleValue,
   ClearIndicator,
   ValueContainer,
};

class ReactSelect extends React.PureComponent {
   static propTypes = {
      classes: PropTypes.object.isRequired,
      theme: PropTypes.object.isRequired,
      isMulti: PropTypes.bool,
      onDelete: PropTypes.func,
      onEditChange: PropTypes.func,
      onCanDoAction: PropTypes.func,
   };
   static defaultProps = {
      isMulti: true,
   };

   constructor(props) {
      super(props);

      this.selectRef = createRef();
      this.state = {
         // editValue: null,
         options: this.mapOptions(props),
         isChanged: props.value !== undefined && (!isArray(props.value) || props.value.length > 0),
      };
   }

   static createOptions(labelArray) {
      const results = [];
      if (labelArray && labelArray.length > 0) {
         for (const labelArrayElement of labelArray) {
            results.push({label: labelArrayElement, value: labelArrayElement});
         }
      }
      return results;
   }

   static createOptionsFromObject(objectArray, labelProperty='name', valueProperty='id') {
      const results = [];
      if (objectArray && objectArray.length > 0) {
         for (const objectArrayElement of objectArray) {
            results.push({...objectArrayElement, label: isFunction(labelProperty) ? labelProperty(objectArrayElement) : objectArrayElement[labelProperty], value: objectArrayElement[valueProperty]});
         }
      }
      return results;
   }

   static createValueFromIdList(idList = [], optionsList = [], labelProperty='label', valueProperty='value') {
      let results = [];

      if (idList && optionsList && idList.length > 0 && optionsList.length > 0) {
         for (const idItem of idList) {
            const found = find(optionsList, {id: isObject(idItem) ? idItem.id : idItem});
            if (found) {
               results.push(found);
            }
         }
      }
      return results;
   }

   /**
    * Create the value as an object that matches the options from the single value. Useful when the value is an id to
    * find the option that has that value.
    *
    * @type {(function(*=, *=, *=, *=): {label: (*|string), value: any}) & MemoizedFunction}
    */
   static createObjectValue = (value, optionsList = [], labelProperty='label', valueProperty='value') => {
      let results = value;

      if (value && optionsList.length > 0 && !isObject(value)) {
         const found = find(optionsList, {[valueProperty]: value});

         results = {label: found ? found[labelProperty] : '', value, ...found};
      }

      return results;
   };

   componentWillReceiveProps(nextProps) {
      if (!isEqual(nextProps.options, this.props.options)) {
         this.setState({options: this.mapOptions(nextProps)});
      }
      if (!isEqual(nextProps.value, this.props.value) && nextProps.value) {
         const firstOption = get(this.mapOptions(nextProps || this.props), '[0]');

         if (!isObject(nextProps.value) && (firstOption && isObject(firstOption))) {

         }
         this.setState({isChanged: true});
      }
   }

   mapOptions = (props) => {
      let options = [];

      if (props.options && props.options.length > 0) {
         if (get(props, 'options[0].label') === undefined) {
            options = props.options.map(this.getMenuItem(props));
         } else {
            options = props.options;
         }
      }
      return options;
   };

   getMenuItem = props => item => {
      return {label: this.getLabel(props, item), value: this.getValue(item, props), ...item};
   };

   getLabel = (props, item) => {
      if (typeof item === 'string') {
         return item;
      }
      return props.getLabel ? props.getLabel(item) : item[props.labelKey || 'label'];
   };

   getValue = (item, props = this.props) => {
      if (typeof item === 'string') {
         return item;
      }
      return item ? item[props.valueKey || 'value'] : undefined;
   };

   getDefaultValue = () => {
      const { defaultValue } = this.props;
      const { isCleared } = this.state;
      if (!isCleared) {
         if (isObjectLike(defaultValue)) {
            return defaultValue;
         }
         return find(this.state.options, ['value', this.props.defaultValue]);
      } else {
         return undefined;
      }
   };

   handleChange = (value, options) => {
      let useValue = value;
      if (options.action === "remove-value" && get(options, 'option.type') === DELETE_ACTION) {
         if (!get(value, '[0].__isNew__')) {
            this.props.onDelete && this.props.onDelete(value);
         }
         useValue = null;
      }
      if (options.action === "edit-value" && get(options, 'option.type') === EDIT_ACTION) {
         this.props.onEditChange && this.props.onEditChange(value);
      }
      if (useValue === null && this.props.isClearable) {
         this.setState({isCleared: true});
      }
      this.setState({isChanged: true});
      const {onChange} = this.props;
      onChange && onChange(useValue, this.props.name, this.props.isMulti, options);
   };

   handleSelectChange = (event, options)  => {
      const {onChange} = this.props;
      if (onChange) {
         set(event, 'target.name', this.props.name+'_Edit');
         onChange(event, this.props.name+'_Edit', this.props.isMulti);
      }
   }

   formatCreateLabel = (inputValue) => (
      <span><b>CREATE</b>{' '}{`"${inputValue}"`}</span>
   );

   render() {
      const {
         id, classes, styles, theme, isClearable = false, autoFocus, menuIsOpen, menuPosition = 'fixed', disabled, label,
         placeholder, onCreateOption, getOptionLabel, getOptionValue, isMulti, escapeClearsValue, isCreatable = false,
         minMenuHeight, maxMenuHeight, fullWidth = false, defaultValue, closeMenuOnSelect, noOptionsMessage,
         onMultiClick, openMenuOnClick, menuPlacement = 'auto', isLoading, backspaceRemovesValue, isDeletable, isConfirmDeletable,
         closeMenuOnScroll, name, onCanDoAction = defaultCanDoAction, isEditable = false, onEditChange, onCanEdit, ...textFieldProps
      } = this.props;

      const { options, isChanged } = this.state;

      const selectStyles = {
         ...styles,
         menu: (base) => ({
            ...base,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[8],
         }),
         //Move the select container above sticky row headers.
         container: () => ({
            '& > div': {
               zIndex: 2000,
            }
         }),
         input: base => ({
            ...base,
            color: theme.palette.text.primary,
            '& input': {
               font: 'inherit',
            },
         }),
         indicatorSeparator: base => ({
            ...base,
            color: 'transparent',
            backgroundColor: 'transparent',
         }),
      };
      const SelectComponent = isCreatable ? CreatableSelect : Select;
      const usePlaceholder = placeholder || '';
      const useValue = !isChanged ? this.getDefaultValue() || '' : this.props.value;
      return (
         <div className={classes.root}>
            <NoSsr>
               <SelectComponent
                  name={name}
                  menuIsOpen={menuIsOpen}
                  menuPosition={menuPosition}
                  openMenuOnClick={openMenuOnClick}
                  closeMenuOnScroll={closeMenuOnScroll}
                  closeMenuOnSelect={closeMenuOnSelect}
                  noOptionsMessage={noOptionsMessage}
                  isClearable={isClearable || isDeletable || isEditable || isConfirmDeletable}
                  actionOptions={{isDeletable, isClearable, isEditable, isConfirmDeletable}}
                  onEditChange={onEditChange}
                  autoFocus={autoFocus}
                  classes={classes}
                  styles={selectStyles}
                  isDisabled={disabled}
                  ref={this.selectRef}
                  textFieldProps={{
                     ...textFieldProps,
                     selectRef: this.selectRef,
                     label,
                     disabled,
                     fullWidth,
                     onChange: this.handleSelectChange,
                     InputLabelProps: {
                        shrink: !!usePlaceholder || !!this.props.value || !!defaultValue ? true : undefined,
                     },
                  }}
                  options={options}
                  getOptionLabel={getOptionLabel}
                  getOptionValue={getOptionValue || this.getValue}
                  components={components}
                  value={useValue}
                  onChange={this.handleChange}
                  onClick={onMultiClick}
                  onCreateOption={onCreateOption}
                  onCanDoAction={onCanDoAction}
                  placeholder={usePlaceholder}
                  isMulti={isMulti}
                  escapeClearsValue={escapeClearsValue}
                  menuPlacement={menuPlacement}
                  minMenuHeight={minMenuHeight}
                  maxMenuHeight={maxMenuHeight}
                  backspaceRemovesValue={backspaceRemovesValue}
                  isLoading={isLoading}
                  filterOption={createFilter({
                     matchFrom: isCreatable ? 'any' : 'start'
                  })}
                  formatCreateLabel={this.formatCreateLabel}
               />
            </NoSsr>
         </div>
      );
   }
}

export default withStyles(styles, { withTheme: true })(ReactSelect);
