import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import ModalDialog from '../../../fhg/components/dialog/ModalDialog';
import PieChart from './PieChart';
import * as am4core from '@amcharts/amcharts4/core';
import Grid from '@material-ui/core/Grid';
import useQueryFHG from '../../../fhg/components/data/useQueryFHG';
import {REPORT_QUERY} from './ComprehensiveInclusiveAudit';

export default function InclusiveAuditChartsDialog(props) {
  const [chartList, setChartList] = useState([]);
  const {loading, data, statusComponent} = useQueryFHG(REPORT_QUERY, {
    variables: {reportId: props.report.id},
    fetchPolicy: 'cache-and-network'
  });

  /**
   * Builds the pie charts.
   */
  useEffect(() => {
    if (data) {
      buildCharts();
    }
  }, [data]);

  /**
   * Builds the pie charts.
   */
  function buildCharts() {
    const mapOfOpportunityTypeStatistics = {
      mapOfEntries: {
        'total': 0
      },
      mapOfOpportunityTypes: {}
    };
    const gridAnswers = data.gridAnswers.filter((gridAnswer) => gridAnswer.entry > 0);

    data.opportunityTypes.forEach((opportunityType) => {
      const filteredGridAnswers = gridAnswers.filter(
          (gridAnswer) => gridAnswer.opportunity.opportunityTypeId === opportunityType.id && gridAnswer.entry > 0);

      if (!filteredGridAnswers.length) {
        return;
      }

      const data = {
        opportunityType,
        mapOfEntries: {
          'total': 0
        }
      };
      mapOfOpportunityTypeStatistics.mapOfOpportunityTypes[opportunityType.id] = data;

      mapOfOpportunityTypeStatistics.mapOfEntries.total += filteredGridAnswers.length;
      data.mapOfEntries.total += filteredGridAnswers.length;

      filteredGridAnswers.forEach((gridAnswer) => {
        if (data.mapOfEntries[gridAnswer.entry]) {
          data.mapOfEntries[gridAnswer.entry]++;
        } else {
          data.mapOfEntries[gridAnswer.entry] = 1;
        }

        if (mapOfOpportunityTypeStatistics.mapOfEntries[gridAnswer.entry]) {
          mapOfOpportunityTypeStatistics.mapOfEntries[gridAnswer.entry]++;
        } else {
          mapOfOpportunityTypeStatistics.mapOfEntries[gridAnswer.entry] = 1;
        }
      });
    });

    setChartList([
      {
        titleKey: 'inclusiveInspection.opportunityDistribution.label',
        data: getChartDataForEntry(mapOfOpportunityTypeStatistics, 'total')
      },
      {
        titleKey: 'inclusiveInspection.primaryPurpose.label',
        data: getChartDataForEntry(mapOfOpportunityTypeStatistics, 1)
      },
      {
        titleKey: 'inclusiveInspection.secondaryPurpose.label',
        data: getChartDataForEntry(mapOfOpportunityTypeStatistics, 2)
      }
    ]);
  }

  /**
   * Gets the chart data for an entry value.
   *
   * @param {Object} mapOfOpportunityTypeStatistics Map of opportunity type statistics.
   * @param {string} entry Entry value.
   * @returns {Object[]} Chart data.
   */
  function getChartDataForEntry(mapOfOpportunityTypeStatistics, entry) {
    const data = [];

    Object.keys(mapOfOpportunityTypeStatistics.mapOfOpportunityTypes).forEach((id) => {
      if (mapOfOpportunityTypeStatistics.mapOfOpportunityTypes[id].mapOfEntries[entry]) {
        data.push({
          value: mapOfOpportunityTypeStatistics.mapOfOpportunityTypes[id].mapOfEntries[entry],
          category: mapOfOpportunityTypeStatistics.mapOfOpportunityTypes[id].opportunityType.name.replaceAll(' ', '\n'),
          color: am4core.color(mapOfOpportunityTypeStatistics.mapOfOpportunityTypes[id].opportunityType.color)
        });
      }
    });

    return data.length ? data : null;
  }

  if (loading) {
    return statusComponent;
  }

  return (
      <ModalDialog
          open
          onClose={props.onClose}
          titleKey="inclusiveInspection.pieCharts.title"
          maxWidth="md"
          fullWidth
          cancelKey="close.button"
      >
        <Grid container direction="column" spacing={4}>
          {
            chartList.map((chart, index) =>
                chart.data ? <Grid item><PieChart key={index} data={chart.data} titleKey={chart.titleKey}/></Grid> : null)
          }
        </Grid>
      </ModalDialog>
  );
}

InclusiveAuditChartsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired
};
