import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import TableCell from '@material-ui/core/TableCell';
import {FormControl, MenuItem, Select} from '@material-ui/core';
import classNames from 'classnames';
import gql from 'graphql-tag';
import useMutationFHG from '../../../fhg/components/data/useMutationFHG';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    minWidth: 45,
    width: 45,
    userSelect: 'none'
  },
  readOnly: {
    border: '1px solid #c4c4c4',
    textAlign: 'center'
  },
  value: {
    display: 'inline-block'
  },
  select: {
    width: 45,
    height: 45,
    '& > div': {
      padding: '0 !important',
      width: 45,
      height: 45
    },
    '& $menuItem': {
      marginTop: 8,
      marginLeft: 8
    }
  },
  menuItem: {
    height: 30,
    width: 30,
    lineHeight: '25px',
    textAlign: 'center',
    borderRadius: 30,
    border: '3px solid',
    fontSize: 18,
    fontWeight: 'bold',
    boxSizing: 'border-box'
  },
  menuItemPrimary: {
    borderColor: '#728c39',
    color: '#728c39'
  },
  menuItemSecondary: {
    borderColor: '#da670a',
    color: '#da670a'
  },
  menuItemTertiary: {
    borderColor: '#345c8b',
    color: '#345c8b'
  }
}));

const GRID_ANSWER_CREATE = gql`
  mutation createGridAnswer($reportId: Int!, $equipmentId: Int!, $opportunityId: UUID, $entry: Int!) {
    gridAnswer: gridAnswer_Create(gridAnswer: {reportId: $reportId, equipmentId: $equipmentId, opportunityId: $opportunityId, entry: $entry}) {
      id
      equipmentId
      opportunityId
      entry
    }
  }
`;

const GRID_ANSWER_UPDATE = gql`
  mutation updateGridAnswer($id: Int!, $reportId: Int!, $entry: Int!) {
    gridAnswer: gridAnswer_Update(gridAnswerId: $id, gridAnswer: {reportId: $reportId, entry: $entry}) {
      id
      equipmentId
      opportunityId
      entry
    }
  }
`;

const GRID_ANSWER_DELETE = gql`
  mutation deleteGridAnswer($id: Int!) {
    gridAnswer_Delete(gridAnswerId: $id)
  }
`;

/**
 * Component to display a cell for an equipment inclusive opportunity.
 */
export default function EquipmentInclusiveOpportunityCell(props) {
  const classes = useStyles();
  const [gridAnswer, setGridAnswer] = useState(props.gridAnswer);
  const [saving, setSaving] = useState(false);
  const [value, setValue] = useState(gridAnswer?.entry || 0);
  const [gridAnswerCreate] = useMutationFHG(GRID_ANSWER_CREATE);
  const [gridAnswerUpdate] = useMutationFHG(GRID_ANSWER_UPDATE);
  const [gridAnswerDelete] = useMutationFHG(GRID_ANSWER_DELETE);
  const styles = {
    color: props.color
  };

  /**
   * Updates the grid answer.
   *
   * @param {Object} event onChange event.
   */
  async function updateGridAnswer(event) {
    const entry = event.target.value;

    if (saving || entry === value) {
      return;
    }

    setValue(entry);
    setSaving(true);

    try {
      if (gridAnswer) {
        if (entry) {
          const result = await gridAnswerUpdate({
            variables: {
              id: gridAnswer.id,
              reportId: props.report.id,
              entry
            }
          });
          setSaving(false);

          if (result?.data?.gridAnswer) {
            setGridAnswer(result.data.gridAnswer);
          }
        } else {
          await gridAnswerDelete({
            variables: {
              id: gridAnswer.id
            }
          });
          setSaving(false);
          setGridAnswer(null);
        }
      } else {
        const result = await gridAnswerCreate({
          variables: {
            reportId: props.report.id,
            opportunityId: props.opportunity.id,
            equipmentId: props.equipment.id,
            entry
          }
        });
        setSaving(false);

        if (result?.data?.gridAnswer) {
          setGridAnswer(result.data.gridAnswer);
        }
      }
    } catch (error) {
      console.log('Error updating gridAnswer', error);
      setSaving(false);
    }
  }

  return (
      <TableCell className={classNames(classes.root, {
        [classes.readOnly]: !props.isEditable
      })}>
        {props.isEditable ? (
            <FormControl variant="outlined">
              <Select
                  IconComponent={() => null}
                  className={classes.select}
                  value={value}
                  onChange={updateGridAnswer}
              >
                <MenuItem value={0}>&nbsp;</MenuItem>
                <MenuItem value={1}>
                  <div className={classes.menuItem} style={styles}>1</div>
                </MenuItem>
                <MenuItem value={2}>
                  <div className={classes.menuItem} style={styles}>2</div>
                </MenuItem>
                <MenuItem value={3}>
                  <div className={classes.menuItem} style={styles}>3</div>
                </MenuItem>
              </Select>
            </FormControl>
        ) : (
            <div className={classNames(classes.value, {
              [classes.menuItem]: Boolean(value)
            })} style={value ? styles : undefined}>{value || ''}</div>
        )}
      </TableCell>
  );
}

EquipmentInclusiveOpportunityCell.propTypes = {
  report: PropTypes.object.isRequired,
  opportunity: PropTypes.object.isRequired,
  equipment: PropTypes.object.isRequired,
  gridAnswer: PropTypes.object,
  isEditable: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired
};
