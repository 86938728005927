import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import get from 'lodash/get';
import React from 'react'
import TextField from '../../components/TextField';
import FancyRectangle from '../../react-image-annotation-edit-delete-annotations/src/components/FancyRectangle';
import Oval from '../../react-image-annotation-edit-delete-annotations/src/components/Oval';
import Point from '../../react-image-annotation-edit-delete-annotations/src/components/Point';
import Rectangle from '../../react-image-annotation-edit-delete-annotations/src/components/Rectangle';
import {
   RectangleSelector, PointSelector, OvalSelector
} from '../../react-image-annotation-edit-delete-annotations/src/selectors';
import Typography from './Typography';

const Box = ({children, geometry, style}) => (
   <div
      style={{
         ...style,
         position: 'absolute',
         left: `${geometry.x}%`,
         top: `${geometry.y}%`,
         height: `${geometry.height}%`,
         width: `${geometry.width}%`,
         textAlign: 'center',
      }}
   >
      {children}
   </div>
);

export function renderSelector({annotation, active}) {
   const {geometry} = annotation;
   if (!geometry) {
      return null;
   }
   switch (annotation.geometry.type) {
      case RectangleSelector.TYPE:
         return (
            <FancyRectangle
               annotation={annotation}
            />
         );
      case PointSelector.TYPE:
         return (
            <Point
               annotation={annotation}
            />
         );
      case OvalSelector.TYPE:
         return (
            <Oval
               annotation={annotation}
            />
         );
      default:
         return null
   }
}

export function renderHighlight({id, key, annotation, active, selectAnnotation, onDelete}) {
   const {geometry} = annotation;
   if (!geometry) {
      return null
   }

   switch (annotation.geometry.type) {
      case RectangleSelector.TYPE:
         return <>
            <Rectangle
               id={id}
               key={key}
               annotation={annotation}
               active={active}
               selectAnnotation={selectAnnotation}
               style={{
                  border: !active ? 'solid 2px red' : 'solid 3px red',
                  outline: !active ? '2px solid rgba(255,255,255,.6)' : '3px solid rgba(255,255,255,.8)',
                  boxShadow: 'none',
               }}
            />
               <Box geometry={{...geometry, y: geometry.y + geometry.height}} style={{textAlign: 'center'}}>
                  <Typography style={{color: 'red', fontSize: 16, background: 'rgba(255,255,255,.6)'}}>
                     {annotation.data && annotation.data.text}
                  </Typography>
               </Box>
         </>;
      case PointSelector.TYPE:
         return (
            <Point
               id={id}
               key={key}
               annotation={annotation}
               active={active}
            />
         );
      case OvalSelector.TYPE:
         return (
            <Oval
               id={id}
               key={key}
               style={{
                  border: !active ? 'solid 1px rgba(255, 0, 0, 0.6)' : 'solid 1px red',
                  boxShadow: active
                     && '0 0 20px 20px rgba(255, 255, 255, 0.3) inset'
               }}
               annotation={annotation}
               active={active}
            />
         );
      default:
         return null
   }
};

export function RenderContent({annotation}) {
   const {geometry} = annotation;
   return (
      <div
         key={annotation.data.id}
         style={{
            background: 'white',
            borderRadius: 3,
            position: 'absolute',
            left: `${geometry.x}%`,
            top: `${geometry.y + geometry.height}%`
         }}
      >
      </div>
   )
}

export function renderEditor({container, annotation, onCancel, onChange, onCreate, onUpdate, onDelete}) {
   const {geometry} = annotation;
   if (!geometry) {
      return null;
   }

   const currentText = get(annotation, 'data.text');
   const isUpdate = get(annotation, 'selection.isUpdate', false);
   const onSubmit = isUpdate ? onUpdate : onCreate;
   const handleKey = (event) => {
      if (!event.defaultPrevented) {
         if (event.key === 'Escape' && onCancel) {
            event.preventDefault();
            onCancel();
         } else if (event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            onSubmit();
         }
      }
   };

   const handleClose = (event, reason) => {
      if (reason !== 'backdropClick') {
         onCancel && onCancel(event, reason);
      }
   };
   return (
      <div
         style={{
            background: 'white',
            borderRadius: 3,
            position: 'absolute',
            left: `${geometry.x}%`,
            top: `${geometry.y + geometry.height}%`,
         }}
      >
         <Popover
            open={true}
            anchorEl={container}
            onKeydown={handleKey}
            onClose={handleClose}
            anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
            transformOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
            }}
         >
            <Grid container direction={'column'} fullWidth spacing={1} style={{padding: 16}}>
                  <Grid item>
                     <TextField
                        label={'Comment'}
                        autoFocus
                        onKeyDownCapture={handleKey}
                        fullWidth
                        defaultValue={currentText}
                        onChange={e => onChange({
                           ...annotation,
                           data: {
                              ...annotation.data,
                              text: e.target.value
                           }
                        })}
                     />
                  </Grid>
                  <Grid item>
                     {get(annotation, 'selection.isUpdate') ? <>
                        <Button type={'submit'} color={'primary'} onClick={onSubmit}>
                           <Typography color='inherit' id={'inspect.updateComment.button'}/>
                        </Button>
                        <Button onClick={onDelete} color={'secondary'}>
                           <Typography color='inherit'>Delete</Typography>
                        </Button>
                        <Button onClick={onCancel} >
                           <Typography color='inherit' id={'cancel.button'}/>
                        </Button>
                     </> : <>
                        <Button type={'submit'} color={'primary'} onClick={onSubmit} >
                           <Typography color='inherit' id={'inspect.addComment.button'}/>
                        </Button>
                        <Button onClick={onCancel} >
                           <Typography color='inherit' id={'cancel.button'}/>
                        </Button>
                     </>
                     }
                  </Grid>
            </Grid>
         </Popover>
      </div>
   )
}
