import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {sortBy} from 'lodash';
import * as _ from 'lodash';
import React, {useState, useEffect, useMemo} from 'react';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import get from 'lodash/get';

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
   contentStyle: {
      overflow: 'hidden'
   },
   buttonStyle: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      position: 'sticky',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      bottom: 0,
      '&:hover': {
         backgroundColor: theme.palette.background.paper,
      },
      zIndex: 1,
   },
   selectAllStyle: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      position: 'sticky',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      top: 0,
      '&:hover': {
         backgroundColor: theme.palette.background.paper,
      },
      zIndex: 1000,
   },
   checkboxStyle: {
      padding: 2,
   }
}));

export default function ItemMenu({
   open,
   anchorEl,
   onClose,
   onAddItems,
   items = [],
   nameProperty = 'name',
   itemName,
   operationName =  'Add',
   useAddAll,
   allName,
   useNew,
   onNewItem,
   ItemEdit,
   ItemEditProps,
   dense = false
}) {

   const [selected, setSelected] = useState({});
   const [areAllSelected, setAllSelected] = useState(false);
   const [showNew, setShowNew] = useState(false);

   const classes = useStyles();

   useEffect(() => {
      if (!open) {
         setSelected({});
      }
   }, [open]);

   /**
    * When the user clicks the status menu item.
    * @param name The name of the status.
    * @return {Function} The callback for the onClick for the named status.
    */
   const onClick = name => (event) => {
      event.stopPropagation();
      event.preventDefault();

      const newSelected = {...selected, [name]: !selected[name]};
      setSelected(newSelected);
   };

   useEffect(() => {
      let newAreAllSelected = true;

      if (items.length > 0) {
         for (const item of items) {
            const useItem = get(item, nameProperty);
            if (selected[useItem] === undefined || !selected[useItem]) {
               newAreAllSelected = false;
               break;
            }
         }
      } else {
         newAreAllSelected = false;
      }
      setAllSelected(newAreAllSelected);
   }, [selected, items, nameProperty]);

   const addItems = () => {
      const selectedItems = _.filter(items, item => selected[get(item, nameProperty)]);

      onAddItems && onAddItems(selectedItems, areAllSelected);
      onClose && onClose();
   };

   const handleAddItems = () => {
      addItems();
   };

   const handleNewItem = () => {
      if (ItemEdit) {
         setShowNew(true);
      } else if (onNewItem) {
         onNewItem();
      }
   };

   const handleSelectAll = () => {
      const newAreAllSelected = !areAllSelected;
       setAllSelected(newAreAllSelected);
       const selectedObject = {};
      for (const item of items) {
         const userItem = get(item, nameProperty);
         selectedObject[userItem] = newAreAllSelected;
      }
      setSelected(selectedObject);
   };

   const closeNew = () => {
      setShowNew(false);
   };

   /**
    * Sort the equipment by the equipment ID.
    */
   const sortedItems = useMemo(() => sortBy(items, [nameProperty]), [items, nameProperty]);

   return (
      <Menu
         id='item-select-menu-id'
         style={{userSelect: 'none'}}
         anchorEl={anchorEl}
         open={open}
         onClose={onClose}
         MenuListProps={{
            disablePadding: true,
         }}
         PaperProps={{
            style: {
               maxHeight: ITEM_HEIGHT * 8,
               // width: 200,
            },
         }}
         onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
         }}
      >
         {(ItemEdit && showNew) && <ItemEdit open={showNew} onClose={closeNew} isCreate {...ItemEditProps}/>}
            <span className={classes.mouseTracker}>
               <div className={classes.selectAllStyle}>
               <Typography color='primary' variant='body1'
                           style={{marginLeft: 8, paddingTop: 8}}>Master {itemName}</Typography>
               {items?.length <= 0 && (
                  <Typography variant='body2'
                              style={{marginLeft: 8, paddingTop: 8}}>No {itemName}</Typography>
               )}
               {useAddAll && items?.length > 0 && (
                  <FormControlLabel style={{paddingLeft:11}}
                     control={(
                        <Checkbox
                           name={'areAllSelected'}
                           checked={areAllSelected}
                           onClick={handleSelectAll}
                           value='areAllSelected'
                        />
                     )}
                     label={<Typography className={classes.menuText} color='secondary' variant='body1'>Select All</Typography>}
                  />
               )}
               <Divider/>
               </div>
               {sortedItems.map(item => {
                  const useItem = get(item, nameProperty);
                  return(
                     <MenuItem key={useItem} onClick={onClick(useItem)} dense={dense} style={{maxWidth: 800}}>
                        <Checkbox className={classes.checkboxStyle} checked={!!selected[useItem]}/>
                        <Typography className={classes.menuText} color='inherit' variant='body1'
                                    style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>
                           {useItem}
                        </Typography>
                     </MenuItem>
                  )
               })}
               <Grid container direction={'row'} spacing={1} className={classes.buttonStyle} >
                  <Grid item>
                     <Button onClick={handleAddItems} color={'secondary'}>
                        <Typography color='inherit'>{`${operationName} ${itemName}`}</Typography>
                     </Button>
                  </Grid>
                  {(!!useNew && (onNewItem || ItemEdit)) && (
                     <Grid item>
                        <Button onClick={handleNewItem} color={'primary'}>
                           <Typography color='inherit'>{`New ${useNew}`}</Typography>
                        </Button>
                     </Grid>
                  )}
               </Grid>
            </span>
      </Menu>
   );
}
