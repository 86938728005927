import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControl from '@material-ui/core/FormControl/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Storage} from 'aws-amplify';
import gql from 'graphql-tag';
import {concat, debounce, find, get, map, uniqBy} from 'lodash';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import TextField from '../../components/TextField';
import {BUCKET_NAME, ENTRY_SET_VALUE, SUBMIT_ANSWER} from '../../Constants';
import useDeleteMutationFHG from '../../fhg/components/data/useDeleteMutationFHG';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Form from '../../fhg/components/Form';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import {DownIcon} from '../../Icons';
import {ANSWER_FRAGMENT} from './AnswerPanel';
import ThumbnailList from './ThumbnailList';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import {formatMessage} from '../../fhg/utils/Utils';
import {useIntl} from 'react-intl';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2, 1, 5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0.5, 1, 1),
    },
  },
  contentStyle: {
    overflow: 'hidden'
  },
  headingStyle: {
    width: '100%',
    height: '100%',
    fontWeight: 500,
  },
  spinnerStyle: {
    color: 'white',
    marginLeft: theme.spacing(0.5),
  },
  expansionStyle: {
    width: '100%',
  },
  deleteButtonStyle: {
    alignSelf: 'center',
  },
  spaceLeftStyle: {
    marginLeft: 8,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  noVerticalSpace: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  summaryStyle: {
    paddingLeft: theme.spacing(5),
  },
  spaceLeftRelated: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
    }
  },
  topMargin: {
    marginTop: theme.spacing(1),
  },
  typographyStyle: {
    maxWidth: 200,
    padding: theme.spacing(2),
  }
}));

export const ANSWER_UPDATE = gql`
  mutation AnswerUpdate(
    $id: Int!
    $questionAliasId: Int!
    $selectedRegulations: [Int]
    $priorityId: Int
    $note: String
    $statusId: Int
    $text: String
    $imageS3Data: [ImageS3Data]
  ) {
    answer: answer_Update(
      answerId: $id
      answer: {
        questionAliasId: $questionAliasId
        selectedRegulations: $selectedRegulations
        priorityId: $priorityId
        note: $note
        statusId: $statusId
        text: $text
        imageS3Data: $imageS3Data
      }
    ) {
      ...answerInfoForPanel
    }
  }
  ${ANSWER_FRAGMENT}
`;

export const ANSWER_REPLACE_IMAGE = gql`
  mutation AnswerReplaceImage($id: Int!, $index: Int!, $imageBucket: String!) {
    answer: answer_ReplaceImage(
      answerId: $id
      index: $index
      imageBucket: $imageBucket
    ) {
      ...answerInfoForPanel
    }
  }
  ${ANSWER_FRAGMENT}
`;

export const ANSWER_DELETE_IMAGE = gql`
  mutation AnswerDeleteImage($id: Int!, $index: Int!) {
    answer: answer_DeleteImage(answerId: $id, index: $index) {
      ...answerInfoForPanel
    }
  }
  ${ANSWER_FRAGMENT}
`;

const ANSWER_OPTIONS_QUERY = gql`
  query AnswerOptions {
    regulations: regulation_All(includeDeleted: true) {
      id
      isDeleted
      reference
      name
      regulation
      standard: regulationStandard {
        id
        name
      }
    }
    priorities: priority_All {
      id
      isDeleted
      name
      value: id
      label: name
      description
    }
  }
`;

export const NOT_APPLICABLE_ANSWER = '3';
export const COMPLIANT_ANSWER = '4';
export const NON_COMPLIANT_ANSWER = '5';
export const RECOMMENDED_ANSWER = '6';
export const YES_ANSWER = '7';
export const NO_ANSWER = '8';
export const COMPLETE_STATUS = 'complete';
export const INCOMPLETE_STATUS = 'incomplete';
export const DELETED_STATUS = 'deleted';
export const PARTIAL_STATUS = 'partial';

/**
 * Component to edit question details. The component is an expansion panel.
 *
 * Reviewed: 11/7/19
 */
export default function AnswerDetailEdit({ answer, validate, isEditable }) {
  const classes = useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openId, setOpenId] = React.useState();
  const [isChanged, setChanged] = React.useState(false);
  const [selectedRegulations, setSelectedRegulations] = React.useState([]);
  const [isSelectedRegulationsOpen, setSelectedRegulationsOpen] =
    React.useState(false);
  const [regulationList, setRegulationList] = React.useState([]);
  const [priority, setPriority] = React.useState();
  const [photos, setPhotos] = React.useState([]);
  const [isShowDetails, setShowDetails] = React.useState(false);
  const [isInitialized, setInitialized] = React.useState(false);
  const [values, setValues] = useState({answerChoice: '', photo: undefined, notes: ''});
  const { loading, data, statusComponent } = useQueryFHG(ANSWER_OPTIONS_QUERY, {fetchPolicy: 'cache-first'});
  const [answerUpdate, answerUpdateStatusComponent, { answerUpdateData }] =
    useMutationFHG(ANSWER_UPDATE, undefined, 'answerUpdate');
  const [answerReplaceImage, answerReplaceImageStatusComponent] =
    useMutationFHG(ANSWER_REPLACE_IMAGE, undefined, 'replaceImage');
  const [
    answerDeleteImage,
    deleteImageStatusComponent,
    deleteImageConfirmComponent,
    { data: deleteImageData }
  ] = useDeleteMutationFHG(ANSWER_DELETE_IMAGE, undefined, {
    titleKey: 'answer.delete.title',
    confirmKey: 'answer.deletePhoto.confirmation'
  });
  const entrySetValue = answer?.questionAlias?.question?.entrySetValue;
  const answerIsRequired =
     entrySetValue === ENTRY_SET_VALUE.compliantNonCompliant && values.answerChoice === NON_COMPLIANT_ANSWER;

  useEffect(() => {
    return submitChangeDelayed && submitChangeDelayed.flush;
  }, []);

  // Set the values from the answer in the data.
  useEffect(() => {
    if (!!data && !isInitialized && !!answer) {
      setPriority(answer.priorityId || undefined);
      setValues({
        answerChoice: entrySetValue === ENTRY_SET_VALUE.text ? answer.text :
          answer.statusId !== null ? String(answer.statusId): undefined,
        notes: answer.note
      });
      setSelectedRegulations(answer.selectedRegulations || []);

      // Get all the selected regulations from the answer. Must include event ones deleted from the question list.
      const selectedRegulationObjectList = map(
        answer.selectedRegulations,
        regulationId => find(data?.regulations, { id: regulationId }));

      // Get all the allowable regulations from the questions. May not have all the selected ones if some where deleted.
      const regulationList = concat(
        selectedRegulationObjectList || [],
        get(answer, 'questionAlias.question.regulationList', [])
      );

      // Get the unique list of regulations.
      const uniqueRegulationList = uniqBy(regulationList, 'id');
      setRegulationList(uniqueRegulationList);
      updatePhotos(answer);
      setInitialized(true);
    }
    // eslint-disable-next-line
  }, [data, answer]);

  // Update the photo array from the new answer imageData.
  const updatePhotos = (answer) => {
    const newPhotos = [];
    const imageData = get(answer, 'imageData', []);
    if (imageData && imageData.length > 0) {
      for (const [i, image] of imageData.entries()) {
        newPhotos.push({imageS3: image.imageS3, src: image.imageS3, index: i});
      }
    }
    setPhotos(newPhotos);
  };

  /**
   * Submit the changes to the server.
   * @param answer The answer being changed.
   * @param values The edited values.
   * @param priority The edited priority.
   * @return {Promise<void>}
   */
  const submitChange = async (
    answer,
    values,
    selectedRegulations,
    priority
  ) => {
    setChanged(false);
    await answerUpdate({
      variables: {
        id: answer.id,
        questionAliasId: get(answer, 'questionAlias.id'),
        selectedRegulations: selectedRegulations,
        statusId:  entrySetValue !== ENTRY_SET_VALUE.text ? (values.answerChoice && Number(values.answerChoice)) || undefined : undefined,
        text: entrySetValue === ENTRY_SET_VALUE.text ? values.answerChoice : undefined,
        note: values.notes,
        priorityId: priority,
      }
    });
  };

  /**
   * Upload the new and updated photos to the S3 bucket. Set the S3 photos in the answer.
   *
   * @param photo The photo to upload.
   * @param isNew Indicates if the photo is new.
   * @return {Promise<unknown>} The promise for the uploaded photo.
   */
  const uploadPhoto = async (photo, isNew) => {
    if (photo.blob) {
      const imagePath = `upload/Photo_${Date.now()}.jpg`;
      try {
        await Storage.put(imagePath, photo.blob, {level: 'public', contentType: get(photo, 'blob.type', 'image/jpeg')});
        photo.imageS3 = `${BUCKET_NAME}/${imagePath}`;

        if (!isNew) {
          answerReplaceImage({
            variables: {
              id: answer.id,
              index: photo.index,
              imageBucket: photo.imageS3,
            }
          }).then(result => {
            handlePhotoSuccess(result);
          });
        } else {
          answerUpdate({
            variables: {
              id: answer.id,
              questionAliasId: get(answer, 'questionAlias.id'),
              imageS3Data: [{ imageBucket: photo.imageS3 }],
            }
          }).then(result => {
            handlePhotoSuccess(result);
          });
        }
      } catch (error) {
        console.log('Error uploading file', error);
      }
    } else {
      console.log('Error uploading file', 'No image to upload');
    }
  };

  // Debounce the submit changes call so that changes to text fields don't cause continual submits.
  const submitChangeDelayed = useRef(
    debounce(submitChange, SUBMIT_ANSWER)
  ).current;

  //When an edited values change, submit the changes.
  React.useEffect(() => {
    if (isChanged && !isSelectedRegulationsOpen) {
      submitChangeDelayed(answer, values, selectedRegulations, priority);
    }
    // eslint-disable-next-line
  }, [answer, values, selectedRegulations, isSelectedRegulationsOpen, priority, isChanged]);

  /**
   * Handle changes to the edited values.
   * @param event The event that triggered the change.
   */
  const handleChange = event => {
    if (event.target.name === 'selectedRegulations') {
      setSelectedRegulations(event.target.value || []);
    } else if (event.target.name === 'priority') {
      setPriority(event.target.value);
    } else {
      setValues({ ...values, [event.target.name]: event.target.value });
    }
    setChanged(true);
  };

  /**
   * Toggle showing the details panel.
   */
  const handleDetails = () => {
    setShowDetails(!isShowDetails);
  };

  /**
   * Set the custom validity message for the photo.
   * @return {*} Returns the photo.
   */
  const handleCustomValidity = () => {
    const isValid = !answerIsRequired || photos.length > 0;

    const target = document.getElementById(`photo for ${answer.id}`);
    if (target) {
      target.setCustomValidity(isValid ? '' : 'Take a photo.');
      target.focus();
    }
    return isValid;
  };

  /**
   * Handle changing the photo. Handles new or existing.
   * @param photo The new or replaced photo.
   * @param isNew Indicates if the photo is new or existing.
   */
  const handleChangePhoto = (photo, isNew) => {
    uploadPhoto(photo, isNew);

    if (isNew) {
      setPhotos([...photos, photo]);
    } else {
      setPhotos([...photos]);
    }
  };

  /**
   * Handle the actual deleting of the photo after successful delete from the server.
   *
   * @param result The result of the server delete.
   */
  const handlePhotoSuccess = (result) => {
    updatePhotos(result.data.answer);
  };

  /**
   * Handle the delete photo. Deletes from the server.
   * @param photo The photo to be deleted.
   */
  const handleDeletePhoto = photo => {
    answerDeleteImage(
      {
        variables: {
          id: answer.id,
          index: photo.index,
        }
      },
      handlePhotoSuccess
    );
  };

  const renderMultipleRegulations = selected => {
    const selectedRegulations = [];
    const regulations = data?.regulations;

    for (const selectedId of selected) {
      const regulation = find(regulations, { id: selectedId });

      if (regulation) {
        let standardName = get(regulation, 'standard.name');
        if (standardName === 'None') {
          standardName = undefined;
        }
        const reference = get(regulation, 'reference');
        if (standardName || reference) {
          const fullDescription = [standardName, reference].join(' ');
          selectedRegulations.push(fullDescription);
        } else {
          selectedRegulations.push(regulation.regulation);
        }
      }
    }
    return selectedRegulations.join(', ');
  };

  const handleClick = id => event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenId(id);
  };

  const maybePrevent = event => {
    if (openId) {
      event.preventDefault();
      event.stopPropagation();
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenId(undefined);
  };

  if (loading) {
    return statusComponent;
  }

  return (
    <Fragment>
      {deleteImageConfirmComponent}
      {statusComponent ||
        answerUpdateStatusComponent ||
        deleteImageStatusComponent ||
        answerReplaceImageStatusComponent}
      <Form
        style={{ width: '100%' }}
        validate={validate}
        reportInvalid={validate}
        customValidity={handleCustomValidity}
      >
        <Grid
           name={'Answer Detail Edit'}
           container
           fullWidth
           className={classes.root}
           direction={'column'}
        >
          <Grid
             item
             container
             direction={'row'}
             resizable={false}
             alignItems={'center'}
             spacing={1}
             fullWidth
          >
            {entrySetValue === ENTRY_SET_VALUE.yesNo && (
               <Grid item>
                 <FormControl className={classes.topMargin} component='fieldset' required disabled={!isEditable}>
                   <RadioGroup
                      aria-label='standard'
                      name='answerChoice'
                      value={values.answerChoice}
                      onChange={handleChange}
                      row
                   >
                     <FormControlLabel
                        value={YES_ANSWER}
                        control={<Radio/>}
                        disabled={!isEditable}
                        label={<Typography id={'inspect.yesAnswer.menuItem'}/>}
                     />
                     <FormControlLabel
                        value={NO_ANSWER}
                        control={<Radio/>}
                        disabled={!isEditable}
                        label={<Typography id={'inspect.noAnswer.menuItem'}/>}
                     />
                   </RadioGroup>
                 </FormControl>
               </Grid>
            )}
            {entrySetValue === ENTRY_SET_VALUE.compliantNonCompliant && (
               <>
                 <Grid item>
                   <FormControl className={classes.topMargin} component='fieldset' required disabled={!isEditable}>
                     <RadioGroup
                        aria-label='standard'
                        name='answerChoice'
                        value={values.answerChoice}
                        onChange={handleChange}
                        row
                     >
                       <FormControlLabel
                          value={COMPLIANT_ANSWER}
                          control={<Radio/>}
                          disabled={!isEditable}
                          label={
                            <Typography id={'inspect.compliantAnswer.menuItem'}/>
                          }
                       />
                       <FormControlLabel
                          value={NON_COMPLIANT_ANSWER}
                          control={<Radio/>}
                          disabled={!isEditable}
                          label={
                            <>
                              <Typography
                                 id={'inspect.nonCompliantAnswer.menuItem'}
                              />
                              {answer.statusId === Number(NON_COMPLIANT_ANSWER) && answer.createdDateTime === answer.updatedDateTime && (
                                 <Tooltip
                                    title={formatMessage(intl, 'inspect.nonCompliantAnswer.note', undefined, undefined)}>
                                   <IconButton>
                                     <WarningOutlinedIcon/>
                                   </IconButton>
                                 </Tooltip>
                              )}
                            </>
                          }
                       />
                       <FormControlLabel
                          value={RECOMMENDED_ANSWER}
                          control={<Radio/>}
                          disabled={!isEditable}
                          label={
                            <Typography
                               id={'inspect.recommendedAnswer.menuItem'}
                            />
                          }
                       />
                       <FormControlLabel
                          value={NOT_APPLICABLE_ANSWER}
                          control={<Radio/>}
                          disabled={!isEditable}
                          label={
                            <Typography
                               id={'inspect.notApplicableAnswer.menuItem'}
                            />
                          }
                       />
                     </RadioGroup>
                   </FormControl>
                 </Grid>
                 {values.answerChoice === COMPLIANT_ANSWER && (
                    <Grid item>
                      <Button className={classes.topMargin} onClick={handleDetails} color={'secondary'}>
                        <Typography
                           color='inherit'
                           id={
                             isShowDetails
                                ? 'inspect.removeDetails.button'
                                : 'inspect.addDetails.button'
                           }
                        />
                      </Button>
                    </Grid>
                 )}
               </>
            )}
            {entrySetValue === ENTRY_SET_VALUE.text && (
               <Grid item xs={12} sm={12} md={8}>
                 <TextField
                    className={classes.topMargin}
                    name="answerChoice"
                    label={<Typography id="answer.answer.label"/>}
                    required
                    fullWidth
                    value={values.answerChoice}
                    onChange={handleChange}
                    onBlur={() => submitChangeDelayed.flush()}
                    disabled={!isEditable}
                    margin="none"
                 />
               </Grid>
            )}
          </Grid>
          {(values.answerChoice && (entrySetValue !== ENTRY_SET_VALUE.compliantNonCompliant ||
                (values.answerChoice === NON_COMPLIANT_ANSWER || values.answerChoice === RECOMMENDED_ANSWER) ||
                (values.answerChoice === COMPLIANT_ANSWER && isShowDetails))
          ) && (
             <>
               <Grid
                  name={'Notes & Priority'}
                  item
                  container
                  direction='row'
                  xs={12}
                  fullWidth
                  spacing={1}
                  style={{marginTop: 8}}
               >
                 {entrySetValue === ENTRY_SET_VALUE.compliantNonCompliant && (
                    <Grid
                       item
                       xs={12}
                       sm={regulationList.length > 0 ? 6 : 12}
                       md={regulationList.length > 0 ? 2 : 4}
                    >
                      <TextField
                         name={'priority'}
                         label={
                           <Typography
                              display={'inline'}
                              id={'questionDetail.priority.label'}
                           />
                         }
                         // options={get(data, 'priorities')}
                         variant={'outlined'}
                         select
                         SelectProps={{IconComponent: DownIcon}}
                         value={priority}
                         onChange={handleChange}
                         margin='none'
                         fullWidth
                         autoFocus
                         disabled={!isEditable}
                         required={answerIsRequired}
                         inputProps={{required: answerIsRequired}}
                      >
                        <MenuItem value={null} onClickCapture={maybePrevent}>&nbsp;</MenuItem>
                        {get(data, 'priorities', []).map(priority => (
                           <MenuItem
                              key={'priority' + priority.id}
                              value={priority.id}
                              onClickCapture={maybePrevent}
                           >
                             <Grid
                                container
                                direction={'row'}
                                alignItems={'center'}
                                justify={'space-between'}
                             >
                               {`${priority.label}`}
                               <Button
                                  color='secondary'
                                  size='small'
                                  style={{
                                    height: 19,
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginRight: 8
                                  }}
                                  onClick={handleClick(priority.id)}
                               >
                                 <Typography>More Info</Typography>
                               </Button>
                               <Popover
                                  key={'popover' + priority.id}
                                  open={openId === priority.id}
                                  anchorEl={anchorEl}
                                  onClose={handleClose}
                                  PaperProps={{
                                    className: classes.typographyStyle
                                  }}
                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }}
                               >
                                 <Typography className={classes.typographyStyle}>
                                   {priority.description}
                                 </Typography>
                               </Popover>
                             </Grid>
                           </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                 )}

                 {regulationList.length > 0 && (
                    <Grid item xs={12} sm={6} md={2}>
                      <TextField
                         name={'selectedRegulations'}
                         label={<Typography id={'questionDetail.standard.label'}/>}
                         variant={'outlined'}
                         select
                         SelectProps={{
                           multiple: true,
                           renderValue: renderMultipleRegulations,
                           IconComponent: DownIcon,
                         }}
                         value={selectedRegulations}
                         onChange={handleChange}
                         margin='none'
                         disabled={!isEditable}
                         fullWidth
                      >
                        {regulationList.map(regulation => (
                           <MenuItem
                              key={'regulation' + regulation.id}
                              value={regulation.id}
                           >
                             <Checkbox
                                className={classes.checkboxStyle}
                                checked={
                                   selectedRegulations &&
                                   selectedRegulations.length >= 0 &&
                                   selectedRegulations.indexOf(regulation.id) > -1
                                }
                             />
                             {(get(regulation, 'standard.name') &&
                                get(regulation, 'standard.name') !== 'None') && (
                                <Typography
                                   variant={'subtitle1'}
                                   display={'inline'}
                                >
                                  {`${[
                                    get(regulation, 'standard.name'),
                                    get(regulation, 'reference')
                                  ].join(' ')}: `}
                                </Typography>
                             )}
                             <Typography
                                variant={'subtitle2'}
                                display={'inline'}
                                style={{whiteSpace: 'normal'}}
                             >
                               {regulation.regulation}
                             </Typography>
                           </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                 )}
                 <Grid item xs={12} sm={12} md={8}>
                   <TextField
                      name='notes'
                      label={<Typography id={'answer.notes.label'}/>}
                      required={answerIsRequired}
                      fullWidth
                      value={values.notes}
                      onChange={handleChange}
                      onBlur={() => submitChangeDelayed.flush()}
                      margin='none'
                      disabled={!isEditable}
                      rows={2}
                   />
                 </Grid>
               </Grid>
               <Grid name={'bottom row'} item resizable={false} fullWidth>
                 <ThumbnailList
                    answer={answer}
                    defaultImages={photos}
                    onChange={handleChangePhoto}
                    onDelete={handleDeletePhoto}
                    isEditable={isEditable}
                 />
                 <input
                    aria-invalid='false'
                    id={`photo for ${answer.id}`}
                    name='confirm'
                    type='text'
                    style={{
                      width: 0,
                      height: 0,
                      padding: 0,
                      position: 'relative',
                      top: -96,
                      left: 22,
                      zIndex: -1
                    }}
                 />
               </Grid>
             </>
          )}
        </Grid>
      </Form>
    </Fragment>
  );
}
