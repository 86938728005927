import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import {lighten} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {drawerWidth, PLACEHOLDER_IMAGE, THUMBNAIL_SIZE, USERS_ALL_PATH} from '../../Constants';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import {cacheDelete} from '../../fhg/utils/DataUtil';
import InspectionBuilder from '../builder/InspectionBuilder';
import PropertyInfoEdit from './PropertyInfoEdit';
import {PROPERTIES_QUERY} from './PropertyView';
import useFullScreen from '../../fhg/hooks/useFullScreen';

const useStyles = makeStyles(theme => ({
   titleStyle: {
      lineHeight: 1.2,
   },
   drawerStyle: {
      width: drawerWidth,
      flexShrink: 0,
   },
   paperStyle: {
      // width: drawerWidth,
      backgroundColor: '#EFF0F0',
   },
   progressStyle: {
      position: 'relative',
      top: '50%',
      left: '45%',
   },
   toolbar: theme.mixins.toolbar,
   contentStyle: {
      overflowX: 'auto',
      overflowY: 'hidden',
      maxWidth: 1200,
      minWidth: 800,
      minHeight: 420,
      height: '100%',
      padding: theme.spacing(3),
   },
   headingStyle: {
      width: '100%',
      height: '100%',
      fontWeight: 'bold',
   },
   dividerStyle: {
      backgroundColor: theme.palette.secondary.main,
      height: `25px !important`,
      margin: 'auto',
   },
   smallTitle: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
   },
   smallTitle2: {
      marginTop: theme.spacing(1),
   },
   buttonStyle: {
      whiteSpace: 'nowrap',
   },
   leftButtonStyle: {
      composes: '$buttonStyle',
      marginRight: theme.spacing(1),
   },
   rightButtonStyle: {
      composes: '$buttonStyle',
      marginLeft: theme.spacing(1),
   },
   imageStyle: {
      maxHeight: 200,
      width: '100%',
   },
   deleteColorStyle: {
      color: theme.palette.error.main,
      '&:hover': {
         backgroundColor: lighten(theme.palette.error.light, 0.8),
      }
   },
}));

const DELETE_PROPERTY = gql`
  mutation deleteProperty($id: Int!)
  {
     property_Delete(propertyId: $id)
  }
`;


PropertyInfo.propTypes = {
   classes: PropTypes.object.isRequired,
   property: PropTypes.object,
   userIdList: PropTypes.object,
};

/**
 * Drawer to navigate to reports and other screens.
 */
export default function PropertyInfo({property = {}, userIdList}) {
   const {id = 0} = useParams();

   const classes = useStyles();
   const history = useHistory();
   const isFullScreen = useFullScreen();

   const [propertyDelete, propertyDeleteStatusComponent] = useMutationFHG(DELETE_PROPERTY);

   const [showEdit, setShowEdit] = useState(false);


   const openEdit = () => {
      setShowEdit(true);
   };

   const closeEdit = () => {
      setShowEdit(false);
   };

   const handleDelete = async () => {
      try {
         await propertyDelete({
            variables: {id: +id},
            optimisticResponse: {property_Delete: 1},
            update: cacheDelete([{query: PROPERTIES_QUERY, queryPath: 'properties'}], +id),
         });
         history.push('/property');
      } catch (e) {
         //Intentionaly left blank
      }
   };

   const handleAddUser = () => {

      if (!!userIdList && userIdList.length > 0) {
         history.push(`${USERS_ALL_PATH}/${get(userIdList, '[0]')}`, {isCreate: false, property});
      } else {
         history.push(USERS_ALL_PATH, {isCreate: true, property});
      }
   };

   const image = get(property, 'imageS3') || PLACEHOLDER_IMAGE;

   return (
      <Grid container direction={'column'} wrap={'nowrap'} className={classes.contentStyle} style={{ maxWidth: isFullScreen ? '100%' : '1200px', minHeight: isFullScreen ? '1000px' : '420px' }}>
         {propertyDeleteStatusComponent}
         {showEdit && <PropertyInfoEdit open={showEdit} onClose={closeEdit} isCreate={false} property={property}/>}
         {!isFullScreen && <Grid item>
            <Grid container direction={'row'} wrap={'nowrap'} resizeable={false} spacing={2}>
               <Grid item xs={6}>
                  <Grid container direction={'column'} wrap={'nowrap'}>
                     <Grid container direction={'row'} wrap={'nowrap'} justify={'space-between'}>
                        <Grid item>
                           <Typography variant={'h6'} className={classes.titleStyle} color={'inherit'}>
                              {property.name}
                           </Typography>
                           <Typography variant={'body2'} color={'inherit'}>
                              {property.addressLineOne}
                           </Typography>
                           <Typography variant={'body2'} color={'inherit'}>
                              {property.addressLineTwo}
                           </Typography>
                           <Typography variant={'body2'} color={'inherit'} id={'propertyInfo.cityStateZip.label'}
                                       values={{
                                          city: property.city.name,
                                          state: property.state.abbreviation,
                                          zipCode: property.zipCode
                                       }}
                           />
                        </Grid>
                        <Grid item resizable={false}>
                           <Grid container spacing={1} alignItems={'center'} direction={'row'} wrap={'nowrap'}>
                              <Grid item>
                                 <Button onClick={openEdit} color={'secondary'}
                                         className={classes.leftButtonStyle}>
                                    <Typography color='inherit' id={'property.edit.button'}/>
                                 </Button>
                              </Grid>
                              <Grid item>
                                 <Divider orientation={'vertical'} className={classes.dividerStyle}/>
                              </Grid>
                              <Grid item>
                                 <ConfirmButton
                                    onConfirm={handleDelete}
                                    buttonLabelKey='property.delete.button'
                                    color='secondary'
                                    messageKey='property.delete.confirmation'
                                    titleKey='property.delete.title'
                                    values={property}
                                    confirmProps={{submitColorStyle: classes.deleteColorStyle}}
                                 />
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid container>
                        <Grid item xs={12} sm={6}>
                           <Typography variant={'subtitle2'} color={'inherit'} id={'propertyInfo.type.label'}
                                       display={'inline'} className={classes.smallTitle2}/>
                           <Typography variant={'body2'} color={'inherit'} display={'inline'}
                                       className={classes.smallTitle}>
                              {property.type || ' N/A'}
                           </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <Typography variant={'subtitle2'} color={'inherit'}
                                       id={'propertyInfo.information.label'}/>
                           <Typography variant={'body2'} color={'inherit'}>
                              {property.information || ' N/A'}
                           </Typography>
                        </Grid>
                        <Grid item xs={12}>
                           <Button onClick={handleAddUser} color={'secondary'} disabled={!property && !property.id}
                                   className={classes.leftButtonStyle}>
                              <Typography color='inherit' id={'propertyInfo.createUser.button'}/>
                           </Button>
                        </Grid>
                        <Grid item xs={12}>
                           <Typography variant={'subtitle2'} color={'inherit'} id={'propertyInfo.companyName.label'}
                                       display={'inline'} className={classes.smallTitle2}/>
                           <Typography variant={'body2'} color={'inherit'} display={'inline'}
                                       className={classes.smallTitle}>
                              {property.companyName || ' N/A'}
                           </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <div>
                              <Typography variant={'subtitle2'} color={'inherit'}
                                          id={'propertyInfo.contactName.label'}
                                          display={'inline'}/>
                              <Typography variant={'body2'} color={'inherit'} display={'inline'}
                                          className={classes.smallTitle}>
                                 {property.contactName || ' N/A'}
                              </Typography>
                           </div>
                           <div>
                              <Typography variant={'subtitle2'} color={'inherit'} id={'propertyInfo.phone.label'}
                                          display={'inline'}/>
                              <Typography variant={'body2'} color={'inherit'} display={'inline'}
                                          className={classes.smallTitle}>
                                 {property.phone || ' N/A'}
                              </Typography>
                           </div>
                           <div>
                              <Typography variant={'subtitle2'} color={'inherit'} id={'propertyInfo.email.label'}
                                          display={'inline'}/>
                              <Typography variant={'body2'} color={'inherit'} display={'inline'}
                                          className={classes.smallTitle}>
                                 {property.email || ' N/A'}
                              </Typography>
                           </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <Typography variant={'subtitle2'} color={'inherit'} id={'propertyInfo.schedule.label'}/>
                           <Typography variant={'body2'} color={'inherit'}>
                              {property.schedule || ' N/A'}
                           </Typography>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item>
                  <img alt='property' className={classes.imageStyle}
                        src={property.imageS3 || PLACEHOLDER_IMAGE}
                        onError={(e) => {e.target.src = PLACEHOLDER_IMAGE}}/>
               </Grid>
            </Grid>
         </Grid>}
         <Grid item name={'Test resizable'} resizable={true} fullWidth fullHeight>
            <InspectionBuilder property={property}/>
         </Grid>
      </Grid>
   );
}
