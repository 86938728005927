import {CircularProgress} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import gql from 'graphql-tag';
import {forEach} from 'lodash';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {parse} from 'query-string';
import React, {useCallback, useEffect, useState} from 'react';
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary} from '../../components/ExpansionPanel';
import TextField from '../../components/TextField';
import {ENTRY_SET_VALUE, INSPECTION_TYPE, POWER_WASH_TYPE} from '../../Constants';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useEditData from '../../fhg/components/edit/useEditData';
import Form from '../../fhg/components/Form';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import {FILTER_QUESTIONS_QUERY} from './Area';
import {useLocation} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import QuestionDetailEditContent from './QuestionDetailEditContent';

const useStyles = makeStyles(
  {
    headingStyle: {
      width: '100%',
      height: '100%',
      fontWeight: 500,
    },
    expansionStyle: {
      width: '100%',
    },
    deleteButtonStyle: {
      alignSelf: 'center',
    },
    spaceLeftStyle: {
      marginLeft: 8,
    },
    noWrap: {
      whiteSpace: 'nowrap',
    },
  },
  { name: 'QuestionDetailEditStyles' }
);

const QUESTION_FRAGMENT = gql`
  fragment questionDetailInfo on Question {
    id
    text
    questionTypeId
    entrySetValue
    isDeleted
    regulationList {
      id
      isDeleted
      reference
      name
      regulation
      standard: regulationStandard {
        id
        isDeleted
        name
      }
    }
    equipment: masterEquipment {
      id
      isDeleted
      name
      value: name
      label: name
    }
  }
`;

const QUESTION_CREATE = gql`
  mutation CreateQuestion($area: String!, $text: String!, $equipment: String, $reference: String, $standard: String, 
     $questionTypeId: Int!, $regulationInfo: [RegulationInfo], $entrySetValue: Int) {
    question: question_Generate(
      question: {
        area: $area,
        equipment: $equipment,
        text: $text,
        reference: $reference,
        standard: $standard,
        questionTypeId: $questionTypeId,
        regulationInfo: $regulationInfo,
        entrySetValue: $entrySetValue
      }
    ) {
      ...questionDetailInfo
    }
  }
  ${QUESTION_FRAGMENT}
`;

export const QUESTION_UPDATE = gql`
  mutation UpdateQuestion(
    $id: Int!,
    $area: String!,
    $text: String!,
    $equipment: String,
    $reference: String,
    $standard: String,
    $questionTypeId: Int!,
    $regulationInfo: [RegulationInfo],
    $entrySetValue: Int
  ) {
    question: question_GenerateUpdate(
      questionId: $id,
      question: {
        area: $area,
        equipment: $equipment,
        text: $text,
        reference: $reference,
        standard: $standard,
        questionTypeId: $questionTypeId,
        regulationInfo: $regulationInfo,
        entrySetValue: $entrySetValue,
      }
    ) {
      ...questionDetailInfo
    }
  }
  ${QUESTION_FRAGMENT}
`;

const DELETE_QUESTION = gql`
  mutation deleteQuestion($id: Int!) {
    question_Delete(questionId: $id)
  }
`;

export const QUESTION_QUERY = gql`
  query QuestionQuery($id: Int!) {
    question: question_ById(questionId: $id) {
      ...questionDetailInfo
    }
    standards: regulationStandard_All {
      id
      isDeleted
      name
      value: name
      label: name
    }
  }
  ${QUESTION_FRAGMENT}
`;

QuestionDetailEdit.propTypes = {
  area: PropTypes.object.isRequired,
  show: PropTypes.bool,
  question: PropTypes.object,
  equipmentList: PropTypes.array,
  expanded: PropTypes.bool,
  validate: PropTypes.bool,
  onDelete: PropTypes.func,
  onSuccess: PropTypes.func,
  onChange: PropTypes.func,
  onExpand: PropTypes.func,
  onCancel: PropTypes.func,
};

/**
 * Component to edit question details. The component is an expansion panel.
 *
 * Reviewed: 9/19/19
 */
export default function QuestionDetailEdit({
  area = {},
  equipmentList,
  question = {},
  expanded = false,
  onDelete,
  onSuccess,
  onChange,
  onCancel,
  onExpand,
  validate = false,
  show = true
}) {
  const classes = useStyles();
  const location = useLocation();

  // const formRef = React.useRef();

  const [text, setText] = useState();
  const regulationState = useState([]);
  const [regulations, setRegulations] = regulationState;
  const [deleteQuestion, deleteStatusComponent] =
    useMutationFHG(DELETE_QUESTION);
  const [createQuestion, createQuestionStatusComponent] =
    useMutationFHG(QUESTION_CREATE);
  const [updateQuestion, updateQuestionStatusComponent] =
    useMutationFHG(QUESTION_UPDATE);

  const editData = useEditData();
  const [
    editValues,
    handleEditChange,
    { isChanged, setIsChanged, setEditValues, resetValues }
  ] = editData;
  const [isSaving, setIsSaving] = useState(false);

  /**
   * Set the entrySetType based on the question type by default. Power wash default to yes/no and others default to
   * compliant/noncompliant.
   *
   * CAVEAT: This will be overridden when new defaults are set.
   */
  useEffect(() => {
    if (editValues?.questionTypeId) {
      if (editValues?.questionTypeId === POWER_WASH_TYPE.toString()) {
        setEditValues(editValues => ({...editValues, entrySetValue: ENTRY_SET_VALUE.yesNo}));
      } else {
        setEditValues(editValues => ({...editValues, entrySetValue: ENTRY_SET_VALUE.compliantNonCompliant}));
      }
    }
  }, [editValues?.questionTypeId, setEditValues]);

  /**
   * On delete successful, stop deleting and notify parent.
   */
  const handleSuccessDelete = () => {
    if (question.id) {
      deleteQuestion({
        variables: { id: question.id },
        refetchQueries: getDeleteRefetchQueries
      });
    }
    onDelete?.();
  };

  /**
   * Submits the changes to the user.
   *
   * @return {*} return object with changes.
   */
  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      setIsSaving(true);

      let regulationInfo = [];

      forEach(regulations, regulation => {
        if (
          regulation.standard &&
          regulation.regulation &&
          regulation.reference
        ) {
          regulationInfo.push({
            ...regulation,
            standard: get(regulation, 'standard.label')
          });
        }
      });

      const variables = {
        area: area.name,
        entrySetValue: editValues.entrySetValue,
        text: editValues.text || get(question, 'text', ''),
        equipment: get(editValues.equipment, 'label'),
        questionTypeId:
          editValues.questionTypeId !== undefined
            ? +editValues.questionTypeId
            : get(question, 'questionTypeId') || INSPECTION_TYPE,
        regulationInfo,
      };
      try {
        if (question.id) {
          variables.id = question.id;
          await updateQuestion({
            variables,
            refetchQueries: getEditRefetchQueries
          });
        } else {
          await createQuestion({
            variables,
            refetchQueries: getEditRefetchQueries
          });
        }
        setIsSaving(false);
        setIsChanged(false);

        onSuccess?.();
      } catch (e) {}
      return variables;
    } else {
      onSuccess?.();
    }
  }, [regulations, editValues, area, text, onSuccess]);

  /**
   * On user canceled the edit. Set state to initial state.
   */
  const handleCancel = () => {
    setText(undefined);
    resetValues();
    setRegulations([]);

    onCancel && onCancel(question.id);
  };

  /**
   * Stop propagation of the click event.
   * @param event The click event.
   */
  const handleNoDefaultClick = event => {
    event.stopPropagation();
  };

  /**
   * Refetch queries for Apollo to update cache after delete mutation.
   * @return {{variables: *, query: *}[]} The refetch queries.
   */
  const getDeleteRefetchQueries = useCallback(() => {
    const filters = parse(location.search, {
      parseNumbers: true,
      parseBooleans: true
    });
    const variables = { id: area.id, area: area.name, ...filters };

    return [{ query: FILTER_QUESTIONS_QUERY, variables }];
  }, [location, area]);

  /**
   * Refetch queries for Apollo to update cache after edit mutation.
   * @return {{variables: *, query: *}[]} The refetch queries.
   */
  const getEditRefetchQueries = useCallback(() => {
    const filters = parse(location.search, {
      parseNumbers: true,
      parseBooleans: true
    });
    const variables = { id: area.id, area: area.name, ...filters };
    const queries = [{ query: FILTER_QUESTIONS_QUERY, variables }];
    if (question.id) {
      queries.push({ query: QUESTION_QUERY, variables: { id: question.id } });
    }
    return queries;
  }, [location, area, question]);

  /**
   * On user changing an edit field, pass notification to the parent component.
   * @param event The edit event.
   */
  const handleChange = event => {
    handleEditChange(event);
    onChange?.(event, question.id || -1);
  };

  const questionChange = event => {
    onChange?.(event, question.id || -1);
  };

  if (show) {
    return (
      <ExpansionPanel
        expanded={expanded}
        onChange={onExpand}
        className={classes.expansionStyle}
      >
        {deleteStatusComponent ||
          createQuestionStatusComponent ||
          updateQuestionStatusComponent}
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon color='primary' />}
          aria-controls='panel1bh-content'
          id='panel1bh-header'
          IconButtonProps={{ color: 'secondary' }}
        >
          {expanded ? (
            <TextField
              name='text'
              label={'Question'}
              required
              autoFocus
              fullWidth
              defaultValue={question.text}
              value={editValues.text}
              onClick={handleNoDefaultClick}
              onChange={handleChange}
              margin='none'
            />
          ) : (
            <Typography variant={'subtitle1'} className={classes.headingStyle}>
              {question.text}
            </Typography>
          )}
          <Grid item className={classes.deleteButtonStyle} resizable={false}>
            <ConfirmButton
              onConfirm={handleSuccessDelete}
              buttonLabelKey='questionDetail.delete.button'
              style={{ padding: 6 }}
              color='secondary'
              messageKey='questionDetail.delete.confirmation'
              titleKey='questionDetail.delete.title'
              values={question.id ? question : editValues}
              confirmProps={{ submitColorStyle: classes.deleteColorStyle }}
            />
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {expanded && (
            <Form submit={handleSubmit} validate={validate}>
              <Grid
                container
                direction={'column'}
                className={classes.contentStyle}
              >
                <QuestionDetailEditContent
                  question={question}
                  onQuestionChange={questionChange}
                  onChange={handleChange}
                  editData={editData}
                  equipmentList={equipmentList}
                  isSaving={isSaving}
                  regulationState={regulationState}
                />
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <Button type='submit' color={'primary'}>
                  <Typography color='inherit' id={'save.label'} />
                  {isSaving && (
                    <CircularProgress
                      className={classes.spinnerStyle}
                      size={15}
                      thickness={2.5}/>
                  )}
                </Button>
                <Button onClick={handleCancel}>
                  <Typography color='inherit' id={'cancel.button'} />
                </Button>
              </Grid>
            </Form>
          )}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  } else {
    return null;
  }
}
