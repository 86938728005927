import React from "react";
export default React.createContext({
  isPlanner: false,
  isSuperAdmin: false,
  isAdmin: false,
  isInspector: false,
  isManagement: false,
  isPropertyOwner: false,
  cognitoSub: undefined,
});
