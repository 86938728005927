import IconButton from '@material-ui/core/IconButton';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import React, {PureComponent} from 'react';

export default class CsvExportButton extends PureComponent {
   static propTypes = {
      onClick: PropTypes.any
   };

   render() {
      return <Tooltip title='Download CSV file'>
         <IconButton aria-label='Download CSV File' onClick={this.props.onClick}>
            <SaveAlt style={{color: 'white'}}/>
         </IconButton>
      </Tooltip>;
   }
}


