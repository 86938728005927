import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import React, {Fragment, useState} from 'react';
import ModalDialog from './dialog/ModalDialog';
import Typography from './Typography';

/**
 * Button Component to show confirmation.
 *
 * Reviewed: 6/22/20
 */
ConfirmButton.propTypes = {
   titleKey: PropTypes.string.isRequired,       // Localization key for the title.
   messageKey: PropTypes.string.isRequired,     // Localization key for the messages.
   buttonLabelKey: PropTypes.string,            // Localization key for the button label.
   onConfirm: PropTypes.func.isRequired,        // The callback when the action is confirmed.
   onCancel: PropTypes.func,                    // The callback when the action is canceled.
   component: PropTypes.any,          // The Button component. Defaults to Button.
};

export default function ConfirmButton({titleKey, messageKey, buttonLabelKey, onConfirm, onCancel, confirmProps,
   children, color, component, values, ...buttonProperties}) {

   const [isModalOpen, setIsModalOpen] = useState(false);

   const handleClick = event => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      setIsModalOpen(true);

      buttonProperties && buttonProperties.onClick && buttonProperties.onClick(event);
   };

   const handleConfirm = () => {
      setIsModalOpen(false);
      onConfirm && onConfirm();
   };

   const handleCancel = (event) => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }

      setIsModalOpen(false);
      onCancel && onCancel();
   };

   const UseButton = component || Button;
   return (
      <Fragment>
         {isModalOpen && (
            <ModalDialog submitKey={buttonLabelKey} {...confirmProps} titleKey={titleKey} messageKey={messageKey} onSubmit={handleConfirm}
                         onClose={handleCancel} hideBackdrop submitColor={color} values={values}
            />
         )}
         <UseButton onClick={handleClick} color={color} {...buttonProperties}>
            {(!component) && (
               <Typography variant={'inherit'} id={buttonLabelKey}/>
            )}
            {children}
         </UseButton>
      </Fragment>
   );
}

