import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import gql from 'graphql-tag';
import get from 'lodash/get';
import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Typography from '../../fhg/components/Typography';
import UserInfoEdit from './UserInfoEdit';

const styles = theme => ({
   contentStyle: {
      overflow: 'auto',
      height: '100%',
      flex: '1 1',
      // padding: theme.spacing(3),
   },
   messageStyle: {
      padding: theme.spacing(3),
   },
});

export const USER_FRAGMENT = gql`
   fragment userInfo on User {
      id
      email
      firstName
      lastName
      licenseNumber
      credentials
      username
      roles
      isDeleted
      propertyIdList
      regionIdList
       signatureImageData {
           imageS3
       }
      regions: regionList {
         id
         name
         value: id
         label: name
      }
   }
`;

export const USER_QUERY = gql`
   query getUser($id: Int!)
   {
      user:user_ById(userId: $id) {
         ...userInfo
         cognitoSub
         clientId
      }
      roles: listRoles
   }
   ${USER_FRAGMENT}
`;
const PROPERTY_QUERY = gql`
   query getPropertyForUser($propertyId: Int!)
   {
      property:property_ById(propertyId: $propertyId) {
         id
         name
         email
         contactName
      }
   }
`;
export const ROLES_QUERY = gql`
   query getRoles {
      roles: listRoles
   }
`;

/**
 * Drawer to navigate to reports and other screens.
 */
function User({classes, location, match, history}) {

   const [user, setUser] = useState(null);
   const [roles, setRoles] = useState([]);
   const id = Number(get(match, 'params.id', 0));
   const {isCreate} = get(location, 'state', {});

   const {loading:rolesLoading, data:rolesData, statusComponent:rolesStatusComponent} = useQueryFHG(ROLES_QUERY, {fetchPolicy: 'cache-first'});
   const {loading, data, statusComponent} = useQueryFHG(USER_QUERY, {variables: {id}, skip: isCreate, fetchPolicy: 'cache-first'});
   const propertyId = get(data, 'user.propertyId');
   const {loading:propertyLoading, data:propertyData, statusComponent:propertyStatusComponent} = useQueryFHG(PROPERTY_QUERY, {variables: {propertyId}, skip: !propertyId, fetchPolicy: 'cache-first'});

   useEffect(() => {
      setUser(get(data, 'user'));
   }, [data]);

   useEffect(() => {

      if (!!propertyData) {
         history.replace(undefined, {property: propertyData.property});
      }
   }, [propertyData]);

   useEffect(() => {
      setRoles(get(rolesData, 'roles'));
   }, [rolesData]);

   if (loading || rolesLoading || propertyLoading) {
      return statusComponent || rolesStatusComponent || propertyStatusComponent || <Typography variant="h6">Loading...</Typography>;
   }

   if (!user && !isCreate) {
      return null;
   }
   return (
      <Grid item className={classes.contentStyle}>
         {statusComponent || rolesStatusComponent || propertyStatusComponent}
         {id || isCreate ? (
            <UserInfoEdit key={id} user={!isCreate && user} roles={roles}/>
         ) : <>
            {id === undefined && (
               <Typography className={classes.messageStyle} id='user.noSelection.error' variant={'h6'}
                           color={'inherit'}/>
            )}
         </>
         }
      </Grid>
   );
}

export default withRouter(withStyles(styles)(User));
