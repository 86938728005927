import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useFullScreen = () => {
  const location = useLocation();
  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const isFullScreenParam = searchParams.get('isFullScreen');
    setIsFullScreen(Boolean(isFullScreenParam) === true);
  }, [location.search]);

  return isFullScreen;
};

export default useFullScreen;
