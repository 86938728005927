import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import {find} from 'lodash';
import get from 'lodash/get';
import React, {Fragment, useContext} from 'react';
import {withRouter} from 'react-router-dom';
import ItemDrawer from '../../components/ItemDrawer';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Area from './Area';
import AreaEdit from './AreaEdit';
import DrawerContext from '../../components/DrawerContext';

const useStyles = makeStyles(theme => ({
   contentStyle: {
      overflow: 'auto',
      flex: '1 1',
      padding: theme.spacing(3),
   },
}));

const AREA_FRAGMENT = gql`
   fragment areaInfo on Area {
      id
      name
      isDeleted
   }
`;

export const MASTER_AREA_QUERY = gql`
   query getMasterAreas
   {
      areas:area_AllWhere(areaSearch: {templateId: 1, isCategory: false}) {
         ...areaInfo
      }
   }
   ${AREA_FRAGMENT}
`;

/**
 * The component that displays the Area and edits the Master Questions.
 *
 * Reviewed: 9/19/19
 */
function AreaView({match, history}) {
   const {loading, data, statusComponent} = useQueryFHG(MASTER_AREA_QUERY, {fetchPolicy: 'cache-and-network'});
   const classes = useStyles();

   /**
    * When an Area is deleted, remove the Area's selection.
    */
   const handleDelete = () => {
      history.push('/planning');
   };

   const handleOnSuccess = result => {
      history.push(`/planning/${get(result, 'data.area.id')}`);
   };

   const id = Number(get(match, 'params.id', 0));

   const { isDrawerOpen } = useContext(DrawerContext);

   return (
      <Fragment>
         {statusComponent}
         <ItemDrawer
            items={get(data, 'areas')}
            isLoading={loading}
            prefixKey={'area'}
            uri={'/planning'}
            ItemEdit={AreaEdit}
            ItemEditProps={{onSuccess: handleOnSuccess}}
            useSearch={false}
            open={isDrawerOpen}
            variant="persistent"
         />
         <div className={classes.contentStyle}>
            <Area key={`questionView${id}`} area={find(get(data, 'areas'), {id})}
                  onDelete={handleDelete}/>
         </div>
      </Fragment>
   );
}

export default withRouter(AreaView);
