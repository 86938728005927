import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {lighten} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Delete} from '@material-ui/icons';
import {Edit} from '@material-ui/icons';
import {Add} from '@material-ui/icons';
import {add} from 'date-fns';
import {format} from 'date-fns';
import gql from 'graphql-tag';
import {reverse} from 'lodash';
import {sortBy} from 'lodash';
import {debounce} from 'lodash';
import {useRef} from 'react';
import {useEffect} from 'react';
import React, {useState} from 'react';
import {v4 as uuid} from 'uuid';
import TextField from '../../components/TextField';
import {DATE_TIME_DB_FORMAT, DAYS_SINCE_COMPLETION, DATE_FORMAT, SUBMIT_ANSWER} from '../../Constants';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import ConfirmButton from '../../fhg/components/ConfirmButton';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import useEditData from '../../fhg/components/edit/useEditData';
import Grid from '../../fhg/components/Grid';
import TypographyFHG from '../../fhg/components/Typography';
import Typography from '../../fhg/components/Typography';
import {cacheDelete} from '../../fhg/utils/DataUtil';
import {cacheAdd} from '../../fhg/utils/DataUtil';

const useStyles = makeStyles(theme => ({
   root: {
      padding: theme.spacing(0, 2, 0, 5),
      [theme.breakpoints.down('xs')]: {
         padding: theme.spacing(0, 0.5, 0, 1),
      },
   },
   newNoteStyle: {
      padding: theme.spacing(0.5),
   },
   fadeArea: {
      '&:hover $fadeIn': {
         opacity: 0.8,
      },
      maxWidth: 800,
   },
   selectedStyle: {
      opacity: '1 !important',
   },
   notSelectedStyle: {
      opacity: '0 !important',
   },
   iconButtonStyle: {
      '&:hover': {
         opacity: 1,
      }
   },
   fadeIn: {
      opacity: 0
   },
   deleteColorStyle: {
      color: theme.palette.error.main,
      '&:hover': {
         backgroundColor: lighten(theme.palette.error.light, 0.8),
      }
   },
}));

export const TECH_NOTE_FRAGMENT = gql`
   fragment techNoteInfo on TechNote {
      id
      isCompleted
      description
      isDeleted
      createdDateTime
      completionDateTime
   }
`;

const TECH_NOTES_CREATE_UPDATE = gql`
   mutation techNotesCreateUpdate($id: String!, $propertyId: Int!, $description: String, $isCompleted: Boolean) {
      techNote:techNote_CreateUpdate(techNote: {id: $id, propertyId: $propertyId, description: $description, isCompleted: $isCompleted}) {
         ...techNoteInfo
      }
   }
   ${TECH_NOTE_FRAGMENT}
`;

const TECH_NOTES_DELETE = gql`
   mutation techNoteDelete($id: String!){
      techNote_Delete(techNoteId: $id)
   }
`;

const TECH_NOTES_QUERY = gql`
   query getTechNotes($propertyId: [Int], $daysSinceCompletion: Int)
   {
      techNotes: techNote_AllWhere(techNoteSearch: {propertyId: $propertyId, daysSinceCompletion: $daysSinceCompletion}) {
         ...techNoteInfo
      }
   }
   ${TECH_NOTE_FRAGMENT}
`;

const getTechNoteCacheQueries = (propertyId, daysSinceCompletion) => {
   return propertyId ?
      [{query: TECH_NOTES_QUERY, variables: {propertyId, daysSinceCompletion}, queryPath: 'techNotes'}] : [];
}

const TECH_NOTES_HISTORY_QUERY = gql`
   query getTechNotesHistory($propertyId: [Int]) {
      techNotes: techNote_AllWhere(techNoteSearch: {propertyId: $propertyId, isCompleted: [true]}) {
         ...techNoteInfo
      }
   }
   ${TECH_NOTE_FRAGMENT}
`;

/**
 * Component to edit summary.
 *
 * Reviewed:
 */
export default function TechnicalNotes({propertyId, isEditable, showHistory = false, classes: classesProp = {}}) {
   const classes = {...useStyles(), ...classesProp};

   const [isNewNote, setIsNewNote] = useState(false);
   const [isEditNote, setIsEditNote] = useState(false);

   const [/*editValues*/, handleChange, {
      defaultValues,
      setDefaultValues,
      getValue,
      setEditValues
   }
   ] = useEditData(
      undefined, undefined, undefined, (value) => {
         submitChangeDelayed(value, defaultValues, isNewNote, propertyId);
      });

   const [selected, setSelected] = useState();

   const {loading, data, statusComponent} = useQueryFHG(TECH_NOTES_QUERY,
      {
         variables: {propertyId, daysSinceCompletion: DAYS_SINCE_COMPLETION},
         skip: !propertyId || showHistory,
         fetchPolicy: 'cache-and-network'
      });

   const {
      loading: loadingTechnicalNotes,
      data: dataTechnicalNotes,
      statusComponent: statusComponentTechnicalNotes
   } = useQueryFHG(TECH_NOTES_HISTORY_QUERY,
      {variables: {propertyId}, skip: !propertyId || !showHistory, fetchPolicy: 'cache-and-network'});

   const [noteList, setNoteList] = useState([]);

   useEffect(() => {
      let useData;

      if (showHistory) {
         useData = dataTechnicalNotes?.techNotes || [];
      } else {
         useData = data?.techNotes || [];
      }

      setNoteList(reverse(sortBy(useData, 'createdDateTime')));

   }, [data, dataTechnicalNotes, showHistory]);

   const [techNotesCreateUpdate, techNotesStatusComponent] = useMutationFHG(TECH_NOTES_CREATE_UPDATE);
   const [techNotesDelete, deleteTechNotesStatusComponent] = useMutationFHG(TECH_NOTES_DELETE);

   /**
    * When the user clicks the checklist item.
    *
    * @param note The note that was clicked.
    * @return {Function} The callback for the onClick for the checklist item.
    */
   const handleClick = note => async (event) => {
      event.stopPropagation();
      event.preventDefault();

      if (note?.id) {
         await submitChange({id: note.id, isCompleted: event.target.checked}, defaultValues, isNewNote, propertyId);
      } else {
         console.log('Note did not have an id');
      }
   };

   /**
    * Submit the changes to the server.
    * @return {Promise<void>}
    * @param editValues The new values to submit
    */
   const submitChange = async (editValues, defaultValues, isNewNote, propertyId) => {
      const variables = {
         id: editValues?.id || uuid(),
         propertyId,
         ...editValues,
      }

      await techNotesCreateUpdate({
         variables,
         optimisticResponse: {
            __typename: 'Mutation',
            techNote: {
               __typename: 'TechNote',
               ...defaultValues,
               ...variables,
               isDeleted: false,
            }
         },
         update: isNewNote ? cacheAdd(getTechNoteCacheQueries(propertyId, DAYS_SINCE_COMPLETION), 'techNote') :
            undefined,
      });

      setIsNewNote(false);
      setIsEditNote(true);

      if (!editValues?.id) {
         setSelected(variables.id);
      }
   };

   const submitChangeDelayed = useRef(debounce(submitChange, SUBMIT_ANSWER)).current;

   useEffect(() => {
      return submitChangeDelayed.flush();
   }, []);

   const handleAddNote = () => {
      const id = uuid();
      const createdDateTime = add(new Date(), {days: 1});
      const note = {
         id,
         isCompleted: false,
         description: '',
         isDeleted: false,
         createdDateTime: format(createdDateTime, DATE_TIME_DB_FORMAT),
         completionDateTime: '',
      };
      setNoteList(noteList => [note, ...noteList]);
      setIsNewNote(true);
      setSelected(id);
      setEditValues({});
      setDefaultValues(note);
   };

   const handleEditNote = (note) => () => {
      setIsEditNote(true);
      setDefaultValues(note);
      setSelected(note.id);
   };

   const handleBlurNote = () => {
      submitChangeDelayed.flush();

      setTimeout(() => {
         setSelected(undefined);
         setIsNewNote(false);
         setIsEditNote(false);
         setEditValues({description: undefined});
      }, 50);
   };

   const handleSelectNote = (note) => () => {
      setSelected(selected === note?.id ? undefined : note?.id);
   };

   const handleDelete = (id) => async (event) => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      try {
         if (id) {
            await techNotesDelete({
               variables: {id},
               optimisticResponse: {techNote_Delete: 1},
               update: cacheDelete(getTechNoteCacheQueries(propertyId), id),
            });
         } else {
            console.log('Could not delete the tech note that did not have an id', id);
         }
      } catch (error) {
         console.log(error);
      }
   };

   const handleKeydown = (event) => {
      if (event?.key === 'Escape' || event?.key === 'Enter') {
         if (event?.key === 'Escape') {
            submitChangeDelayed.cancel();
         } else if (event?.key === 'Enter') {
            submitChangeDelayed.flush();
         }

         setSelected(undefined);
         setIsNewNote(false);
         setIsEditNote(false);
         setEditValues({description: undefined});
      }
   };

   if (loading || loadingTechnicalNotes) {
      return statusComponent || statusComponentTechnicalNotes || null;
   }

   return (
      <Grid container direction={'column'} wrap='nowrap' className={`${classes.root} ${classes.fullHeight}`}
            fullWidth>
         {techNotesStatusComponent || statusComponent || deleteTechNotesStatusComponent ||
         statusComponentTechnicalNotes}
         {!showHistory && (
            <Grid item>
               <Button color={'secondary'} onClick={handleAddNote} disabled={!isEditable}><Add/>Add Technical
                  Note</Button>
            </Grid>
         )}
         <Grid item container direction={'column'} className={classes.fullHeight} wrap={'nowrap'} overflow='auto'
               fullWidth>

            {noteList.map((note) => (
               <Grid key={note.id} container direction={'row'} className={classes.fadeArea} justify={'space-between'}
                     alignItems={'center'}>
                  <Grid item container direction={'row'} alignItems={'center'} fullWidth={false} resizable
                        wrap={'nowrap'}>
                     <Grid item resizable={false} style={{paddingLeft: 16}}>
                        <CheckboxFHG onChange={handleClick(note)} name={'isCompleted'} size={'small'}
                                     checked={note?.isCompleted}
                                     classes={{checkboxStyle: classes.checkboxStyle}}
                                     disabled={!isEditable}
                        />
                     </Grid>
                     {(isNewNote || isEditNote) && selected === note?.id ? (
                        <Grid item resizable fullWidth>
                           <TextField
                              name='description'
                              // label={<Typography id={'notes.notes.label'}/>}
                              value={getValue('description')}
                              fullWidth
                              autoFocus
                              onChange={handleChange}
                              onBlur={handleBlurNote}
                              onKeyDown={handleKeydown}
                              disabled={!isEditable}
                           />
                        </Grid>
                     ) : (
                        <TypographyFHG key={note?.id} name='isCompleted'
                                       onClick={handleSelectNote(note)}>{note.description}</TypographyFHG>
                     )}
                  </Grid>
                  {(((!isNewNote && !isEditNote) || selected !== note?.id) && !showHistory) && (
                     <Grid item container direction={'row'} fullWidth={false} resizable={false}>
                        <Grid item className={`${classes.iconButtonStyle} ${classes.fadeIn} ${selected === note?.id ?
                           classes.selectedStyle : selected && classes.notSelectedStyle}`}>
                           <IconButton size={'small'} onClick={handleEditNote(note)}>
                              <Edit fontSize='small'/>
                           </IconButton>
                        </Grid>
                        <Grid item className={`${classes.iconButtonStyle} ${classes.fadeIn} ${selected === note?.id ?
                           classes.selectedStyle : selected && classes.notSelectedStyle}`}>
                           <ConfirmButton
                              onConfirm={handleDelete(note?.id)}
                              component={IconButton}
                              buttonLabelKey='notes.delete.button'
                              style={{padding: 6}}
                              color='secondary'
                              messageKey='notes.delete.confirmation'
                              titleKey='notes.deleteNote.button'
                              values={note ? note : {description: 'Untitled'}}
                              confirmProps={{submitColorStyle: classes.deleteColorStyle}}
                           >
                              <Delete fontSize='small'/>
                           </ConfirmButton>
                        </Grid>
                     </Grid>
                  )}
                  {(showHistory && note?.completionDateTime) && (
                     <Typography>{format(new Date(note?.completionDateTime), DATE_FORMAT)}</Typography>
                  )}
               </Grid>
            ))}
         </Grid>
      </Grid>
   );
}
