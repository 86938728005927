import {forOwn, get} from 'lodash';
import castArray from 'lodash/castArray';
import findIndex from 'lodash/findIndex';
import {removeOne} from './Utils';

/**
 * Add the new item to the cache for the list of queries. The query list will have the query, the variables, and the
 * queryPath(optional). If the queryPath isn't specified, the mutationPath will be used
 *
 * @param queryList the list of queries to add the result item. (e.g. {query, variables, queryPath})
 * @param mutationPath Property name resulting object being updated.
 * @return {function(*, {data: {*}}): void}
 */
export const cacheAdd = (queryList, mutationPath) => {
   const useQueryList = castArray(queryList);

   return (proxy, {data}) => {
      for (const queryItem of useQueryList) {
         const {query, variables, queryPath = mutationPath} = queryItem;

         const resultData = get(data, mutationPath);
         // Read the data from our cache for this query.
         const cachedData = proxy.readQuery({query, variables});
         // Write our data back to the cache with the new comment in it
         const newArray = [...(cachedData[queryPath] || []), resultData];
         const newData = {...cachedData, [queryPath]: newArray};
         proxy.writeQuery({query, variables, data: newData});
      }
   };
}

/**
 * Delete the item add the id from the cache for the list of queries. The query list will have the query, the
 * variables, and the queryPath(optional). If the queryPath isn't specified, the path will be used.
 *
 * @param queryList the list of queries to delete the item at id. (e.g. {query, variables, queryPath})
 * @param path Property name resulting object being updated.
 * @return {function(*, {data: {*}}): void}
 */
export const cacheDelete = (queryList, id, path) => {
   const useQueryList = castArray(queryList);

   return (proxy, {data}) => {
      for (const queryItem of useQueryList) {
         try{
            const {query, variables, queryPath = path} = queryItem;

            const cachedData = proxy.readQuery({query, variables});
            const itemIndex = findIndex(cachedData[queryPath], {id});
            if (itemIndex >= 0) {
               const modifiedList = removeOne([...cachedData[queryPath]], itemIndex);
               proxy.writeQuery({
                  query,
                  variables,
                  data: {...cachedData, [queryPath]: modifiedList.length > 0 ? modifiedList : null}
               });
            }
         } catch (error) {
            console.log(error, error.stackTrace);
         }
      }
   };
}

/**
 *
 * @deprecated Use cacheAdd
 */
export const updateAdd = (mutationKey, options) => (proxy, { data }) => {
   let currentOptions = Array.isArray(options) ? options : [options];
   for (const option of currentOptions) {
      updateAddChange(mutationKey, option, proxy, data);
   }
};

export const updateAddChange = (mutationKey, options, proxy, data ) => {
   const { query, key} = options;
   let queryData = proxy.readQuery(options);
   let usingDataKey = queryData[ mutationKey ];
   if (usingDataKey) {
      usingDataKey.push(data[ key]);
   } else {
      get(queryData, key, []).push(data[ mutationKey ]);
   }
   proxy.writeQuery({ query, data: queryData });

   // if (variables && !isEqual(variables, {})) {
   //    let queryData = proxy.readQuery({query, variables});
   //    usingDataKey = queryData[mutationKey];
   //    if (usingDataKey) {
   //       usingDataKey.push(data[key]);
   //    } else {
   //       get(queryData, key, []).push(data[mutationKey]);
   //    }
   //    proxy.writeQuery({query, data: queryData})
   // }
};

export const updateEdit = (mutationKey, options) => (proxy, { data }) => {
   let currentOptions = Array.isArray(options) ? options : [options];
   for (const option of currentOptions) {
      updateEditChange(mutationKey, option, proxy, data);
   }
};

function copyMatchingProperties(originalObject, changedObject) {
   let matchingObject = {};
   forOwn(originalObject, (value, key) => {
      matchingObject[key] = changedObject[key] || value;
   });
   return matchingObject;
}

export const updateEditChange = (mutationKey, options, proxy, data) => {
   const { query, key, variables} = options;
   const queryData = proxy.readQuery({ query, variables});
   const usingDataKey = queryData[ mutationKey ];
   if (usingDataKey) {
      queryData[ mutationKey ] = data[ key];
   } else {
      const selectedIndex = get(queryData, key).findIndex(item => item.id === data[ mutationKey ].id);
      get(queryData, key)[selectedIndex] = copyMatchingProperties(queryData[ key ][selectedIndex], data[ mutationKey ]);
   }
   proxy.writeQuery({ query, data: queryData })
};

/**
 * @deprecated Use cacheDelete
 */
export const updateDelete = (id, options) => (proxy, { data }) => {
   if (options) {
      let currentOptions = Array.isArray(options) ? options : [options];
      for (const option of currentOptions) {
         updateDeleteChange(id, option, proxy, data);
      }
   }
};

export const updateDeleteChange = (id, options, proxy, data) => {
   const queryData = proxy.readQuery(options);
   const selectedIndex = queryData[ options.key ].findIndex(item => item.id === id);
   if (selectedIndex >= 0) {
      removeOne(queryData[ options.key], selectedIndex);
      proxy.writeQuery({ query: options.query, data: queryData })
   } else {
      console.log('Could not find item to remove from cache on delete.');
   }
};
