import { useState, useEffect } from "react";
import { loadingAndError } from "./DataUtils";
import {DocumentNode} from 'graphql';
import {MutationHookOptions, useMutation} from '@apollo/client';

export default function useMutationFHG<MutationType, VariablesType>(
  mutation: DocumentNode,
  options?: MutationHookOptions<MutationType, VariablesType>,
  prefix?: string
) {
  const [statusComponent, setStatusComponent] = useState<
    JSX.Element | null | undefined
  >(null);
  const [mutationFunction, { loading, error, data }] = useMutation<
    MutationType,
    VariablesType
  >(mutation, options);

  useEffect(() => {
    return () => {
      setStatusComponent(null);
    };
  }, []);

  useEffect(() => {
    setStatusComponent(!data ? loadingAndError(loading, error) : null);
    if (error) {
      console.log("useMutation error", error);
    }
  }, [data, loading, error]);

  if (!prefix) {
    return [
      mutationFunction,
      statusComponent,
      { loading, error, data },
    ] as const;
  } else {
    return [
      mutationFunction,
      statusComponent,
      {
        [prefix + "Loading"]: loading,
        [prefix + "Error"]: error,
        [prefix + "Data"]: data,
      },
    ] as const;
  }
}
