import makeStyles from '@material-ui/core/styles/makeStyles';
import {forEach} from 'lodash';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import get from 'lodash/get';
import React, {Fragment, useEffect, useMemo} from 'react';
import TextField from '../../components/TextField';
import {AUDIT_TYPE, ENTRY_SET_VALUE, INCLUSIVE_TYPE, INSPECTION_TYPE, POWER_WASH_TYPE} from '../../Constants';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Grid from '../../fhg/components/Grid';
import ReactSelect from '../../fhg/components/ReactSelect';
import Typography from '../../fhg/components/Typography';
import ValidateTarget from '../../fhg/components/ValidateTarget';
import {formatMessage, removeOne} from '../../fhg/utils/Utils';
import {QUESTION_QUERY} from './QuestionDetailEdit';
import {useIntl} from "react-intl";

const useStyles = makeStyles(theme => ({
   contentStyle: {
      overflow: 'hidden'
   },
   spinnerStyle: {
      color: 'white',
      marginLeft: theme.spacing(0.5),
   },
   noVerticalSpace: {
      paddingBottom: 0,
      paddingTop: 0,
   },
   radioGroupStyle: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: '100%',
   },
   radioStyle: {
      padding: theme.spacing(0.5),
   },
   topMargin: {
      marginTop: theme.spacing(1),
   },
   checkboxStyle: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(3),
   },
}), {name: 'QuestionDetailEditContentStyles'});

QuestionDetailEditContent.propTypes = {};

export default function QuestionDetailEditContent({
   question,
   regulationState,
   editData,
   equipmentList,
   onQuestionChange,
   onChange,
   isSaving
}) {
   const classes = useStyles();
   const intl = useIntl();
   const answerTypeSelectData = useMemo(() => [
      {
         label: formatMessage(intl, 'questionDetail.compliantNonCompliant.label', undefined, undefined),
         value: ENTRY_SET_VALUE.compliantNonCompliant,
      },
      {
         label: formatMessage(intl, 'questionDetail.yesNo.label', undefined, undefined),
         value: ENTRY_SET_VALUE.yesNo,
      },
      {
         label: formatMessage(intl, 'questionDetail.text.label', undefined, undefined),
         value: ENTRY_SET_VALUE.text,
      }
   ], []);
   const {data, statusComponent} = useQueryFHG(QUESTION_QUERY, {variables: {id: question.id || -1}});
   const [regulations, setRegulations] = regulationState;
   const [
      editValues, /*not used */, {
         setIsChanged,
         defaultValues,
         setDefaultValues,
         handleSelectChange: handleSelectEditChange,
      }
   ] = editData;

   useEffect(() => {
      const regulations = [];

      if (!!data) {
         const questionInfo = get(data, 'question', {});
         const regulationInfo = get(questionInfo, 'regulationList', []);
         setDefaultValues(questionInfo);

         forEach(regulationInfo, regulation => {
            const standardName = get(regulation, 'standard.name');
            regulations.push({
               regulation: regulation.regulation,
               reference: regulation.reference,
               standard: {label: standardName, value: standardName}
            });
         });
      }
      setRegulations(regulations);
   }, [data, setRegulations, setDefaultValues]);

   /**
    * Handle changes to a react select component.
    * @param index The index of the item in the array to select.
    * @desc value The value of the react select.
    * @desc name The name of the react select.
    */
   const handleObjectArraySelectChange = (index) => (value, name) => {
      if (value.hasOwnProperty('label')) {
         const array = regulations.slice(0);
         array[index][name] = value;
         setRegulations(array);
         setIsChanged(true);
         onQuestionChange(null);
      }
   };

   const handleRemoveRegulation = index => () => {
      const useRegulations = [...regulations];
      removeOne(useRegulations, index);
      setRegulations(useRegulations);
      setIsChanged(true);
   };

   /**
    * Handle changes to a text input.
    * @param index The index of the object in the array.
    * @desc event The event that triggered the change.
    */
   const handleObjectArrayChange = (index) => (event) => {
      const array = regulations.slice(0);
      array[index][event.target.name] = event.target.value;
      setRegulations(array);
      setIsChanged(true);
      onQuestionChange(event);
   };

   const handleSelectChange = (event, name) => {
      handleSelectEditChange(event, name);
      onQuestionChange?.(event, question.id);
   };

   const handleAnswerTypeChange = (event, name) => {
      handleSelectEditChange(event.value, name);
      onQuestionChange?.(event.value, question.id);
   };

   const handleAddRegulation = () => {
      setRegulations([...regulations, {regulation: undefined, reference: undefined, standard: undefined}]);
   };

   const useQuestionTypeId = editValues?.questionTypeId !== undefined ? +editValues.questionTypeId :
      defaultValues?.questionTypeId || INSPECTION_TYPE;

   return (
      <Grid container item direction={'row'} spacing={2} alignItems={'center'}>
         {statusComponent}
         <Grid item container className={classes.noVerticalSpace} fullWidth={false} alignItems={'center'}>
            <Grid item>
               <FormControl component='fieldset' className={classes.radioGroupStyle}>
                  <FormLabel component='legend'>
                     <Typography id={'questionDetail.type.label'}/>
                  </FormLabel>
                  <RadioGroup aria-label='question type' name='questionTypeId'
                              value={useQuestionTypeId.toString()} row
                              onChange={onChange}>
                     <FormControlLabel value={INSPECTION_TYPE.toString()}
                                       control={<Radio className={classes.radioStyle}/>}
                                       label={<Typography
                                          id={'questionDetail.inspection.label'}/>}/>
                     <FormControlLabel value={INCLUSIVE_TYPE.toString()}
                                       control={<Radio className={classes.radioStyle}/>}
                                       label={<Typography
                                          id={'questionDetail.inclusive.label'}/>}/>
                     <FormControlLabel value={AUDIT_TYPE.toString()}
                                       control={<Radio className={classes.radioStyle}/>}
                                       label={<Typography id={'questionDetail.audit.label'}/>}/>
                     <FormControlLabel value={POWER_WASH_TYPE.toString()}
                                       control={<Radio className={classes.radioStyle}/>}
                                       label={<Typography
                                          id={'questionDetail.powerWash.label'}/>}/>
                  </RadioGroup>
               </FormControl>
            </Grid>
         </Grid>
         <Grid item className={`${classes.topMargin} ${classes.noVerticalSpace}`}>
            <ReactSelect
               name="entrySetValue"
               style={{minWidth: 265, zIndex: 1000}}
               label={<Typography display="inline" id="questionDetail.answerType.label"/>}
               options={answerTypeSelectData}
               defaultValue={defaultValues?.entrySetValue || ENTRY_SET_VALUE.compliantNonCompliant}
               value={answerTypeSelectData.find((selectData) => selectData.value === editValues?.entrySetValue)}
               onChange={handleAnswerTypeChange}
               isMulti={false}
               disabled={isSaving}
               fullWidth
               required
            />
         </Grid>
         <Grid item
               className={`${classes.topMargin} ${classes.noVerticalSpace}`}>
            <ReactSelect
               name={'equipment'}
               style={{minWidth: 150, zIndex: 1000}}
               label={<Typography display={'inline'}
                                  id={'questionDetail.equipment.label'}/>}
               options={equipmentList}
               defaultValue={get(data, 'question.equipment')}
               value={editValues.equipment}
               onChange={handleSelectChange}
               isMulti={false}
               disabled={isSaving}
               fullWidth
               isCreatable
               required
            />
            <ValidateTarget name={'ValidateTargetEquipment'} top={-1}
                            value={editValues.equipment || get(data, 'question.equipment')}/>
         </Grid>
         {useQuestionTypeId !== POWER_WASH_TYPE && (
            <Fragment>
               {regulations.map((regulation, index) => {
                  let useStandard = get(regulations, `[${index}].standard.value`, '');
                  useStandard = useStandard && useStandard !== 'None';
                  return <Grid item container direction={'row'} spacing={2}
                               alignItems={'center'}>
                     <Grid item xs={12} sm={6} md={2} className={classes.noVerticalSpace}>
                        <ReactSelect
                           name={'standard'}
                           label={<Typography display={'inline'}
                                              id={'questionDetail.standard.label'}/>}
                           options={get(data, 'standards', [])}
                           value={regulation.standard}
                           onChange={handleObjectArraySelectChange(index, onQuestionChange)}
                           isMulti={false}
                           disabled={isSaving}
                           isCreatable
                           fullWidth
                        />
                     </Grid>
                     <Grid item xs={12} sm={6} md={2} className={classes.noVerticalSpace}>
                        <TextField
                           name='reference'
                           label={'Reference'}
                           required={useStandard}
                           disabled={!useStandard}
                           fullWidth
                           value={regulation.reference}
                           onChange={handleObjectArrayChange(index)}
                           margin='none'
                        />
                     </Grid>
                     <Grid item xs={12} sm={9} md={6} className={classes.noVerticalSpace}>
                        <TextField
                           name='regulation'
                           label={'Regulation'}
                           required={useStandard}
                           disabled={!useStandard}
                           fullWidth
                           value={regulation.regulation}
                           onChange={handleObjectArrayChange(index)}
                           margin='none'
                           multiline
                           rowsMax={6}
                        />
                     </Grid>
                     <Grid item xs={12} sm={3} md={2}>
                        <Button onClick={handleRemoveRegulation(index)}>
                           <Typography color='secondary'>Remove Regulation</Typography>
                        </Button>
                     </Grid>
                  </Grid>
               })}
               <Grid item xs={12}>
                  <Button onClick={handleAddRegulation}>
                     <Typography color='secondary'>Add Regulation</Typography>
                  </Button>
               </Grid>
            </Fragment>
         )}
      </Grid>
   );
}
