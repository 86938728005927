import Badge from '@material-ui/core/Badge/Badge';
import Button from '@material-ui/core/Button';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import {withStyles} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import gql from 'graphql-tag';
import {find} from 'lodash';
import {filter, differenceBy, isFunction, delay, get, defer, sortBy, findIndex} from 'lodash';
import PropTypes from 'prop-types';
import React, {useState, useEffect, useMemo} from 'react';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import {getAreasForQuestionType} from '../../Utils/dataUtil';
import {INVENTORY_PROPERTY_QUERY} from '../inventory/InventoryItemEdit';
import AreaEdit, {CREATE_AREA, UPDATE_AREA} from '../master/AreaEdit';
import {EQUIPMENT_CREATE} from '../master/EquipmentEdit';
import EquipmentBuilder from './EquipmentBuilder';
import {TEMPLATE_QUERY} from './InspectionBuilder';
import ItemMenu from './ItemMenu';
import {cloneDeep} from 'lodash';
import { Fullscreen, FullscreenExit } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import useFullScreen from '../../fhg/hooks/useFullScreen';

const useStyles = makeStyles(theme => ({
   headingStyle: {
      height: '100%',
      fontWeight: 500,
   },
   fullHeight: {
      height: '100%',
   },
   areaListStyle: {
      height: `calc(100% - ${theme.spacing(6)}px)`,
   },
   noAreaStyle: {
      composes: '$headingStyle',
      padding: theme.spacing(2),
      backgroundColor: theme.palette.type === 'dark' ? '#9B9B9B' : '#D2D2D2',
   },
   leftSpace: {
      marginLeft: theme.spacing(1),
   },
   actionButtonStyle: {
      minWidth: 'max-content',
   },
}));

const ExpansionPanel = withStyles({
   root: {
      backgroundColor: 'transparent',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      borderTop: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:last-child': {
         borderBottom: 'none',
      },
      '&:not(:last-child)': {
         borderBottom: 0,
      },
      '&:before': {
         display: 'none',
      },
      '&$expanded': {
         margin: 'auto',
      },
   },
   expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(theme => ({
   root: {
      backgroundColor: theme.palette.type === 'dark' ? '#9B9B9B' : '#D2D2D2',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
         minHeight: 56,
      },
   },
   content: {
      '&$expanded': {
         margin: '12px 0',
      },
   },
   expanded: {},
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
   root: {
      padding: theme.spacing(0, 0, 2),
   },
}))(MuiExpansionPanelDetails);

const AREA_SMALL_FRAGMENT = gql`
    fragment areaSmallInfo on Area {
        id
        name
        description
        isDeleted
        equipment {
            id
            name
            description
            isDeleted
            questionAliases {
                id
                isDeleted
                markedForAdd
                markedForRemove
            }
        }
    }
`;

export const REPORT_QUERY = gql`
    query getReportAreasForAreaBuilder ($reportId: Int!)
    {
        report:report_ById(reportId: $reportId) {
            id
            isDeleted
            template {
                id
                isDeleted
                areas {
                    ...areaSmallInfo
                }
            }
            answers {
                id
                isDeleted
                questionAliasId
                priorityId
                statusId
                imageData {
                    imageS3
                }
                note
                questionAlias {
                    id
                    isDeleted
                    markedForAdd
                    markedForRemove
                    question {
                        id
                        text
                    }
                }
            }
        }
    }
    ${AREA_SMALL_FRAGMENT}
`;

const MASTER_AREAS_QUERY = gql`
    query getMasterEquipment
    {
        masterAreas:area_AllWhere(areaSearch: {templateId: 1, isCategory: false}) {
            id
            isDeleted
            name
            equipment {
                id
                isDeleted
                name
                questions {
                    id
                    isDeleted
                    text
                    questionTypeId
                }
            }
        }
    }
`;

/**
 * Component to work with areas to build inspection for property.
 */
function AreaBuilder({property, template, questionTypeId, reportId}) {

   const [expanded, setExpanded] = useState(false);
   const [anchorEl, setAnchorEl] = useState();
   const classes = useStyles();
   const [areas, setAreas] = useState([]);
   const [showEdit, setShowEdit] = useState();
   const [scrollToArea, setScrollToArea] = useState();
   const [masterAreas, setMasterAreas] = useState([]);
   const {loading, data, statusComponent} = useQueryFHG(MASTER_AREAS_QUERY, {fetchPolicy: 'cache-and-network'});
   const [areaCreate, areaCreateStatusComponent] = useMutationFHG(CREATE_AREA);
   const [areaUpdate, areaUpdateStatusComponent] = useMutationFHG(UPDATE_AREA);
   const location = useLocation();
   const history = useHistory();

   const isFullScreen = useFullScreen();

   const [equipmentCreate, createEquipmentStatusComponent] = useMutationFHG(EQUIPMENT_CREATE,
      undefined, 'equipment');
   const {data: inventoryData, statusComponent: inventoryStatusComponent} = useQueryFHG(INVENTORY_PROPERTY_QUERY,
      {variables: {propertyId: property.id}, skip: !property || !property.id, fetchPolicy: 'cache-and-network'});

   const inventoryItems = get(inventoryData, 'inventoryItems') || [];

   /**
    * Find all the inventory items that have areas that can be added to the inspection.
    * @return {unknown[]} The list of inventory items with areas.
    */
   const findInventoryItemsInNewAreas = () => {
      return filter(inventoryItems, item => {
         const name = get(item, 'area.name');
         if (name) {
            const areaFound = areas.length > 0 ? findIndex(areas, {name}) : -1;
            return areaFound < 0;
         } else {
            // If the area name is undefined we don't want to use the area for adding.
            return false;
         }
      });
   };

   /**
    * List of Inventory Items with areas that can be added to the inspection.
    * @type {unknown[]|Array<unknown[][keyof unknown[]]>|[]} The list of inventory items with areas.
    */
   const inventoryItemsToUpdate = useMemo(() => {
      if (inventoryItems.length > 0) {
         return sortBy(findInventoryItemsInNewAreas(), ['area.name', 'equipment.name', 'name']);
      }
      return [];
   }, [inventoryItems, areas]);

   /**
    * Add areas and equipment from the inventory items to the inspection.
    * @return {Promise<void>} The promise for the completion of the add.
    */
   const addUpdateForInspectionItems = async () => {

      if (inventoryItemsToUpdate.length > 0) {
         let newAreas = [];
         let previousArea = {};

         for (const inventoryItem of inventoryItemsToUpdate) {
            const areaToAddName = get(inventoryItem, 'area.name');

            if (areaToAddName && areaToAddName !== previousArea.name) {
               const result = await areaCreate(
                  {
                     variables: {name: areaToAddName, templateId: template.id},
                     refetchQueries: getRefetchQueriesForInventory
                  });
               previousArea = result.data.area;
               newAreas.push(previousArea);
            }

            if (inventoryItem.equipmentId) {
               await equipmentCreate({
                  variables: {name: get(inventoryItem, 'equipment.name'), areaId: previousArea.id},
                  refetchQueries: getRefetchQueriesForInventory
               });
            }
         }

         setAreas([...areas, ...newAreas]);
         defer(() => {
            setExpanded(areas.length);
            setScrollToArea(newAreas[newAreas.length - 1]);
         });
      }
   };

   /**
    * Add new areas from the inventory to inspection.
    */
   const updateInspection = async () => {

      if (template && (inventoryItems.length > 0)) {
         addUpdateForInspectionItems();
      }
   }

   useEffect(() => {
      let masterAreas = !!data ? data.masterAreas : [];

      if (questionTypeId && masterAreas.length > 0) {
         masterAreas = getAreasForQuestionType(masterAreas, questionTypeId, 'questions', 'questionTypeId');
      }

      setMasterAreas(masterAreas);
   }, [data, questionTypeId]);

   const markForApproval = function (newAreas) {
      for (const area of newAreas) {
         const equipmentList = get(area, 'equipment', []);
         for (const equipment of equipmentList) {
            const questionAliases = get(equipment, 'questionAliases', []);
            for (const questionAlias of questionAliases) {
               if (!questionAlias.isDeleted && (questionAlias.markedForAdd || questionAlias.markedForRemove)) {
                  equipment.__approveCount = equipment.__approveCount ? equipment.__approveCount + 1 : 1;
               }
            }
            if (equipment.__approveCount > 0) {
               area.__approveCount = area.__approveCount ? area.__approveCount + 1 : 1;
            }
         }
      }
   };

   const clearMarksForApproval = function (newAreas) {
      for (const area of newAreas) {
         area.__approveCount = 0;
         const equipmentList = get(area, 'equipment', []);
         for (const equipment of equipmentList) {
            equipment.__approveCount = 0;
         }
      }
   };


   useEffect(() => {
      let newAreas = [];

      if (template && template.areas && template.areas.length > 0) {
         newAreas = cloneDeep(filter(template.areas, {isDeleted: false}));
         if (!reportId) {
            clearMarksForApproval(newAreas);
            markForApproval(newAreas);
         }
      }
      if (questionTypeId && newAreas?.length > 0) {
         const filteredAreas = filter(newAreas, area => find(masterAreas, {name: area.name}));
         setAreas(filteredAreas);
      } else {
         setAreas(newAreas);
      }
   }, [template, reportId, questionTypeId, masterAreas]);

   useEffect(() => {
      if (scrollToArea) {
         delay(() => {
            const elements = document.getElementsByName(`Area ${scrollToArea.id}`);
            if (elements && elements[0] && isFunction(elements[0].scrollIntoViewIfNeeded)) {
               elements[0].scrollIntoViewIfNeeded();
            }
         }, 750);
      }
   }, [scrollToArea]);

   const handleExpand = (index) => (event, newExpanded) => {
      setExpanded(newExpanded ? index : false);
   };

   const handleOpenAddArea = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleCloseAddArea = () => {
      setAnchorEl(null);
   };

   const handleDelete = area => event => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      areaUpdate({variables: {id: area.id, templateId: null}, refetchQueries: getRefetchQueries});
      setAreas(differenceBy(areas, [{id: area.id}], 'id'));
   };

   const handleEdit = area => event => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      setShowEdit(area);
   };

   const closeEdit = event => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      setShowEdit(undefined);
   };

   const getRefetchQueries = () => {
      const queries = [{query: TEMPLATE_QUERY, variables: {propertyId: property.id}}];

      if (!!reportId) {
         queries.push({query: REPORT_QUERY, variables: {reportId}});
      }

      return queries;
   };

   /**
    * Get the refetch queries when the inventory items are added.
    * @return {[{variables: {propertyId}, query: *}, {variables: {propertyId}, query: *}]}
    */
   const getRefetchQueriesForInventory = () => {
      return [
         {query: TEMPLATE_QUERY, variables: {propertyId: property.id}},
         {query: INVENTORY_PROPERTY_QUERY, variables: {propertyId: property.id}}
      ];
   };

   const handleAddAreas = async (addedAreas = []) => {
      const newAreas = [];
      for (const area of addedAreas) {
         const result = await areaCreate(
            {variables: {name: area.name, templateId: template.id}, refetchQueries: getRefetchQueries});
         newAreas.push(result.data.area);
      }
      setAreas([...areas, ...cloneDeep(newAreas)]);
      defer(() => {
         setExpanded(areas.length);
         setScrollToArea(newAreas[newAreas.length - 1]);
      });
   };

   const handleFullScreen = () => {
      if (isFullScreen) {
         history.push(`${location.pathname}`);
      } else {
         history.push(`${location.pathname}?isFullScreen=true`)
      }
   }

   if (loading) {
      return statusComponent;
   }
   let counts = {};

   return (
      <Grid container direction={'column'} wrap={'nowrap'} className={classes.fullHeight} spacing={2}
            style={{borderBottom: '1px solid lightGrey'}}>
         {statusComponent || areaCreateStatusComponent || areaUpdateStatusComponent || createEquipmentStatusComponent ||
         inventoryStatusComponent}
         {!!showEdit && (
            <AreaEdit open={!!showEdit} onClose={closeEdit} isCreate={false} useDescription area={showEdit}
                      templateId={template && template.id}/>
         )}
         {!reportId && (
            <Grid item container direction={'row'} alignItems={'flex-end'} justify={'flex-end'}>
               {/*<Typography id='propertyQuestions.title' variant={'h6'} color={'inherit'}/>*/}
               <Grid item>
                  <Button
                     color={'secondary'}
                     startIcon={isFullScreen ? <FullscreenExit /> : <Fullscreen />}
                     onClick={handleFullScreen}
                  >
                     {isFullScreen ? 'Exit full screen' : 'Full screen'}
                  </Button>
               </Grid>
               {(inventoryItemsToUpdate.length > 0 && areas.length > 0) && (
                  <Grid item>
                     <Button onClick={updateInspection} color={'secondary'}>
                        <Typography color='inherit'>Add New Inspection Areas</Typography>
                     </Button>
                  </Grid>
               )}
            </Grid>
         )}
         <Grid item container direction={'column'} overflow={'auto'} className={classes.areaListStyle} spacing={2}
               wrap={'nowrap'}>
            <Grid item fullWidth overflow={'auto'} style={{width: 'calc(100% - 18px)', marginRight: 18}}>
               {areas.length > 0 ? areas.map((area, index) => {
                  if (!area.description) {
                     counts[area.name] = counts[area.name] === undefined ? 1 : counts[area.name] + 1;
                  }
                  return (
                     <ExpansionPanel key={`Area ${area.id}`} name={`Area ${area.id}`} expanded={expanded === index}
                                     onChange={handleExpand(index)}>
                        <ExpansionPanelSummary
                           expandIcon={<ExpandMoreIcon/>}
                           aria-controls='panel1a-content'
                           id='panel1a-header'
                        >
                           <Grid container direction={'row'} wrap={'nowrap'} justify={'space-between'}
                                 overflow={'visible'}>
                              <Grid item overflow={'visible'}>
                                 <Badge color='secondary'
                                        badgeContent={area.__approveCount}
                                        className={classes.margin}
                                        anchorOrigin={{horizontal: 'left', vertical: 'top'}}>
                                    <Typography variant={'subtitle1'} className={classes.headingStyle}>
                                       {area.description ||
                                       (counts[area.name] > 1 ? `${area.name} - ${counts[area.name]}` : area.name)}
                                    </Typography>
                                 </Badge>
                              </Grid>
                              {!reportId && (
                                 <Grid item container spacing={1} fullWidth={false} resizable={false}>
                                    <Grid item>
                                       <Button onClick={handleEdit(area)} color={'secondary'}
                                               className={classes.leftSpace}>
                                          <Typography color='inherit' id={'propertyQuestions.edit.button'}/>
                                       </Button>
                                    </Grid>
                                    <Grid item>
                                       <Button onClick={handleDelete(area)} color={'secondary'}
                                               className={classes.leftSpace}>
                                          <Typography color='inherit' id={'propertyQuestions.delete.button'}
                                                      values={area}/>
                                       </Button>
                                    </Grid>
                                 </Grid>
                              )}
                           </Grid>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                           <Grid container direction={'column'} spacing={2}>
                              <EquipmentBuilder
                                 key={`Property EquipmentPanelDetails ${area.id}`}
                                 property={property}
                                 area={area}
                                 masterAreas={masterAreas}
                                 masterEquipment={area.equipment}
                                 propertyEquipment={area.equipment}
                                 actionEquipmentKey={'propertyQuestions.delete.button'}
                                 questionTypeId={questionTypeId}
                                 reportId={reportId}
                              />
                           </Grid>
                        </ExpansionPanelDetails>
                     </ExpansionPanel>
                  );
               }) : (
                  <Grid item container className={classes.noAreaStyle} direction={'row'} alignItems={'center'}>
                     <Typography variant={'subtitle1'}
                                 id={inventoryItemsToUpdate.length <= 0 ? 'propertyQuestions.noAreas.message' :
                                    'propertyQuestions.noAreasWithInventory.message'}/>
                     {inventoryItemsToUpdate.length > 0 && (
                        <Button name='add inspection areas' onClick={updateInspection} color={'secondary'}>
                           <Typography color='inherit'>Add Areas from Inventory</Typography>
                        </Button>
                     )}
                  </Grid>
               )}
            </Grid>
            <Grid item resizable={false}>
               <Button onClick={handleOpenAddArea} color={'secondary'}>
                  <Typography color='inherit'>Add Area</Typography>
               </Button>
               <ItemMenu open={!!anchorEl} anchorEl={anchorEl} onClose={handleCloseAddArea} items={masterAreas}
                         onAddItems={handleAddAreas} itemName={'Areas'} useAddAll={false}
               />
            </Grid>
         </Grid>
      </Grid>
   );
}

AreaBuilder.propTypes = {
   classes: PropTypes.object.isRequired,
   property: PropTypes.object,               // The property for which we are building the inspection.
   template: PropTypes.object,               // The property for which we are building the inspection.
};

export default AreaBuilder;
