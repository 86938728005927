import * as React from "react";

type Action = { type: "SET_CLIENT"; payload: string };
type Dispatch = (action: Action) => void;
type State = { clientId: string };
type ClientImpersonationContextProviderProps = { children: React.ReactNode };

const ClientImpersonationContextStateContext = React.createContext<
  State | undefined
>(undefined);
const ClientImpersonationContextDispatchContext = React.createContext<
  Dispatch | undefined
>(undefined);

function ClientImpersonationContextReducer(state: State, action: Action) {
  switch (action.type) {
    case "SET_CLIENT": {
      return { ...state, clientId: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const initialState: State = { clientId: "" };

function ClientImpersonationContextProvider({
  children,
}: ClientImpersonationContextProviderProps) {
  const [state, dispatch] = React.useReducer(
    ClientImpersonationContextReducer,
    initialState
  );
  return (
    <ClientImpersonationContextStateContext.Provider value={state}>
      <ClientImpersonationContextDispatchContext.Provider value={dispatch}>
        {children}
      </ClientImpersonationContextDispatchContext.Provider>
    </ClientImpersonationContextStateContext.Provider>
  );
}

function useClientImpersonationContextState() {
  const context = React.useContext(ClientImpersonationContextStateContext);
  if (context === undefined) {
    throw new Error(
      "useClientImpersonationContextState must be used within a ClientImpersonationContextProvider"
    );
  }
  return context;
}

function useClientImpersonationContextDispatch() {
  const context = React.useContext(ClientImpersonationContextDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useClientImpersonationContextDispatch must be used within a ClientImpersonationContextProvider"
    );
  }
  return context;
}

export {
  ClientImpersonationContextProvider,
  useClientImpersonationContextState,
  useClientImpersonationContextDispatch,
};
