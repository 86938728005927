import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import gql from 'graphql-tag';
import { get, debounce, default as _ } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { SUBMIT_ANSWER } from '../../Constants';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';

const useStyles = makeStyles(theme => ({
  contentStyle: {},
  root: {
    width: '100%',
  },
  newAreaStyle: {
    position: 'sticky',
    bottom: -theme.spacing(1),
  },
}));

export const CHECKLIST_QUERY = gql`
  query getChecklist {
    checklist: inspectorChecklistEntry_All {
      id
      name
    }
  }
`;

export const REPORT_FRAGMENT = gql`
  fragment reportInfoForChecklist on Report {
    id
    isDeleted
    name
    summary
    checkedInspectorItems
    conclusion
    imageData {
      imageS3
    }
  }
`;

const REPORT_UPDATE = gql`
  mutation UpdateReportForInspection($id: Int!, $checkedInspectorItems: [Int]) {
    report: report_Update(reportId: $id, report: { checkedInspectorItems: $checkedInspectorItems }) {
      ...reportInfoForChecklist
    }
  }
  ${REPORT_FRAGMENT}
`;

/**
 * Component to display an Inspection.
 */
export default function InspectionChecklist({ report, isEditable }) {
  const theme = useTheme();
  const [selected, setSelected] = useState({});
  const [checklistItems, setChecklistItems] = useState([]);
  const { loading, data, statusComponent } = useQueryFHG(CHECKLIST_QUERY, {
    fetchPolicy: 'cache-and-network'
  });
  const [reportUpdate, reportUpdateStatusComponent] = useMutationFHG(
    REPORT_UPDATE,
    undefined,
    'reportUpdate'
  );
  const classes = useStyles();

  useEffect(() => {
    if (!!data) {
      setChecklistItems(get(data, 'checklist', []));
    } else if (!loading) {
      setChecklistItems([]);
    }
  }, [data]);

  useEffect(() => {
    const selected = [];
    if (report.checkedInspectorItems) {
      for (const selectedElement of report.checkedInspectorItems) {
        selected[selectedElement] = true;
      }
    }
    setSelected(selected);
  }, [report]);

  /**
   * When the user clicks the checklist item.
   *
   * @param name The name of the checklist item.
   * @return {Function} The callback for the onClick for the checklist item.
   */
  const handleClick = name => (event) => {
    event.stopPropagation();
    event.preventDefault();

    const newSelected = { ...selected, [name]: !selected[name] };
    setSelected(newSelected);
    submitChangeDelayed(newSelected, checklistItems);
  };

  /**
   * Submit the changes to the server.
   * @param selected The selected list being changed.
   * @param checklistItems The list of checklistItems from the report.
   * @return {Promise<void>}
   */
  const submitChange = async (selected, checklistItems) => {
    const selectedChecklistItems = _.filter(
      checklistItems,
      item => selected[item.id]
    );
    const selectedChecklistIds = selectedChecklistItems.map(item => item.id);

    await reportUpdate({
      variables: {
        id: report.id,
        checkedInspectorItems: selectedChecklistIds,
      }
    });
  };

  const submitChangeDelayed = useRef(
    debounce(submitChange, SUBMIT_ANSWER)
  ).current;

  useEffect(() => {
    return submitChangeDelayed.flush();
  }, []);

  if (loading) {
    return statusComponent;
  }

  return (
    <Grid
      container
      direction={'column'}
      wrap='nowrap'
      className={`${classes.root} ${classes.fullHeight}`}
      spacing={2}
      fullWidth
    >
      {reportUpdateStatusComponent || statusComponent}
      <Grid
        item
        container
        direction={'column'}
        className={classes.fullHeight}
        wrap={'nowrap'}
        overflow='auto'
        fullWidth
      >
        {checklistItems.map((item) => (
          <FormControlLabel
            style={{paddingLeft: 11, backgroundColor: theme.palette.type === 'dark' ? '#7D7D7D' : '#DFDFDF'}}
            control={
              <Checkbox
                checked={!!selected[item.id]}
                onClick={handleClick(item.id)}
                disabled={!isEditable}
                value='selectedChecklistItems'
              />
            }
            label={
              <Typography
                variant={'subtitle1'}
                className={classes.headingStyle}
              >
                {item.name}
              </Typography>
            }
          />
        ))}
      </Grid>
    </Grid>
  );
}

InspectionChecklist.propTypes = {
  report: PropTypes.object, // The report for the checlist.
};
