import {lighten} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {injectIntl} from 'react-intl';
import {withRouter, Prompt} from 'react-router-dom';
import {formatMessage} from '../../utils/Utils';
import ModalDialog from '../dialog/ModalDialog';
import useMutationFHG from './useMutationFHG';

const useStyles = makeStyles(theme => ({
   formStyle: {
      height: '100%',
      overflow: 'hidden',
   },
   submitColorStyle: {
      color: theme.palette.primary.main,
      '&:hover': {
         backgroundColor: lighten(theme.palette.primary.main, 0.9),
      }
   }
}));

/**
 * The New User dialog which creates a new user.
 */
export default function MutationEditDialog({
   open, onClose, titleKey, submitKey, onSubmit, onSuccess, onError,
   createMutation, updateMutation, refetchQueries, leavePageKey, isCreate, maxWidth,
   fullWidth, onBeforeSubmit, isForm = true, children
}) {
   const intl = useIntl();
   const [isSaving, setIsSaving] = useState(false);
   const [isChanged, setIsChanged] = useState(false);
   const [update, updateStatus] = useMutationFHG(isCreate ? createMutation : updateMutation, {refetchQueries});
   const classes = useStyles();

   const handleSubmit = async event => {
      let resultsBefore;
      event.preventDefault();
      setIsSaving(true);

      try {
         if (onBeforeSubmit) {
            resultsBefore = await onBeforeSubmit();
         }

         const variables = onSubmit(resultsBefore);

         update({variables}).then(result => {
            onSuccess(result);
            onClose();
            setIsChanged(false);
         }).catch(error => {
            console.log(error);
            onError && onError();
         }).finally(() => {
            setIsSaving(false);
         });
      } catch (error) {
         setIsSaving(false);
         console.log(error);
         onError && onError();
      }
   };

   return (
      <ModalDialog open={open} onClose={onClose} titleKey={titleKey} isSaving={isSaving} submitKey={submitKey}
                   isForm={isForm} onSubmit={handleSubmit} submitColorStyle={classes.submitColorStyle}
                   maxWidth={maxWidth} fullWidth={fullWidth} disableBackdropClick
      >
         {updateStatus}
         <Prompt when={isChanged} message={() => formatMessage(intl, leavePageKey, 'Discard changes?')}/>
         {children}
      </ModalDialog>
   );
}

MutationEditDialog.propTypes = {
   isLoading: PropTypes.bool,                // Indicates if the data for the component is still loading.
   open: PropTypes.bool,                     // Indicates if the modal dialog should be open.
   onClose: PropTypes.func,                  // Callback when the dialog is closed.
   titleKey: PropTypes.string.isRequired,    // The title key for the title of the dialog.
   submitKey: PropTypes.string,              // The key for the label of the submit button.
   onSubmit: PropTypes.func.isRequired,      // Callback when the data in the dialog is submitted.
   onSuccess: PropTypes.func,                // Callback when the data is successfully submitted.
   onError: PropTypes.func,                   // Callback when an error occurs.
   errorId: PropTypes.string,                // The key for the error message when the data cannot be saved.
   isCreate: PropTypes.bool,                 // Indicates if a new item is being created. Undefined is based on URL
                                             //    and false is update.
   createMutation: PropTypes.object,         // The GraphQL mutation object to create the data.
   updateMutation: PropTypes.object,         // The GraphQL mutation object to update the data.
   updateCache: PropTypes.array,             // Queries to update the cache when the mutation is successful.
   leavePageKey: PropTypes.string,           // The key for the leaving page without saving message.
   children: PropTypes.any.isRequired,       // The children input components that get the new data.
};

MutationEditDialog.defaultProps = {
   isLoading: false,
   open: true,
   submitKey: 'save.label',
   errorId: 'save.error',
   leavePageKey: 'leavePage',
};
