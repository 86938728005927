import { useState, useEffect } from "react";
import { loadingAndError } from "./DataUtils";
import {DocumentNode} from 'graphql';
import {QueryHookOptions, useQuery} from '@apollo/client';

export default function useQueryFHG<QueryType, VariablesType>(
  query: DocumentNode,
  options?: QueryHookOptions<QueryType, VariablesType>
) {
  const [statusComponent, setStatusComponent] = useState<
    JSX.Element | null | undefined
  >(null);

  const { loading, error, data, refetch } = useQuery(query, options);

  useEffect(() => {
    setStatusComponent(!data ? loadingAndError(loading, error) : null);
    if (error) {
      console.log("useQuery error", error);
    }
  }, [data, loading, error]);

  return { loading, error, data, statusComponent, refetch };
}
