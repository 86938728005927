import {useEffect, useRef} from 'react';

/**
 * Creates a debounced function that delays invoking callback until after delay milliseconds have elapsed since the last time
 * the debounced function was invoked.
 *
 * @param {number} delay Delay in milliseconds.
 * @param {number} [maximumDelay] Maximum delay in milliseconds.
 * @returns {Function} Debounced function.
 */
export function useDebounce(delay, maximumDelay) {
  const timeoutId = useRef(null);
  const timeoutStartInMs = useRef(0);

  useEffect(() => {
    return () => {
      if (timeoutId.current !== null) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, []);

  /**
   * Clears the previous scheduled callback and schedules the new callback in the future.
   *
   * @param {Function} callback Callback to be scheduled.
   */
  function execute(callback) {
    if (timeoutId.current === null) {
      timeoutStartInMs.current = Date.now();
    } else {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }

    timeoutId.current = setTimeout(() => {
      callback();
      timeoutId.current = null;
    }, maximumDelay ? Math.min(Math.max(maximumDelay - (Date.now() - timeoutStartInMs.current), 0), delay) : delay);
  }

  return execute;
}