import {find, get} from 'lodash';
import {
   INCOMPLETE_STATUS, COMPLIANT_ANSWER, NOT_APPLICABLE_ANSWER, COMPLETE_STATUS, PARTIAL_STATUS, RECOMMENDED_ANSWER,
   YES_ANSWER, NO_ANSWER, NON_COMPLIANT_ANSWER,
} from './AnswerDetailEdit';
import {ENTRY_SET_VALUE} from "../../Constants";

const checkAnswerStatus = (questionAlias, answers) => {
   let status = INCOMPLETE_STATUS;
   let answer = find(answers, {questionAliasId: questionAlias.id});
   const entrySetValue = questionAlias?.question?.entrySetValue;

   questionAlias.__answer = answer;

   if (answer) {
      if (entrySetValue === ENTRY_SET_VALUE.compliantNonCompliant) {
         if (answer.statusId) {
            if (answer.statusId === Number(NON_COMPLIANT_ANSWER)) {
               if (answer.note && answer.priorityId && answer.imageData?.length > 0) {
                  status = COMPLETE_STATUS;
               } else if (answer.createdDateTime !== answer.updatedDateTime) {
                  status = PARTIAL_STATUS;
               } else {
                  status = INCOMPLETE_STATUS;
               }
            } else {
               status = COMPLETE_STATUS;
            }
         } else {
            status = INCOMPLETE_STATUS;
         }
      } else if (entrySetValue === ENTRY_SET_VALUE.yesNo) {
         if (answer.statusId) {
            status = COMPLETE_STATUS;
         } else {
            status = INCOMPLETE_STATUS;
         }
      } else if (entrySetValue === ENTRY_SET_VALUE.text) {
         if (answer.text) {
            status = COMPLETE_STATUS;
         } else {
            status = INCOMPLETE_STATUS;
         }
      }
   } else {
      console.log('No answer for a questionAlias', questionAlias);
      status = COMPLETE_STATUS;
   }
   return {status, isInProgress: status === PARTIAL_STATUS};
};

const checkQuestionStatus = (questionAliases, answers) => {
   let tempStatus;
   let overallStatus;
   let overallIsInProgress = false;

   if (questionAliases && questionAliases.length > 0) {
      for (const questionAlias of questionAliases) {
         const {status, isInProgress} = checkAnswerStatus(questionAlias, answers);
         questionAlias.__completeStatus = status;
         questionAlias.__isInProgress = isInProgress;
         overallIsInProgress = overallIsInProgress || isInProgress;
         if (status === PARTIAL_STATUS || (tempStatus && status !== tempStatus)) {
            tempStatus = PARTIAL_STATUS;
            overallStatus = PARTIAL_STATUS;
         } else {
            tempStatus = status;
         }
      }
      if (overallStatus !== PARTIAL_STATUS) {
         overallStatus = tempStatus;
      }
   } else {
      overallStatus = COMPLETE_STATUS;
   }
   return {status: overallStatus, isInProgress: overallIsInProgress};
};

const clearQuestionStatus = (questionAliases, answers) => {
   if (questionAliases && questionAliases.length > 0) {
      for (const questionAlias of questionAliases) {
         // clearAnswerStatus(questionAlias, answers);
         questionAlias.__completeStatus = undefined;
         questionAlias.__isInProgress = undefined;
      }
   }
};


const checkEquipmentStatus = (equipmentArray, answers) => {
   let tempStatus;
   let overallStatus;
   let overallIsInProgress = false;

   if (equipmentArray && equipmentArray.length > 0) {
      for (const equipment of equipmentArray) {
         const {status, isInProgress} = checkQuestionStatus(equipment.questionAliases, answers);
         equipment.__completeStatus = status;
         equipment.__isInProgress = isInProgress;
         overallIsInProgress = overallIsInProgress || isInProgress;
         if (status === PARTIAL_STATUS || (tempStatus && status !== tempStatus)) {
            tempStatus = PARTIAL_STATUS;
            overallStatus = PARTIAL_STATUS;
         } else {
            tempStatus = status;
         }
      }
      if (overallStatus !== PARTIAL_STATUS) {
         overallStatus = tempStatus;
      }
   } else {
      overallStatus = COMPLETE_STATUS;
   }
   return {status: overallStatus, isInProgress: overallIsInProgress};
};

const clearEquipmentStatus = (equipmentArray, answers) => {

   if (equipmentArray && equipmentArray.length > 0) {
      for (const equipment of equipmentArray) {
         clearQuestionStatus(equipment.questionAliases, answers);
         equipment.__completeStatus = undefined;
         equipment.__isInProgress = undefined;
      }
   }
};

export const checkAreaStatus = (areas, answers) => {
   let tempStatus;
   let overallStatus;
   let overallIsInProgress = false;

   if (areas && areas.length > 0) {
      for (const area of areas) {
         const {status, isInProgress} = checkEquipmentStatus(area.equipment, answers);
         area.__completeStatus = status;
         area.__isInProgress = isInProgress;
         overallIsInProgress =  overallIsInProgress || isInProgress;
         if (status === PARTIAL_STATUS || (tempStatus && status !== tempStatus)) {
            tempStatus = PARTIAL_STATUS;
            overallStatus = PARTIAL_STATUS;
         } else {
            tempStatus = status;
         }
      }
      if (overallStatus !== PARTIAL_STATUS) {
         overallStatus = tempStatus;
      }
   } else {
      overallStatus = COMPLETE_STATUS;
   }
   return {status: overallStatus, isInProgress: overallIsInProgress};
};

export const clearAreaStatus = (areas, answers) => {
   if (areas && areas.length > 0) {
      for (const area of areas) {
         clearEquipmentStatus(area.equipment, answers);
         area.__completeStatus = undefined;
         area.__isInProgress = undefined;
      }
   }
};
