import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  MuiThemeProvider,
  createTheme,
  responsiveFontSizes
} from '@material-ui/core/styles';
import { lighten, darken } from '@material-ui/core/styles/colorManipulator';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useContext } from 'react';
import AppearanceContext from './fhg/components/AppearanceContext';

// A theme with custom primary and secondary color.
// It's optional.
const defaultTheme = createTheme({
  overrides: {
    MuiFormLabel: {
      // Name of the component ⚛️ / style sheet
      asterisk: {
        // Name of the rule
        display: 'none', // Some CSS
      },
    },
  },
  palette: {
    primary: {
      light: lighten('#1E2021', 0.1),
      main: '#1E2021',
      dark: darken('#1E2021', 0.1), //'#21355A',
    },
    //#C32127
    secondary: {
      light: lighten('#A90004', 0.1),
      main: '#A90004',
      dark: darken('#A90004', 0.1), //'#21355A',
    },
  },
  typography: {
    fontFamily: '"prometo", "Montserrat", "Arial", "Helvetica Neue", "Helvetica", sans-serif;',
  },
});

/**
 * An HOC component to handle setting up the Material-UI theme and provider.
 * @param theme The theme for the application.
 * @param Component The component to apply the theme to.
 * @return {function(*): *}
 */
function withRoot(theme = defaultTheme, Component) {
  function WithRoot(props) {
    const { isDarkMode } = useContext(AppearanceContext);

    let useTheme = React.useMemo(() => {
      let materialTheme;

      if (isDarkMode) {
        materialTheme = {
          palette: {
            type: 'dark',
            primary: {
              main: '#91CAD0',
              // main: '#64E3F1',
              // main: '#1796a4',
              // main: '#24A3B1',
              dark: '#01636c',
            },
            secondary: {
              main: '#fbaa3e',
            },
            text: {
              primary2: '#ffffff',
              // primary: '#ffffff'
            },
            background: {
              default: '#707070',
              // paper: '#9B9B9B',
              paper: '#828282',
            }
          },
          overrides: {
            // MuiFormLabel: {
            //    root: {
            //       color: '#53C3d5',
            //    }
            // },
            // MuiFocused: {
            //    color: '#53C3d5',
            // },
            MuiButton: {
              raisedPrimary: {
                color: 'white',
              },
              // textPrimary: {
              //    color: 'white',
              // },
            },
            MuiTypography: {
              colorPrimary: {
                color: 'white',
              }
            }
          },
          typography: {
            button: {
              textTransform: 'none',
            }
          },
          shape: {
            borderRadius: 0,
          }
        };
      } else {
        materialTheme = theme;
      }
      return createTheme(materialTheme);
    }, [isDarkMode]);
    useTheme = responsiveFontSizes(useTheme);
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={useTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <CssBaseline />
          <Component {...props} />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
