import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import MapFHG from '../../fhg/components/map/MapFHG';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Switch,
  TextField,
  useTheme,
} from '@material-ui/core';
import { ZOOM_DEFAULT } from '../../Constants';
import { find, get, isArray, isObject } from 'lodash';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import { PROPERTY_QUERY } from '../property/Property';
import ReactSelect, { DELETE_ACTION } from '../../fhg/components/ReactSelect';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import {
  MASTER_AREAS_INVENTORY_QUERY,
  MASTER_EQUIPMENT_INVENTORY_QUERY,
} from '../inventory/InventoryItemEdit';
import Form from '../../fhg/components/Form';
import useEditData from '../../fhg/components/edit/useEditData';
import gql from 'graphql-tag';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import { useIntl } from 'react-intl';
import { formatMessage } from '../../fhg/utils/Utils';
import { ArrowBack, Delete, Edit } from '@material-ui/icons';
import DrawerContext from '../../components/DrawerContext';
import EquipmentEdit from '../master/EquipmentEdit';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
  },
  button: {
    height: '50px',
    backgroundColor: theme.palette.primary.dark,
    width: '100%',
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: '500',
  },
  content: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 'bold',
  },
  textField: {
    width: '100%',
    height: '50px',
    '& .MuiInputBase-root': {
      height: '50px',
    },
  },
  selector: {
    flexWrap: 'nowrap',
  },
}));

const DEFAULT = { lat: 36.231265, lng: -115.110922 };

const INVENTORY_LOCATION_CREATE = gql`
  mutation inventoryLocation_Create($inventoryLocation: InventoryLocationCreateInput!) {
    inventoryLocation_Create(inventoryLocation: $inventoryLocation) {
      id
    }
  }
`;

const INVENTORY_LOCATION_UPDATE = gql`
  mutation inventoryLocation_Update(
    $inventoryLocationId: Int!
    $inventoryLocation: InventoryLocationUpdateInput!
  ) {
    inventoryLocation_Update(
      inventoryLocationId: $inventoryLocationId
      inventoryLocation: $inventoryLocation
    ) {
      id
    }
  }
`;

const INVENTORY_LOCATION_DELETE = gql`
  mutation inventoryLocation_Delete($inventoryLocationId: Int!) {
    inventoryLocation_Delete(inventoryLocationId: $inventoryLocationId)
  }
`;

const INVENTORY_LOCATION_ALL_QUERY = gql`
  query inventoryLocation_AllWhere($inventoryLocationSearch: InventoryLocationSearchInput) {
    inventoryLocation_AllWhere(inventoryLocationSearch: $inventoryLocationSearch) {
      id
      clientId
      inventoryItemId
      equipmentId
      propertyId
      name
      description
      latitude
      longitude
      equipment {
        areaId
        area {
          id
          pinType
        }
      }
    }
  }
`;

const EQUIPMENT_FRAGMENT = gql`
  fragment equipmentInfo on Equipment {
    id
    name
    equipmentMasterId
    isDeleted
    value: id
    areaId
    label: name
    isEntity
    manufacturerId
    modelId
  }
`;

const EQUIPMENT_CREATE = gql`
  mutation equipment_Create($equipment: EquipmentCreateInput!) {
    equipment_Create(equipment: $equipment) {
      ...equipmentInfo
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;

const Map = ({ match }) => {
  const classes = useStyles();
  const theme = useTheme();
  const mapRef = useRef();
  const [longLat, setLongLat] = useState(DEFAULT);
  const [label, setLabel] = useState('-');

  const [showEquipmentEdit, setShowEquipmentEdit] = useState(false);
  const [equipmentEditValues, setEquipmentEditValues] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  const { isDrawerOpen } = useContext(DrawerContext);

  const intl = useIntl();

  const propertyCoord = useRef(null);

  useEffect(() => {
    document.title = formatMessage(intl, 'inspect.assets.button.label');
  }, []);

  const [firstCenter, setFirstCenter] = useState(DEFAULT);

  const [createInventoryLocation, , { loading }] = useMutationFHG(INVENTORY_LOCATION_CREATE);

  const [deleteInventoryLocation] = useMutationFHG(INVENTORY_LOCATION_DELETE);
  const [updateInventoryLocation] = useMutationFHG(INVENTORY_LOCATION_UPDATE);
  const [createEquipment] = useMutationFHG(EQUIPMENT_CREATE);

  const { loading: loadingMasterEquipment, data: dataMasterEquipment = [], refetch: reFetchMasterEquipmentInventory } = useQueryFHG(MASTER_EQUIPMENT_INVENTORY_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
    
  const {
    data: dataMasterAreas = [],
    refetch: reFetchMasterAreasInventory,
  } = useQueryFHG(MASTER_AREAS_INVENTORY_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const areaOptions = get(dataMasterAreas, 'masterAreas') || [];

  const handleMapChange = (e) => {
    setLongLat(e);
  };

  const handleDeleteAsset = async () => {
    try {
      setShowModalDelete(false);
      await deleteInventoryLocation({
        variables: {
          inventoryLocationId: currentAsset.current.id,
        },
      });
      await refetch();
      setLongLat(propertyCoord.current);
      setFirstCenter(propertyCoord.current);
      setLabel('-');
    } catch (error) {
      console.error('Error deleting inventory location', error);
    }
  };

  const handleEditAsset = async () => {
    try {
      setShowModalEdit(false);
      await updateInventoryLocation({
        variables: {
          inventoryLocationId: currentAsset.current.id,
          inventoryLocation: {
            name,
          },
        },
      });
      await refetch();
      setLabel(name);
      setName('');
    } catch (error) {}
  };

  const [enableDynamicLocation, setEnableDynamicLocation] = React.useState(false);

  const [showModal, setShowModal] = React.useState(false);
  const [showModalDelete, setShowModalDelete] = React.useState(false);
  const [showModalEdit, setShowModalEdit] = React.useState(false);

  const currentAsset = useRef(null);
  const [name, setName] = useState('');

  const handleChangeEnableDynamicLocation = (event) => {
    setEnableDynamicLocation(event.target.checked);
  };

  const id = Number(get(match, 'params.id', 0));

  const { data: dataInventory, refetch } = useQueryFHG(INVENTORY_LOCATION_ALL_QUERY, {
    variables: {
      inventoryLocationSearch: {
        propertyId: id,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  const { inventoryLocation_AllWhere } = dataInventory || {};

  const { data } = useQueryFHG(PROPERTY_QUERY, {
    variables: { id },
    fetchPolicy: 'cache-first',
  });

  const property = get(data, 'property');

  useEffect(() => {
    if (property) {
      setLongLat({ lat: property.latitude, lng: property.longitude });
      setFirstCenter({ lat: property.latitude, lng: property.longitude });
      propertyCoord.current = { lat: property.latitude, lng: property.longitude };
    }
  }, [property]);

  const timer = useRef(null);

  useEffect(() => {
    if (enableDynamicLocation) {
      timer.current = setInterval(() => {
        window.navigator.geolocation.getCurrentPosition(
          (position) => {
            setLongLat({ lat: position.coords.latitude, lng: position.coords.longitude });
            setFirstCenter({ lat: position.coords.latitude, lng: position.coords.longitude });
          },
          (error) => {
            console.log('Error getting location', error);
          }
        );
      }, 1000);
    } else {
      clearInterval(timer.current);
    }
  }, [enableDynamicLocation]);

  const [editValues, handleChange, { handleSelectChange, setEditValues, resetValues }] = useEditData({
    assetType: null,
    assetTypeName: '',
  });

  const textFieldRef = useRef();
  const selectRef = useRef();

  const handleSubmit = async () => {
    try {
      await createInventoryLocation({
        variables: {
          inventoryLocation: {
            equipmentId: editValues.assetType.id,
            name: editValues.assetTypeName,
            latitude: longLat.lat,
            longitude: longLat.lng,
            propertyId: id,
          },
        },
      });
      await refetch();
      resetValues();
      textFieldRef.current.value = '';
      selectRef.current?.selectRef?.current?.select?.clearValue();
    } catch (error) {
      console.error('Error creating inventory location', error);
    }
  };

  const markers = useMemo(
    () =>
      inventoryLocation_AllWhere?.map((item) => ({
        id: item.id,
        name: item.name,
        lng: item.longitude,
        lat: item.latitude,
      })),
    [inventoryLocation_AllWhere],
  );

  const handleAddEquipment = async (name) => {
    try {
      const data = await createEquipment({
        variables: {
          equipment: {
            name,
            propertyId: id,
          },
        },
      });
      await reFetchMasterEquipmentInventory()
      await reFetchMasterAreasInventory()

      editValues.assetType = { ...data?.data.equipment_Create };

      setEditValues({ ...editValues });
      
      const areaValue = find(areaOptions, { id: data?.data.equipment_Create?.id });
      setSelectedArea(areaValue);

    } catch (error) {
      console.error('Error creating equipment', error);
    }
  };

  /**
   * Can the equipment be deleted or edited.
   *
   * @param equipment The equipment to check.
   * @param action The action to check
   * @return {*|boolean} Indicates if the action can be performed on the selected equipment.
   */
  const handleCanEquipmentAction = (equipment, action) => {
    const path = isArray(equipment) ? '[0].isEntity' : 'isEntity';
    return action === DELETE_ACTION ? get(equipment, path) : true;
  };

  /**
   * Show the edit for the equipment selected. The parameter is a list, but only one is selected and so the edit is
   * for the first item.
   *
   * @param equipmentList The equipment list to edit.
   */
  const handleEquipmentEdit = (equipmentList) => {
    if (equipmentList && equipmentList.length > 0) {
      let areaValue = null;
      
      if (isObject(editValues?.assetType)) {
        // console.log('isObject', editValues?.assetType);
        const areaId = editValues?.assetType?.[0]?.areaId ?? editValues?.assetType?.areaId;
        areaValue = find(areaOptions, { id: areaId });
      } else if (isArray(editValues?.assetType)) {
        areaValue = editValues?.assetType[0];
      }
      // console.log('handleEquipmentEdit', areaValue);
      setShowEquipmentEdit(true);
      setEquipmentEditValues(equipmentList[0]);
      setSelectedArea(areaValue);
    }
  };

  /**
   * Close the Equipment edit dialog.
   */
  const closeEditEquipment = () => {
    setShowEquipmentEdit(false);
    setEquipmentEditValues(null);
  };
  /**
   * Callback for the submit success. Update the id property for the equipment.
   *
   * @param equipment The equipment result of the submit.
   */
  const handleEquipmentSubmitSuccess = (equipment) => {
    let newAssetType = editValues.assetType;
    let areaId = null;
    if (newAssetType?.length > 0) {
      areaId = newAssetType?.[0]?.areaId || editValues?.equipment?.areaId
      newAssetType[0] = { ...newAssetType[0], ...equipment?.equipment };
    } else {
      areaId = newAssetType?.areaId || editValues?.equipment?.areaId
      newAssetType = { ...newAssetType, ...equipment?.equipment }
    }
    editValues.assetType = newAssetType;

    setEditValues({ ...editValues, equipment: { ...equipment?.equipment, areaId} });

    const areaValue = find(areaOptions, { id: areaId });
    setSelectedArea(areaValue);
  };

  return (
    <Grid container item className={classes.root} wrap={'nowrap'} direction={'column'}>
      {showEquipmentEdit && (
        <EquipmentEdit
          componentName="Map"
          onClose={closeEditEquipment}
          area={selectedArea}
          equipment={equipmentEditValues}
          onSuccess={handleEquipmentSubmitSuccess}
          skipRefetch
        />
      )}
      {isDrawerOpen && (
        <Grid
          container
          item
          style={{
            width: '20%',
            backgroundColor: theme.palette.background.paper,
            marginRight: '10px',
          }}
        >
          <Form
            submit={handleSubmit}
            style={{
              flex: 1,
            }}
          >
            <Box
              style={{
                flex: 1,
                height: '100%',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box mb={2} display="flex" alignItems="center">
                  <IconButton href={`/property/${id}`}>
                    <ArrowBack />
                  </IconButton>
                  <Typography variant="h6">New Asset</Typography>
                </Box>
                <Typography id="enable.dynamic.location" variant={'title'} color={'text'} />
                <Switch
                  size="medium"
                  checked={enableDynamicLocation}
                  onChange={handleChangeEnableDynamicLocation}
                  color="primary"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <Box
                  style={{
                    marginTop: '24px',
                  }}
                >
                  <ReactSelect
                    ref={selectRef}
                    classes={{
                      valueContainer: classes.selector,
                    }}
                    name="assetType"
                    label={<Typography display="inline" id="new.asset.type" />}
                    options={dataMasterEquipment?.masterEquipment || []}
                    value={editValues.assetType?.[0] || editValues.assetType}
                    onChange={handleSelectChange}
                    isClearable
                    fullWidth
                    isMulti={false}
                    isCreatable
                    onCanDoAction={handleCanEquipmentAction}
                    isEditable={!loadingMasterEquipment}
                    onEditChange={handleEquipmentEdit}
                    onCreateOption={handleAddEquipment}
                    openMenuOnClick={false}
                    margin="none"
                  />
                </Box>
                <Box
                  style={{
                    marginTop: '24px',
                  }}
                >
                  <TextField
                    inputRef={textFieldRef}
                    name="assetTypeName"
                    className={classes.textField}
                    label={<Typography display="inline" id="new.enter.asset.name" />}
                    variant="outlined"
                    size="medium"
                    onChange={handleChange}
                    value={editValues.assetTypeName}
                  />
                </Box>
                <Button
                  disabled={!editValues.assetType || !editValues.assetTypeName || loading}
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    setShowModal(true);
                  }}
                  style={{
                    marginTop: '24px',
                  }}
                >
                  <Typography
                    variant={'body1'}
                    style={{
                      color: '#ffffff',
                    }}
                    id={'capture.location.button'}
                  />
                </Button>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '24px',
                  }}
                >
                  <Typography id={'capture.location.lat'} className={classes.title} />
                  <Typography className={classes.content}>{longLat.lat}</Typography>
                  <Box height={10} />
                  <Typography id={'capture.location.lng'} className={classes.title} />
                  <Typography className={classes.content}>{longLat.lng}</Typography>
                </Box>
              </Box>
              <Box
                mt={2}
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography id={'Inventory List'} className={classes.title} />
                <List
                  style={{
                    maxHeight: '300px',
                    overflow: 'auto',
                  }}
                >
                  {inventoryLocation_AllWhere?.map((item) => (
                    <ListItem
                      key={item.id}
                      component={Button}
                      onClick={() => {
                        setLongLat({ lat: item.latitude, lng: item.longitude });
                        setFirstCenter({ lat: item.latitude, lng: item.longitude });
                        setLabel(item.name);
                      }}
                      style={{
                        borderBottom: '1px solid #e0e0e0',
                        display: 'flex',
                      }}
                    >
                      <Box
                        display={'flex'}
                        flex={0.8}
                        style={{
                          wordBreak: 'break-all',
                        }}
                      >
                        <ListItemText className={classes.title} primary={item.name} />
                      </Box>
                      <Box display={'flex'} flex={0.2}>
                        <IconButton
                          edge="end"
                          aria-label="edit"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            currentAsset.current = item;
                            setShowModalEdit(true);
                            setLongLat({ lat: item.latitude, lng: item.longitude });
                            setFirstCenter({ lat: item.latitude, lng: item.longitude });
                            setLabel(item.name);
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            currentAsset.current = item;
                            setShowModalDelete(true);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </Form>
        </Grid>
      )}
      <Box
        style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          padding: '10px',
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <MapFHG
          name="map"
          ref={mapRef}
          current={longLat}
          defaultCenter={firstCenter}
          defaultZoom={ZOOM_DEFAULT}
          onChange={handleMapChange}
          onClickOnMap={(e) => {
            setLongLat(e.latlng);
          }}
          disabled={enableDynamicLocation}
          makerLabel={label}
          markers={markers}
          showPopup
        />
      </Box>
      <ModalDialog
        open={showModal}
        onClose={() => setShowModal(false)}
        messageKey="content.save.location"
        submitKey={'button.save'}
        onSubmit={() => {
          setShowModal(false);
          handleSubmit();
        }}
        submitColor="primary"
        variantSubmit="contained"
        variantCancel="contained"
      />
      <ModalDialog
        open={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        messageKey="modal.delete.asset.title"
        submitKey={'inspect.yesAnswer.menuItem'}
        cancelKey={'inspect.noAnswer.menuItem'}
        onSubmit={handleDeleteAsset}
        submitColor="primary"
        variantSubmit="contained"
        variantCancel="contained"
      />
      <ModalDialog
        open={showModalEdit}
        onClose={() => setShowModalEdit(false)}
        messageKey="modal.edit.asset.title"
        submitKey={'add.edit'}
        cancelKey={'cancel.button'}
        onSubmit={handleEditAsset}
        submitColor="primary"
        variantSubmit="contained"
        variantCancel="contained"
      >
        <TextField
          defaultValue={currentAsset.current?.name}
          onChange={(e) => setName(e.target.value)}
        />
      </ModalDialog>
    </Grid>
  );
};

export default Map;
