import CircularProgress from '@material-ui/core/CircularProgress';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Error404 from '../fhg/components/Error404';
import ErrorSnackbar from '../fhg/components/ErrorSnackbar';
import get from 'lodash/get';

export class Server {
   // noinspection JSUnusedLocalSymbols
   fetch = (options) => {
      console.log("Please override fetch");
   };

   // noinspection JSUnusedLocalSymbols
   query = (options) => {
      console.log("Please override query");
   };
}


const styles = createStyles({
   progressStyle: {
      position: 'fixed',

      top: 120,
      left: 10,
      zIndex: 1001,
   },
});

const Request = withStyles(styles)(class extends Component {
   collection;

   static propTypes = {
      children: PropTypes.func.isRequired,
      server: PropTypes.object.isRequired,
      data: PropTypes.object,
      fetchOptions: PropTypes.any,
      node: PropTypes.node,
      showOnLoad : PropTypes.bool,
      errorMessageId: PropTypes.string.isRequired,
      errorMessage: PropTypes.string,
      errorValues: PropTypes.string,
      showProgress: PropTypes.bool,
      refresh: PropTypes.any,
   };

   static defaultProps = {
      node: 'div',
      showOnLoad: true,
      showProgress: true,
      refresh: 0,
   };

   constructor(props) {
      super(props);


      this.state = {
         isLoading: false,
         data: undefined,
         showError: false,
      };
   }

   /**
    * Load the data by fetching from the server. If data is supplied as a prop, the load is skipped and data is used, to
    * avoid refetching data already retrieved.
    *
    * @param props   The props to be used for the options.
    * @return {Promise<void>}
    */
   load = (props) => {
      const { onLoaded, data, server} = props;

      if (!data) {
         this.setState({ isLoading: true, showError: false });
         server.fetch(props.fetchOptions).then((result) => {
            let state = {
               isLoading: false,
               data: result || [],
               // errorMessage: result.errorMessage,
            };
            this.setState(state, () => {
               onLoaded && onLoaded(result);
            });
         }).catch((error) => {
            this.setState({ isLoading: false, showError: true, errorValues: { message: error.message }  });
         });
      } else {
         this.setState({isLoading: false, showError: false, data, errorMessage: undefined}, () => {
            if (onLoaded) {
               onLoaded(data);
            }
         });
      }
   };

   /**
    * Loads the data on demand. Similar to the load call, but occurs when called instead of when the component displays.
    * @param query The data needed by the server to query for the data (e.g. filters).
    */
   handleQuery = (query) => {
      const { onLoaded, server} = this.props;

      this.setState({ isLoading: true, showError: false });
      server.query(query).then((result) => {
         let state = {
            isLoading: false,
            data: result || [],
         };
         this.setState(state, () => {
            onLoaded && onLoaded(result);
         });
      }).catch((error) => {
         this.setState({ isLoading: false, showError: true, errorValues: { message: error.message }  });
      });
   };

   /**
    * Load the data from the server.
    * @return {Promise<void>}
    */
   componentWillMount() {
      // noinspection JSIgnoredPromiseFromCall
      this.load(this.props);
   }

   /**
    * If the refresh prop has changed, reload the data from the server.
    * @param prevProps The old props.
    */
   componentDidUpdate(prevProps) {
      const {refresh} = this.props;
      if (refresh && refresh > prevProps.refresh) {
         // noinspection JSIgnoredPromiseFromCall
         this.load(this.props);
      }
   }

   /**
    * Close the error snackbar.
    */
   handleErrorClose = () => {
      this.setState({ showError: false });
   };

   /**
    * Render the children of the compnent with the automatic props added to the child component or call the function
    * with the props.
    *
    * @return {*} The component or children function.
    */
   renderChildren = () => {
      const { propKey, children } = this.props;
      const { isLoading, error, data } = this.state;

      if (typeof children === 'function') {
         return children(data, isLoading, error, this.handleQuery);
      } else if (typeof children === 'object') {
         return React.Children.map(children, child => {
            // noinspection JSUnresolvedFunction
            return React.cloneElement(child, {
               isLoading,
               [ propKey ]: data,
               error,
               handleQuery: this.handleQuery,
            });
         });
      }
   };

   render() {
      // noinspection JSUnusedLocalSymbols
      const { classes,  node, showOnLoad, showProgress, errorMessageId, onLoaded, isLoading:unused,
         errorMessage, errorValues:errorValuesProp, children, propKey, staticContext, ...props} = this.props;
      const { isLoading, showError, errorValues, error, } = this.state;

      const Component  = node;

      if (get(error, 'response.status') === 404) {
            return <Error404/>
      }

      // props.classes = classes;

      return (
         <Component className={classes.requestRoot} classes={{...classes}} {...props}>
            <ErrorSnackbar open={showError} onClose={this.handleErrorClose} errorId={errorMessageId}
                           values={errorValues || errorValuesProp} message={errorMessage}/>
            {showProgress && !showError && isLoading && <CircularProgress className={classes.progressStyle}/>}
            {(showOnLoad || !isLoading) && this.renderChildren()}
         </Component>
      )
   }
});

export default withRouter(Request);

// noinspection JSCommentMatchesSignature
/**
 *
 * @param WrappedComponent The component which will use the data.
 * @param options The options for the query
 *    method The method of the request (e.g. get, post, put). Default is get.
 *    propKey   The result property name of the data.
 *    propKeyNext   The result property name of the data requested onNext.
 *    errorMessageId The message id for the error message.
 *    errorMessage The message for the error message.
 *    showOnLoad True if the component handles loading indicator. Defaults to true.
 *    showProgress: True if the component should show the progress indicator. Defaults to true.
 * @return {<{}> | *} The HOC.
 */
export const withRequest = (options) => WrappedComponent => {
   if (!options.propKey) {
      options.propKey = 'data';
   }

   if (!options.errorMessageId) {
      options.errorMessageId = 'fetch.error';
   }

   class RequestHOC extends Component {

      render() {
         const { classes, wrappedRef, ...props } = this.props;
         const { propKey } = options;

         let dataProps = {};

         return (
            <Request {...this.props} {...options}>
               {(data, isLoading, error, handleQuery) => {
                  if (!isLoading) {
                     dataProps[propKey] = data;
                  }

                  return (
                     <WrappedComponent ref={wrappedRef} classes={classes}  {...props} onQuery={handleQuery} error={error} isLoading={isLoading} {...dataProps}/>
                  )
               }}
            </Request>
         )
      }
   }

   return RequestHOC;
};

export function showFile(title, blob, type = 'pdf') {
   // It is necessary to create a new blob object with mime-type explicitly set
   // otherwise only Chrome works like it should
   var newBlob = blob; //new Blob([blob], {type: "application/pdf"})

   // IE doesn't allow using a blob object directly as link href
   // instead it is necessary to use msSaveOrOpenBlob
   if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob, `${title}.${type}`);
      return;
   }

   // For other browsers:
   // Create a link pointing to the ObjectURL containing the blob.
   const data = window.URL.createObjectURL(newBlob);
   var link = document.createElement('a');
   link.href = data;
   link.target = '_blank';
   link.download = `${title}.${type}`;

   document.body.appendChild(link);
   link.click();

   link.parentNode.removeChild(link);
}
