import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import gql from 'graphql-tag';
import {isArray} from 'lodash';
import {castArray} from 'lodash';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import MenuWithAddDelete from '../../components/MenuWithAddDelete';
import TextField from '../../components/TextField';
import MutationEditDialog from '../../fhg/components/data/MutationEditDialog';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import useEditData from '../../fhg/components/edit/useEditData';
import {PhoneNumberField} from '../../fhg/components/input/TextMaskCustom';
import {DELETE_ACTION} from '../../fhg/components/ReactSelect';
import ReactSelect from '../../fhg/components/ReactSelect';
import FHGTypography from '../../fhg/components/Typography';
import Typography from '../../fhg/components/Typography';
import ValidateTarget from '../../fhg/components/ValidateTarget';
import {PROPERTY_FRAGMENT} from '../Types';
import {DEFAULT_REGION} from './PropertyView';
import {PROPERTIES_QUERY} from './PropertyView';

const useStyles = makeStyles({
   marginRight: {
      marginRight: 8,
   },
}, {name: 'PropertyInfoEditStyles'});

const OPTIONS_QUERY = gql`
    query getPropertyOptions {
        states:state_All {
            id
            isDeleted
            name
            value:id
            label:name
            abbreviation
        }
        regions: region_All {
            id
            isDeleted
            name
            value: id
            label: name
        }
        propertyTypes: propertyType_All {
            id
            name
            isDeleted
            value: id
            label: name
        }
    }
`;

export const REGION_FRAGMENT = gql`
    fragment regionEditInfo on Region {
        id
        isDeleted
        name
        value:id
        label:name
    }
`;

const CREATE_PROPERTY = gql`
    mutation CreateProperty($name: String!, $addressLineOne: String!, $addressLineTwo: String, $phone: String,
        $email: String, $type: String, $contactName: String, $information: String, $schedule: String, $city: String,
        $stateId: Int, $zipCode: Int, $userIdList: [Int], $mapImageS3Data: ImageS3Data,
        $regionId: Int, $latitude: Float, $longitude: Float, $propertyTypeId: Int, $companyName: String
    ) {
        property: property_Create(property: {name: $name, addressLineOne: $addressLineOne, addressLineTwo: $addressLineTwo,
            phone: $phone, email: $email, type: $type, contactName: $contactName, information: $information,
            scheduleOfInspections: $schedule, city: $city, stateId: $stateId, zipCode: $zipCode, userIdList: $userIdList,
            regionId: $regionId, latitude: $latitude, longitude: $longitude, propertyTypeId: $propertyTypeId,
           mapImageS3Data: $mapImageS3Data, companyName: $companyName
        }) {
            ...propertyInfo
        }
    }
    ${PROPERTY_FRAGMENT}
`;

export const UPDATE_PROPERTY = gql`
    mutation UpdateProperty($id: Int!, $name: String, $addressLineOne: String, $addressLineTwo: String, $phone: String,
        $email: String, $type: String, $contactName: String, $information: String, $schedule: String, $city: String,
        $stateId: Int, $zipCode: Int, $userIdList: [Int], $mapImageS3Data: ImageS3Data
        $regionId: Int, $latitude: Float, $longitude: Float, $propertyTypeId: Int, $companyName: String
    ) {
        property:property_Update(propertyId: $id, property:{name: $name, addressLineOne: $addressLineOne,
            addressLineTwo: $addressLineTwo, phone: $phone, email: $email, type: $type, contactName: $contactName,
            information: $information, scheduleOfInspections: $schedule, city: $city, stateId: $stateId, zipCode: $zipCode,
            userIdList: $userIdList, mapImageS3Data: $mapImageS3Data,
            regionId: $regionId, latitude: $latitude, longitude: $longitude, propertyTypeId: $propertyTypeId, 
            companyName: $companyName
        }) {
            ...propertyInfo
        }
    }
    ${PROPERTY_FRAGMENT}
`;

const CREATE_REGION = gql`
    mutation CreateRegion($name: String!) {
        region:region_Create(region:{name: $name}) {
            ...regionEditInfo
        }
    }
    ${REGION_FRAGMENT}
`;

const DELETE_REGION = gql`
    mutation deleteRegion($id: Int!)
    {
        region_Delete(regionId: $id)
    }
`;

const PROPERTY_TYPE_CREATE = gql`
    mutation propertyTypeCreate($name: String!)
    {
        propertyType: propertyType_Create(propertyType: {name: $name}) {
            id
            value:id
            name
            label:name
        }
    }
`;

const PROPERTY_TYPE_DELETE = gql`
    mutation deletePropertyType($id: Int!)
    {
        propertyType_Delete(propertyTypeId: $id)
    }
`;

PropertyInfoEdit.propTypes = {
   open: PropTypes.bool,
   onClose: PropTypes.func,
   property: PropTypes.object,
   isCreate: PropTypes.bool,           //Indicates if a new property is being created.
};

/**
 * The component to edit a property.
 */
export default function PropertyInfoEdit({open, property, onClose, isCreate}) {
   const classes = useStyles();
   const history = useHistory();

   const {loading, data: optionsData, optionsStatusComponent} = useQueryFHG(OPTIONS_QUERY,
      {fetchPolicy: 'cache-first'});

   const [regionDelete, regionDeleteStatusComponent] = useMutationFHG(DELETE_REGION);
   const [regionCreate, regionCreateStatusComponent] = useMutationFHG(CREATE_REGION);
   const [propertyTypeCreate, createPropertyTypeStatusComponent] = useMutationFHG(PROPERTY_TYPE_CREATE);
   const [propertyTypeDelete, deletePropertyTypeStatusComponent] = useMutationFHG(PROPERTY_TYPE_DELETE);
   const [isSelectOpen, setIsSelectOpen] = useState(false);

   const [editValues, handleChange, {setEditValues, handleSelectChange, resetValues}] = useEditData(property, {
      id: property && property.id,
      city: get(property, 'city.name'),
   });

   const [isSaving, setIsSaving] = useState(false);

   useEffect(() => {
      if (open && isCreate) {
         resetValues();
      }
   }, [open, isCreate]);

   const handleBeforeSubmit = async () => {
      if (editValues.region) {
         const results = await regionCreate(
            {variables: {name: editValues.region}, refetchQueries: getOptionRefetchQueries});
         setEditValues({...editValues, regionId: get(results, 'data.region.id')});
         return results;
      }
   };
   /**
    * Submits the changes to the property.
    */
   const handleSubmit = (results) => {
      setIsSaving(true);
      const regionId = get(results, 'data.region.id');
      const propertyTypeId = editValues.propertyTypeId === null ? null : (isArray(editValues.propertyTypeId) ? editValues?.propertyTypeId?.[0]?.id : editValues?.propertyTypeId?.id);
      return {
         ...editValues,
         stateId: (editValues.state && editValues.state.value) || get(property, 'state.id'),
         regionId: regionId || editValues.regionId || get(property, 'region.id'),
         propertyTypeId,
         propertyTypeId_Edit: undefined,
         state_Edit: undefined,
      };
   };

   /**
    * On successful submit of property information.
    */
   const handleSuccess = result => {
      setIsSaving(false);
      history.push(`/property/${get(result, 'data.property.id')}`);
   };

   /**
    * On submit that has an error.
    */
   const handleError = result => {
      setIsSaving(false);
   };

   /**
    * Refetch the options after a mutation is executed that could have affected them.
    * @return {[{query: DocumentNode}]}
    */
   const getOptionRefetchQueries = () => {
      return [{query: OPTIONS_QUERY}];
   };

   /**
    * Refetch the properties after a mutation that could have affected them.
    * @return {[{query: DocumentNode}]}
    */
   const getRefetchQueries = () => {
      return [{query: PROPERTIES_QUERY}];
   };

   const handleDeleteRegion = (id) => {
      try {
         regionDelete({variables: {id}, refetchQueries: getOptionRefetchQueries});
      } catch (e) {

      }
   };

   const getRefetchPropertyTypeQueries = () => ([{query: OPTIONS_QUERY}]);

   /**
    * Delete the area selected.
    *
    * @param deleteOption The area to delete.
    */
   const handlePropertyTypeDelete = (deleteOption) => {
      const propertyTypeToDelete = castArray(deleteOption);
      const id = get(propertyTypeToDelete, '[0].id');

      propertyTypeDelete({variables: {id}, refetchQueries: getRefetchPropertyTypeQueries});

      setEditValues({...editValues, propertyTypeId: undefined});
   };

   /**
    * Handle the area select changes.
    *
    * @param event The select event.
    * @param name The name of the selected component.
    * @param isMulti Indicates if the select is a multi select.
    * @param options The option for the action.
    *
    * @return {Promise<void>} The promise for the select action.
    */
   const handleSelectPropertyChange = async (event, name, isMulti, options) => {
      let useEvent = event;
      if (options && options.action === 'create-option') {
         const variables = {name: useEvent.value};
         const result = await propertyTypeCreate({variables, refetchQueries:getRefetchPropertyTypeQueries});
         useEvent = result.data.propertyType;
      }

      handleSelectChange(useEvent, name || (options && options.name));
   };

   /**
    * Can the property type be deleted.
    *
    * @param propertyType The propertyType to check.
    * @param action The action to check
    * @return {*|boolean} Indicates if the action can be performed on the selected propertyType.
    */
   const handleCanPropertyTypeAction = (propertyType, action) => {
      return action === DELETE_ACTION && propertyType?.hasOwnProperty('id');
   };

   if (open) {
      let requiredState = editValues.state || get(property, 'state');
      if (requiredState === '' || requiredState === ' ') {
         requiredState = undefined;
      }
      const propertyTypeOptions = get(optionsData, 'propertyTypes') || [];
      return (
         <MutationEditDialog
            open={open}
            onClose={onClose}
            titleKey={isCreate ? 'propertyInfo.new.title' : 'propertyInfo.update.title'}
            submitKey={isCreate ? 'propertyInfo.new.label' : 'propertyInfo.update.label'}
            onBeforeSubmit={handleBeforeSubmit}
            onSubmit={!isSelectOpen && handleSubmit}
            onSuccess={handleSuccess}
            onError={handleError}
            createMutation={CREATE_PROPERTY}
            updateMutation={UPDATE_PROPERTY}
            isCreate={isCreate}
            refetchQueries={getRefetchQueries}
            mutationKey={'property'}
            maxWidth={'md'}
            fullWidth={true}
         >
            {optionsStatusComponent || regionCreateStatusComponent || regionDeleteStatusComponent ||
            createPropertyTypeStatusComponent || deletePropertyTypeStatusComponent}
            <Grid container spacing={2}>
               <Grid item xs={12} sm={6}>
                  <TextField
                     autoFocus
                     name='contactName'
                     label={'Property Manager'}
                     fullWidth
                     defaultValue={get(property, 'contactName')}
                     value={editValues.contactName}
                     onChange={handleChange}
                     margin='none'
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={12}>
                  <PhoneNumberField
                     name='phone'
                     label={'Phone'}
                     fullWidth
                     defaultValue={get(property, 'phone') || ''}
                     value={editValues.phone}
                     onChange={handleChange}
                     margin='none'
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     name='email'
                     type='email'
                     label={'Email'}
                     fullWidth
                     defaultValue={get(property, 'email')}
                     value={editValues.email}
                     onChange={handleChange}
                     margin='none'
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     name='name'
                     label={'Name'}
                     required
                     fullWidth
                     defaultValue={get(property, 'name')}
                     value={editValues.name}
                     onChange={handleChange}
                     margin='normal'
                     style={{marginBottom: 0}}
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     name='addressLineOne'
                     label={'Address'}
                     required
                     fullWidth
                     defaultValue={get(property, 'addressLineOne')}
                     value={editValues.addressLineOne}
                     onChange={handleChange}
                     margin='none'
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     name='addressLineTwo'
                     className={classes.marginRight}
                     label={'Address 2'}
                     fullWidth
                     defaultValue={get(property, 'addressLineTwo')}
                     value={editValues.addressLineTwo}
                     onChange={handleChange}
                     margin='none'
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <TextField
                     name='city'
                     label={'City'}
                     required
                     fullWidth
                     defaultValue={get(property, 'city.name')}
                     value={editValues.city}
                     onChange={handleChange}
                     margin='none'
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={6} sm={3}>
                  <ReactSelect
                     name={'state'}
                     label={<Typography display={'inline'} id={'propertyInfo.state.label'}/>}
                     options={get(optionsData, 'states')}
                     defaultValue={get(property, 'state')}
                     value={editValues.state}
                     onChange={handleSelectChange}
                     isMulti={false}
                     fullWidth
                     margin='none'
                     disabled={isSaving}
                     required
                  />
               </Grid>
               <Grid item xs={6} sm={3}>
                  <TextField
                     name='zipCode'
                     label={'Zip Code'}
                     inputProps={{
                        'data-type': 'number',
                        maxLength: 5,
                        pattern: '[0-9]{5}',
                        title: 'Five digit zip code'
                     }}
                     required
                     defaultValue={get(property, 'zipCode')}
                     value={editValues.zipCode}
                     onChange={handleChange}
                     fullWidth
                     margin='none'
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <ReactSelect
                     name={'propertyTypeId'}
                     label={<FHGTypography display={'inline'} id={'propertyEdit.type.label'}/>}
                     options={propertyTypeOptions}
                     defaultValue={ReactSelect.createObjectValue(property?.propertyTypeId, propertyTypeOptions)}
                     value={ReactSelect.createObjectValue(editValues.propertyTypeId, propertyTypeOptions)}
                     hideSelectedOptions
                     onMenuOpen={() => setIsSelectOpen(true)}
                     onMenuClose={() => setIsSelectOpen(false)}
                     isLoading={loading}
                     onChange={handleSelectPropertyChange}
                     isMulti={false}
                     isCreatable
                     isConfirmDeletable
                     onCanDoAction={handleCanPropertyTypeAction}
                     isClearable
                     onDelete={handlePropertyTypeDelete}
                     openMenuOnClick={false}
                     fullWidth
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                      name='companyName'
                      label={<FHGTypography display={'inline'} id={'propertyEdit.companyName.label'}/>}
                      fullWidth
                      defaultValue={get(property, 'companyName')}
                      value={editValues.companyName}
                      onChange={handleChange}
                      margin='none'
                      disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={12}>
                  <TextField
                     name='information'
                     label={'Information'}
                     fullWidth
                     defaultValue={get(property, 'information')}
                     value={editValues.information}
                     onChange={handleChange}
                     margin='none'
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={12} md={6}>
                  <TextField
                     name='schedule'
                     label={'Schedule'}
                     fullWidth
                     defaultValue={get(property, 'schedule')}
                     value={editValues.schedule}
                     onChange={handleChange}
                     margin='none'
                     disabled={isSaving}
                  />
               </Grid>
               <Grid item xs={12} md={6}>
                  <MenuWithAddDelete
                     name={'region'}
                     selectName={'regionId'}
                     selectLabelKey={'propertyInfo.select.label'}
                     addLabelKey={'propertyInfo.regionName.label'}
                     addButtonKey={'propertyInfo.add.label'}
                     options={get(optionsData, 'regions') || []}
                     defaultValue={get(property, 'region.id')}
                     selectValue={editValues.regionId}
                     value={editValues.region}
                     onSelectChange={handleSelectChange}
                     onEditChange={handleSelectChange}
                     disabled={isSaving}
                     fullWidth
                     onDelete={handleDeleteRegion}
                     deleteMessageKey={'propertyInfo.deleteRegion.confirm'}
                     deleteTitleKey={'propertyInfo.deleteRegion.title'}
                     buttonLabelKey={'propertyInfo.delete.button'}
                     isAutoSelectFirst={false}
                     isAddButton={false}
                     margin='none'
                     required
                  />
               </Grid>
            </Grid>
         </MutationEditDialog>
      );
   } else {
      return null;
   }
}
