import get from 'lodash/get';
import * as PropTypes from 'prop-types';
import React, {PureComponent} from 'react';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import DisplayError from '../../fhg/components/DisplayError';
import Grid from '../../fhg/components/Grid';
import Video from '../../fhg/components/Video';

class CameraDialog extends PureComponent {
   videoRef = React.createRef();
   static propTypes = {
      image: PropTypes.object,
      annotations: PropTypes.object,
      onClose: PropTypes.func,
      onSubmit: PropTypes.func,
      allowAnnotations: PropTypes.bool,
   };

   static defaultProps = {
      allowAnnotations: true,
   };

   constructor(props, context) {
      super(props, context);

      this.state = {
         isSaving: false,
         image: props.image,
         annotations: props.annotations,
         isChanged: false,
      };
   }

   handleSubmit = async () => {
      const {onSubmit, onClose} = this.props;

      if (this.state.isChanged) {
         this.setState({isSaving: true});
         const image = await this.videoRef.current.saveImage();
         if (image && this.props.image) {
            image.index = get(this.props, 'image.index');
         }
         try {
            onSubmit && onSubmit(image, !this.props.image, this.state.annotations);
            this.setState({isSaving: false, isChanged: false});
         } catch (error) {
            this.setState({isSaving: false, error, errorId: 'answer.savePhoto.error'});
         }
      } else {
         onClose && onClose();
      }
   };

   handleTakePhoto = (image) => {
      this.setState({image: {src: image}, annotations: undefined, isChanged: true});
   };

   handleUpdateAnnotation = () => {
      this.setState({isChanged: true});
   };

   render() {
      const {onClose, allowAnnotations} = this.props;
      const {open, isSaving = false, errorId, error, annotations, image} = this.state;

      return (
         <ModalDialog open={open} onClose={onClose} maxWidth={'xl'}  isForm={false} contentsScroll={false} fullWidth={true} fullScreen={true}
                      onSubmit={this.handleSubmit} isEnabled={!!image} useCaptureKeydown={false} submitLabel={'Save'} isSaving={isSaving}
         >
            <DisplayError error={error} errorId={errorId} enableRefresh={false}/>
            <Grid item style={{display: 'flex', minHeight: 100, minWidth: 100, height: 700}} fullWidth>
               <Video image={this.state.image && this.state.image.src }
                      ref={this.videoRef} allowAnnotations={allowAnnotations}
                      annotations={annotations} onAnnotationUpdate={this.handleUpdateAnnotation} onTakePhoto={this.handleTakePhoto}/>
            </Grid>
         </ModalDialog>
      );
   }
}

export default CameraDialog;
