import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {TextField} from '@material-ui/core';
import gql from 'graphql-tag';
import useMutationFHG from '../../../fhg/components/data/useMutationFHG';
import {useDebounce} from '../../../fhg/hooks/useDebounce';

const useStyles = makeStyles(theme => ({
  input: {
    '& input': {
      padding: '13px 14px'
    }
  }
}));

const GRID_EQUIPMENT_UPDATE = gql`
  mutation updateGridEquipment($id: Int!, $templateId: Int!, $name: String, $quantity: Int) {
    gridEquipment: gridEquipment_Update(gridEquipmentId: $id, gridEquipment: {templateId: $templateId, name: $name, quantity: $quantity}) {
      id
      name
      quantity
      type
    }
  }
`;

/**
 * Component to display a text cell for an equipment field.
 */
export default function EquipmentTextField(props) {
  const classes = useStyles();
  const [value, setValue] = useState(props.equipment[props.field] ?? '');
  const [gridEquipmentUpdate] = useMutationFHG(GRID_EQUIPMENT_UPDATE);
  const debounceUpdate = useDebounce(500);

  /**
   * Updates the grid equipment field.
   */
  async function updateGridEquipment(newValue) {
    try {
      await gridEquipmentUpdate({
        variables: {
          id: props.equipment.id,
          templateId: props.report.template.id,
          [props.field]: newValue
        }
      });
    } catch (error) {
      console.log('Error updating gridEquipment', error);
    }
  }

  /**
   * Updates the value and queues up an API update.
   *
   * @param {Object} event onChange event.
   */
  function updateValue(event) {
    let displayValue = event.target.value;
    let updateValue = displayValue;

    if (props.isNumber) {
      displayValue = parseInt(displayValue, 10);
      if (isNaN(displayValue)) {
        displayValue = '';
        updateValue = null;
      } else {
        if (displayValue < 0) {
          displayValue = 0;
        }
        updateValue = displayValue;
      }
    }

    setValue(displayValue);
    debounceUpdate(() => updateGridEquipment(updateValue));
  }

  return props.isEditable ? (
      <TextField
          variant="outlined"
          onChange={updateValue}
          value={value}
          className={classes.input}
          fullWidth
      />
  ) : (
      <div>{value || ''}</div>
  );
}

EquipmentTextField.propTypes = {
  report: PropTypes.object.isRequired,
  equipment: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  isNumber: PropTypes.bool,
  isEditable: PropTypes.bool.isRequired
};
