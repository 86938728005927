import {withStyles} from '@material-ui/core';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';

export const ExpansionPanel = withStyles(theme => ({
   root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
         borderBottom: 0,
      },
      '&:before': {
         display: 'none',
      },
      '&$expanded': {
         margin: 'auto',
      },
      '& svg': {
         fill: theme.palette.type === 'dark' ? '#fbaa3e' : '#ef7f22',
      }
   },
   expanded: {},
}))(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles(theme => ({
   root: {
      // backgroundColor: '#fafafa',
      backgroundColor: theme.palette.type === 'dark' ? '#9B9B9B' : '#E7E7E7',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
         minHeight: 56,
      },
   },
   content: {
      '&$expanded': {
         margin: '12px 0',
      },
   },
   expanded: {},
}))(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
   root: {
      padding: theme.spacing(0, 2, 2),
   },
}))(MuiExpansionPanelDetails);
