import useTheme from '@material-ui/core/styles/useTheme';
import gql from 'graphql-tag';
import { find } from 'lodash';
import get from 'lodash/get';
import { parse } from 'query-string';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MenuWithAddDelete from '../../components/MenuWithAddDelete';
import TextField from '../../components/TextField';
import CheckboxFHG from '../../fhg/components/CheckboxFHG';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import useEditData from '../../fhg/components/edit/useEditData';
import { MASTER_EQUIPMENT_INVENTORY_QUERY } from '../inventory/InventoryItemEdit';
import { FILTER_QUESTIONS_QUERY } from './Area';
import {useLazyQuery} from '@apollo/client';

const MANUFACTURER_QUERY = gql`
  query getManufacturers {
    manufacturers: manufacturer_All {
      id
      value: id
      isDeleted
      name
      label: name
    }
  }
`;
const MODEL_BY_MANUFACTURER_QUERY = gql`
  query getModelByManufacturer($manufacturerId: [Int]) {
    models: model_AllWhere(modelSearch: { manufacturerId: $manufacturerId }) {
      id
      value: id
      isDeleted
      modelNumber
      label: modelNumber
    }
  }
`;

export const EQUIPMENT_CREATE = gql`
  mutation EquipmentCreateForEquipmentEdit($name: String!, $areaId: Int!, $modelId: Int, $manufacturerId: Int, 
     $isEntity: Boolean) {
    equipment: equipment_Create(
      equipment: {
        name: $name,
        areaId: $areaId,
        manufacturerId: $manufacturerId,
        modelId: $modelId,
        isEntity: $isEntity
      }
    ) {
      id
      value: id
      isDeleted
      name
      label: name
      manufacturerId
      modelId
      isEntity
    }
  }
`;

export const EQUIPMENT_UPDATE = gql`
  mutation EquipmentUpdateForEquipmentEdit($id: Int!, $name: String, $areaId: Int, $modelId: Int, $manufacturerId: Int, 
     $isEntity: Boolean) {
    equipment: equipment_Update(equipmentId: $id, equipment: {
        name: $name,
        areaId: $areaId,
        manufacturerId: $manufacturerId,
        modelId: $modelId,
        isEntity: $isEntity}
    ) {
      id
      value: id
      isDeleted
      name
      label: name
      manufacturerId
      modelId
      isEntity
    }
  }
`;

export const EQUIPMENT_DELETE = gql`
  mutation EquipmentDeleteForEdit($id: Int!) {
    equipment_Delete(equipmentId: $id)
  }
`;

export const MANUFACTURER_CREATE = gql`
  mutation ManufacturerCreate($name: String!) {
    manufacturer: manufacturer_Create(manufacturer: { name: $name }) {
      id
      value: id
      isDeleted
      name
      label: name
    }
  }
`;

export const MANUFACTURER_DELETE = gql`
  mutation ManufacturerDelete($id: Int!) {
    manufacturer_Delete(manufacturerId: $id)
  }
`;

export const MODEL_CREATE = gql`
  mutation ModelCreate($modelNumber: String!, $manufacturerId: Int!) {
    model: model_Create(
      model: { modelNumber: $modelNumber, manufacturerId: $manufacturerId }
    ) {
      id
      value: id
      isDeleted
      modelNumber
      label: modelNumber
    }
  }
`;

export const MODEL_DELETE = gql`
  mutation ModelDelete($id: Int!) {
    model_Delete(modelId: $id)
  }
`;

/**
 * The dialog to create and edit an Equipment.
 *
 * Reviewed: 6/22/20
 */
export default function EquipmentEdit({
  onClose,
  open,
  area,
  equipment,
  onSuccess,
  skipRefetch = false
}) {
  const location = useLocation();
  const theme = useTheme();

  const { loading, data, statusComponent } = useQueryFHG(MANUFACTURER_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
  const [loadModelByManufacturer, { data: modelData }] = useLazyQuery(
    MODEL_BY_MANUFACTURER_QUERY
  );
  const [equipmentUpdate, equipmentUpdateStatusComponent] =
    useMutationFHG(EQUIPMENT_UPDATE);
  const [equipmentCreate, equipmentCreateStatusComponent] =
    useMutationFHG(EQUIPMENT_CREATE);
  const [equipmentDelete, equipmentDeleteStatusComponent] =
    useMutationFHG(EQUIPMENT_DELETE);
  const [manufacturerCreate, manufacturerCreateStatusComponent] =
    useMutationFHG(MANUFACTURER_CREATE);
  const [manufacturerDelete, manufacturerDeleteStatusComponent] =
    useMutationFHG(MANUFACTURER_DELETE);
  const [modelCreate, modelCreateStatusComponent] =
    useMutationFHG(MODEL_CREATE);
  const [modelDelete, modelDeleteStatusComponent] =
    useMutationFHG(MODEL_DELETE);

  const [isSaving, setIsSaving] = useState(false);
  const [
    editValues,
    handleChange,
    { setEditValues, defaultValues, setDefaultValues, handleSelectChange }
  ] = useEditData();

  const [editFocus, setEditFocus] = useState(false);

  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);

  useEffect(() => {
    if (equipment) {
      setDefaultValues(equipment);
      setEditValues({
        equipmentName: equipment.name,
        equipmentId: equipment.id
      });
      if (equipment.manufacturerId) {
        loadModelByManufacturer({
          variables: { manufacturerId: equipment.manufacturerId },
        });
      }
    }
  }, [equipment]);

  useEffect(() => {
    if (!!data) {
      setManufacturers(get(data, 'manufacturers'));
    }
  }, [data]);

  useEffect(() => {
    if (!!modelData) {
      setModels(get(modelData, 'models'));
    }
  }, [modelData]);

  const handleManufacturerChange = (value, name) => {
    if (!!name) {
      setEditValues({ ...editValues, [name]: value, modelId: null });
      if (value) {
        loadModelByManufacturer({ variables: { manufacturerId: value } });
      } else {
        setModels([]);
      }
    }
  };

  const handleEquipmentChange = (value, name) => {
    if (!!name) {
      setEditFocus(false);
      const equipment = find(area.equipment, { id: value });
      if (equipment) {
        setDefaultValues(equipment);
        setEditValues({});
        if (equipment.manufacturerId) {
          loadModelByManufacturer({
            variables: { manufacturerId: equipment.manufacturerId }
          });
        }
        handleSelectChange(value, name);
      } else {
        console.log('Equipment ID could not be found', value);
      }
    }
  };

  const getEquipmentRefetchQueries = () => {
    const filters = parse(location.search, {
      parseNumbers: true,
      parseBooleans: true
    });
    const variables = { id: area.id, area: area.name, ...filters };

    return [
      { query: FILTER_QUESTIONS_QUERY, variables },
      { query: MASTER_EQUIPMENT_INVENTORY_QUERY },
    ];
  };

  const getRefetchQueries = () => {
    return [{ query: MANUFACTURER_QUERY }];
  };

  const getModelRefetchQueries = () => {
    return [{query: MODEL_BY_MANUFACTURER_QUERY, variables: { manufacturerId: editValues.manufacturerId }}];
  };

  const handleCreateEquipment = async () => {
    let manufacturerId;
    let modelId;

    setIsSaving(true);

    if (editValues.manufacturer) {
      const resultManufacturer = await manufacturerCreate({
        variables: { name: editValues.manufacturer },
        refetchQueries: getRefetchQueries
      });
      manufacturerId = get(resultManufacturer, 'data.manufacturer.id');
    } else {
      manufacturerId = editValues.manufacturerId;
    }
    if (editValues.model) {
      const resultModel = await modelCreate({
        variables: {
          modelNumber: editValues.model,
          manufacturerId: manufacturerId
        },
        refetchQueries: getModelRefetchQueries
      });
      modelId = get(resultModel, 'data.model.id');
    } else {
      modelId = editValues.modelId;
    }
    if (editValues.equipmentId) {
      const result = await equipmentUpdate({
        variables: {
          id: editValues.equipmentId,
          name: editValues.equipmentName,
          areaId: area?.id,
          modelId,
          manufacturerId,
          isEntity: editValues.isEntity,
        },
        refetchQueries: skipRefetch ? () => {} : getEquipmentRefetchQueries
      });
      onSuccess && onSuccess(result.data);
    } else {
      const result = await equipmentCreate({
        variables: {
          name: editValues.equipmentName,
          areaId: area.id,
          manufacturerId,
          modelId,
          isEntity: editValues.isEntity
        },
        refetchQueries: getEquipmentRefetchQueries
      });
      onSuccess && onSuccess(result.data);
    }
    setIsSaving(false);
    onClose && onClose();
  };

  const handleCreateManufacturer = async (value, name) => {
    const result = await manufacturerCreate({
      variables: { name: value },
      refetchQueries: getRefetchQueries
    });
    setEditValues({
      ...editValues,
      [name]: get(result, 'data.manufacturer.id')
    });
  };

  const handleCreateModel = async (value) => {
    if (value && editValues.manufacturerId) {
      const result = await modelCreate({
        variables: {
          modelNumber: value,
          manufacturerId: editValues.manufacturerId
        },
        refetchQueries: getModelRefetchQueries
      });
      setEditValues({
        ...editValues,
        model: undefined,
        modelId: get(result, 'data.model.id')
      });
    }
  };

  const handleDeleteEquipment = (id) => {
    equipmentDelete({
      variables: { id },
      refetchQueries: getEquipmentRefetchQueries
    });
  };

  const handleDeleteManufacturer = (id) => {
    manufacturerDelete({
      variables: { id },
      refetchQueries: getRefetchQueries
    });
  };

  const handleDeleteModel = (id) => {
    modelDelete({ variables: { id }, refetchQueries: getModelRefetchQueries });
  };

  const handleCheckboxChange = (event) => {
    setEditValues({ ...editValues, [event.target.name]: event.target.checked });
  };

  const handleAddEquipment = () => {
    setDefaultValues({});
    setEditValues({
      ...editValues,
      equipment: '',
      equipmentId: '',
      equipmentName: undefined,
      manufacturerId: undefined,
      manufacturer: undefined,
      modelId: undefined,
      model: undefined
    });
    setTimeout(() => {
      setEditFocus(true);
    }, 20);
  };

  return (
    <ModalDialog
      titleKey={'equipment.manage.title'}
      open={open}
      submitKey={'equipment.save.label'}
      onClose={onClose}
      onSubmit={handleCreateEquipment}
      isForm
      maxWidth={'sm'}
      fullWidth
    >
      {statusComponent ||
        equipmentCreateStatusComponent ||
        equipmentUpdateStatusComponent ||
        equipmentDeleteStatusComponent ||
        manufacturerCreateStatusComponent ||
        manufacturerDeleteStatusComponent ||
        modelCreateStatusComponent ||
        modelDeleteStatusComponent}
      {!equipment && (
        <MenuWithAddDelete
          key={'MenuWithAddDelete' + editValues.equipmentId}
          name={'equipment'}
          selectName={'equipmentId'}
          selectLabelKey={'equipment.select.label'}
          addLabelKey={'equipment.name.label'}
          addButtonKey={'equipment.add.label'}
          options={get(area, 'equipment') || []}
          selectValue={editValues.equipmentId}
          value={editValues.equipment}
          onSelectChange={handleEquipmentChange}
          onEditChange={handleSelectChange}
          // onCreateChange={handleCreateEquipment}
          disabled={isSaving || loading}
          fullWidth
          onDelete={handleDeleteEquipment}
          deleteMessageKey={'equipment.deleteEquipment.confirm'}
          deleteTitleKey={'equipment.deleteEquipment.title'}
          buttonLabelKey={'equipment.delete.button'}
          onAdd={handleAddEquipment}
          isAutoSelectFirst={false}
          isAddButton={true}
          autoFocus={!editFocus}
        />
      )}
      <TextField
        key={'equipmentName' + defaultValues.label + editFocus}
        name={'equipmentName'}
        labelKey={'equipment.name.label'}
        defaultValue={defaultValues.label}
        value={editValues.equipmentName}
        onChange={handleChange}
        disabled={isSaving || loading}
        fullWidth
        autoFocus={editFocus}
        required
      />
      <MenuWithAddDelete
        key={'manufacturer' + get(area, 'equipment.manufacturerId')}
        name={'manufacturer'}
        selectName={'manufacturerId'}
        selectLabelKey={'equipment.manufacturer.label'}
        addLabelKey={'equipment.nameManufacturer.label'}
        addButtonKey={'equipment.addManufacturer.label'}
        options={manufacturers}
        defaultValue={defaultValues.manufacturerId}
        selectValue={editValues.manufacturerId}
        value={editValues.manufacturer}
        onSelectChange={handleManufacturerChange}
        onEditChange={handleSelectChange}
        onCreateChange={handleCreateManufacturer}
        disabled={isSaving || loading}
        fullWidth
        required={!equipment}
        onDelete={handleDeleteManufacturer}
        deleteMessageKey={'equipment.deleteManufacturer.confirm'}
        deleteTitleKey={'equipment.deleteManufacturer.title'}
      />
      <MenuWithAddDelete
        key={'model' + get(area, 'equipment.modelId')}
        name={'model'}
        selectName={'modelId'}
        selectLabelKey={'equipment.model.label'}
        addLabelKey={'equipment.nameModel.label'}
        addButtonKey={'equipment.addModel.label'}
        options={models}
        defaultValue={defaultValues.modelId}
        selectValue={editValues.modelId}
        value={editValues.model}
        onSelectChange={handleSelectChange}
        onEditChange={handleSelectChange}
        onCreateChange={handleCreateModel}
        disabled={
          isSaving ||
          loading ||
          (!editValues.manufacturerId &&
            !editValues.manufacturer &&
            !defaultValues.manufacturerId)
        }
        fullWidth
        required={!equipment}
        onDelete={handleDeleteModel}
        deleteMessageKey={'equipment.deleteModel.confirm'}
        deleteTitleKey={'equipment.deleteModel.title'}
      />
      {equipment && (
        <CheckboxFHG
          key={'isEntity'}
          style={{ marginLeft: theme.spacing(1) }}
          name={'isEntity'}
          onChange={handleChange}
          color={'default'}
          labelKey={'equipment.inventory.label'}
          value={'isEntity'}
          defaultChecked={defaultValues.isEntity}
          checked={editValues.isEntity}
          disabled={isSaving}
        />
      )}
    </ModalDialog>
  );
}
