import {lighten} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {useState, useEffect} from 'react';
import ModalDialog from '../dialog/ModalDialog';
import {loadingAndError} from './DataUtils';
import {useMutation} from '@apollo/client';

const useStyles = makeStyles(theme => ({
   deleteColorStyle: {
      color: theme.palette.error.main,
      '&:hover': {
         backgroundColor: lighten(theme.palette.error.light, 0.8),
      }
   },
}));

export default function useDeleteMutationFHG(mutation, options, messageOptions) {
   let onSuccess, onError, onCancel;
   let deleteOptions;
   const [statusComponent, setStatusComponent] = useState(null);
   const [confirmComponent, setConfirmComponent] = useState(null);
   const [isConfirmed, setIsConfirmed] = useState(false);
   const classes = useStyles();

   const [mutationFunction, {loading, error, data}] = useMutation(mutation, options);

   const handleCancel = event => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      setIsConfirmed(false);
      setConfirmComponent(null);
      onCancel && onCancel();
   };

   const submitDelete = async event => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      setIsConfirmed(true);
      setConfirmComponent(null);
      try {
         const result = await mutationFunction(deleteOptions);
         onSuccess && onSuccess(result);
      } catch (error) {
         onError && onError(error);
      }
   };

   const deleteMutationFunction = (options, successCallback, errorCallback, cancelCallback) => {
      deleteOptions = options;
      onSuccess = successCallback;
      onError = errorCallback;
      onCancel = cancelCallback;
      const newConfirmComponent = (
         <ModalDialog titleKey={messageOptions.titleKey} messageKey={messageOptions.confirmKey} onClose={handleCancel}
                   onSubmit={submitDelete} values={messageOptions.confirmValues} maxWidth={'xs'} fullWidth={true}
                   submitKey={'delete.button'} submitColorStyle={classes.deleteColorStyle}/>
      );
      setConfirmComponent(newConfirmComponent);
      setIsConfirmed(false);
   };

   useEffect(() => {
      if (isConfirmed) {
         setStatusComponent(!data ? loadingAndError(loading, error) : null);
         if (error) {
            console.log('useMutation error', error);
         }
      }
   }, [data, loading, error, isConfirmed]);

   return [deleteMutationFunction, statusComponent, confirmComponent, {loading, error, data}];
}