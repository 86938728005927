import Grid from '@material-ui/core/Grid';
import {withStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import { Box } from '@material-ui/core';
import gql from 'graphql-tag';
import get from 'lodash/get';
import React from 'react';
import {withRouter} from 'react-router-dom';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Typography from '../../fhg/components/Typography';
import {PROPERTY_FRAGMENT} from '../Types';
import PropertyInfo from './PropertyInfo';

const styles = theme => ({
   contentStyle: {
      overflow: 'auto',
      height: '100%',

      flex: '1 1',
      // padding: theme.spacing(3),
   },
   messageStyle: {
      padding: theme.spacing(3),
   },
});


export const PROPERTY_QUERY = gql`
   query getProperty($id: Int!)
   {
      property:property_ById(propertyId: $id) {
         ...propertyInfo
         userIdList
         mapImageData {
            id:imageHash
            imageS3
         }
         inspectionReports {
            id
            isCompleted
            description
            isDeleted
            name
            createdDateTime
         }
      }
   }
   ${PROPERTY_FRAGMENT}
`;

/**
 * Drawer to navigate to reports and other screens.
 */
function Property({classes, match}) {
   const id = Number(get(match, 'params.id', 0));

   const {data, statusComponent} = useQueryFHG(PROPERTY_QUERY, {variables: {id}, fetchPolicy: 'cache-first'});

   const property = get(data, 'property');
   const userIdList = get(property, 'userIdList');

   return (
      <Grid item className={classes.contentStyle}>
         {statusComponent}
         {property ? (
            <PropertyInfo property={property} userIdList={userIdList}/>
         ) : (
            <Box mb={2} display="flex" alignItems="center">
               <Typography className={classes.messageStyle} id='property.noSelection.instructions' variant={'h6'} color={'inherit'}/>
               {/* <MenuIcon /> */}
            </Box>
         )}
      </Grid>
   );
}

export default withRouter(withStyles(styles)(Property));
