import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import { debounce } from 'lodash';
import get from 'lodash/get';
import { useCallback } from 'react';
import React, { useState, useEffect, useRef } from 'react';
import TextField from '../../components/TextField';
import { SUBMIT_ANSWER } from '../../Constants';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import useEditData from '../../fhg/components/edit/useEditData';
import Form from '../../fhg/components/Form';
import Grid from '../../fhg/components/Grid';
import ReactSelect from '../../fhg/components/ReactSelect';
import Typography from '../../fhg/components/Typography';
import ValidateTarget from '../../fhg/components/ValidateTarget';
import { cacheAdd } from '../../fhg/utils/DataUtil';
import { DownIcon } from '../../Icons';
import { getPerimeterConcernsCacheQueries } from '../builder/PerimeterConcernsBuilder';
import {
  PERIMETER_CONCERN_UPDATE,
  PERIMETER_CONCERN_CREATE
} from '../builder/PerimeterConcernsBuilder';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(0, 1, 0, 1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0.5, 0, 1),
    },
    marginTop: theme.spacing(0.5),
  },
  typographyStyle: {
    maxWidth: 200,
    padding: theme.spacing(2),
  },
  reactSelectStyle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& .MuiOutlinedInput-input': {
      height: 'fit-content',
      padding: '10px 4px',
    },
  },
  reactSelectStyle2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

const PERIMETER_CONCERN_OPTIONS_QUERY = gql`
  query PerimeterConcernOptionsQuery {
    priorities: priority_All {
      id
      isDeleted
      name
      value: id
      label: name
      description
    }
  }
`;

/**
 * Component to edit summary.
 *
 * Reviewed:
 */
export default function PerimeterConcernEdit({
  report,
  perimeterConcern,
  perimeterConcernTypes,
  isEditable
}) {
  const classes = useStyles();
  const [isSaving, setIsSaving] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [openId, setOpenId] = React.useState();

  const [
    editValues,
    handleChange,
    {
      isChanged,
      setIsChanged,
      defaultValues,
      setDefaultValues,
      handleSelectChange
    }
  ] = useEditData({ reportId: report?.id, ...perimeterConcern }, [
    'id',
    'reportId'
  ]);
  const [perimeterConcernCreate, perimeterConcernCreateStatusComponent] =
    useMutationFHG(
      PERIMETER_CONCERN_CREATE,
      undefined,
      'perimeterConcernCreate'
    );
  const [perimeterConcernUpdate, perimeterConcernUpdateStatusComponent] =
    useMutationFHG(
      PERIMETER_CONCERN_UPDATE,
      undefined,
      'perimeterConcernUpdate'
    );

  const { data: optionsData, optionsStatusComponent } = useQueryFHG(
    PERIMETER_CONCERN_OPTIONS_QUERY,
    { fetchPolicy: 'cache-first' }
  );

  /**
   * Submit the changes to the server.
   * @param perimeterConcern The perimeterConcern being changed.
   * @param values The edited values.
   * @param priority The edited priority.
   * @return {Promise<void>}
   */
  const submitChange = useCallback(
    async (editValues) => {
      setIsChanged(false);
      try {
        setIsSaving(true);
        const variables = {
          ...editValues,
          perimeterConcernTypeId: editValues?.perimeterConcernTypeId?.id,
          perimeterConcernTypeId_Edit: undefined
        };

        if (editValues.id) {
          await perimeterConcernUpdate({ variables });
        } else {
          const result = await perimeterConcernCreate({
            variables,
            update: cacheAdd(
              getPerimeterConcernsCacheQueries(report?.id),
              'perimeterConcern'
            ),
          });
          setDefaultValues({ ...(result?.perimeterConcern || {}) });
        }
      } finally {
        setIsSaving(false);
      }
    },
    [isChanged, setIsSaving]
  );

  // Debounce the submit changes call so that changes to text fields don't cause continual submits.
  const submitChangeDelayed = useRef(
    debounce(submitChange, SUBMIT_ANSWER)
  ).current;

  useEffect(() => {
    return submitChangeDelayed.flush;
  }, []);

  useEffect(() => {
    if (isChanged && editValues) {
      submitChangeDelayed(editValues);
    }
  }, [editValues]);

  const handleClick = id => event => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpenId(id);
  };

  const maybePrevent = event => {
    if (openId) {
      event.preventDefault();
      event.stopPropagation();
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenId(undefined);
  };

  return (
    <Form style={{ width: '100%' }} submit={() => submitChangeDelayed.flush()}>
      {perimeterConcernUpdateStatusComponent ||
        optionsStatusComponent ||
        perimeterConcernCreateStatusComponent}
      <Grid
        name={'Perimeter Concern Edit'}
        container
        fullWidth
        className={classes.root}
        direction={'row'}
        spacing={1}
      >
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ReactSelect
            key={
              'perimeterConcernTypeId' + defaultValues.perimeterConcernTypeId
            }
            name={'perimeterConcernTypeId'}
            className={classes.reactSelectStyle}
            style={{ minWidth: 150, zIndex: 1000 }}
            label={
              <Typography
                display={'inline'}
                id={'perimeterConcern.type.label'}
              />
            }
            options={perimeterConcernTypes}
            defaultValue={defaultValues?.perimeterConcernTypeId}
            value={editValues.perimeterConcernTypeId}
            onChange={handleSelectChange}
            isMulti={false}
            disabled={isSaving || !isEditable}
            fullWidth
            autoFocus
            isCreatable
            required
          />
          <ValidateTarget
            name={'ValidatePerimeterConcernTypeId'}
            top={-1}
            value={
              editValues.perimeterConcernTypeId ||
              defaultValues?.perimeterConcernTypeId
            }
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            key={'distance' + defaultValues.distance}
            name='distance'
            defaultValue={defaultValues?.distance}
            label={<Typography id={'perimeterConcern.distance.label'} />}
            fullWidth
            value={editValues.distance}
            onChange={handleChange}
            onBlur={() => submitChangeDelayed.flush()}
            disabled={isSaving || !isEditable}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            name={'priorityId'}
            label={
              <Typography
                display={'inline'}
                id={'questionDetail.priority.label'}
              />
            }
            variant={'outlined'}
            className={classes.reactSelectStyle2}
            select
            SelectProps={{ IconComponent: DownIcon }}
            defaultValue={defaultValues?.priorityId}
            value={editValues.priorityId}
            onChange={event =>
              handleSelectChange(event?.target?.value, event?.target?.name)
            }
            margin='none'
            fullWidth
            required
            inputProps={{ required: true }}
            disabled={isSaving || !isEditable}
            onBlur={() => submitChangeDelayed.flush()}
          >
            {get(optionsData, 'priorities', []).map(priority => (
              <MenuItem
                key={'priority' + priority.id}
                value={priority.id}
                onClickCapture={maybePrevent}
              >
                <Grid
                  container
                  direction={'row'}
                  alignItems={'center'}
                  justify={'space-between'}
                >
                  {`${priority.label}`}
                  <Button
                    color='secondary'
                    size='small'
                    style={{
                      height: 19,
                      paddingTop: 0,
                      paddingBottom: 0,
                      marginRight: 8
                    }}
                    onClick={handleClick(priority.id)}
                  >
                    <Typography>More Info</Typography>
                  </Button>
                  <Popover
                    key={'popover' + priority.id}
                    open={openId === priority.id}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    PaperProps={{
                      className: classes.typographyStyle
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Typography className={classes.typographyStyle}>
                      {priority.description}
                    </Typography>
                  </Popover>
                </Grid>
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={3}>
          <TextField
            key={'comments' + defaultValues.comments}
            name='comments'
            label={<Typography id={'perimeterConcern.comment.label'} />}
            fullWidth
            defaultValue={defaultValues?.comments}
            value={editValues.comments}
            onChange={handleChange}
            onBlur={() => submitChangeDelayed.flush()}
            disabled={isSaving || !isEditable}
          />
        </Grid>
      </Grid>
    </Form>
  );
}
