import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import {debounce, get} from 'lodash';
import React, {useState, useEffect, Fragment, useRef} from 'react';
import TextField from '../../components/TextField';
import {SUBMIT_ANSWER, BUCKET_NAME} from '../../Constants';
import useDeleteMutationFHG from '../../fhg/components/data/useDeleteMutationFHG';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import Form from '../../fhg/components/Form';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import {REPORT_FRAGMENT} from './InspectionChecklist';
import ThumbnailList from './ThumbnailList';
import {Storage} from 'aws-amplify';

const useStyles = makeStyles(theme => ({
   root: {
      padding: theme.spacing(0, 2, 0, 5),
      [theme.breakpoints.down('xs')]: {
         padding: theme.spacing(0, 0.5, 0, 1),
      },
   },
}));

export const REPORT_UPDATE = gql`
   mutation UpdateReportForConclusion($id: Int!, $conclusion: String, $imageS3Data: [ImageS3Data]) {
      report:report_Update(reportId: $id, report:{conclusion: $conclusion, imageS3Data: $imageS3Data}) {
         ...reportInfoForChecklist
      }
   }
   ${REPORT_FRAGMENT}
`;

export const CONCLUSION_REPLACE_IMAGE = gql`
   mutation ConclusionReplaceImage($id: Int!, $index: Int!, $imageBucket: String!) {
      report:report_ReplaceImage(reportId: $id, index: $index, imageBucket: $imageBucket) {
         ...reportInfoForChecklist
      }
   }
   ${REPORT_FRAGMENT}
`;

export const CONCLUSION_DELETE_IMAGE = gql`
   mutation ConclusionDeleteImage($id: Int!, $index: Int!) {
      report:report_DeleteImage(reportId: $id, index: $index) {
         ...reportInfoForChecklist
      }
   }
   ${REPORT_FRAGMENT}
`;

/**
 * Component to edit conclusion.
 *
 * Reviewed: 3/3/2020
 */
export default function Conclusion({report, isEditable}) {
   const classes = useStyles();

   const [photos, setPhotos] = useState([]);
   const [conclusion, setConclusion] = useState('');
   const [conclusionUpdate, conclusionUpdateStatusComponent] = useMutationFHG(REPORT_UPDATE, undefined,
      'conclusionUpdate');
   const [conclusionReplaceImage, conclusionReplaceImageStatusComponent] = useMutationFHG(
      CONCLUSION_REPLACE_IMAGE, undefined, 'replaceImage');
   const [conclusionDeleteImage, deleteImageStatusComponent, deleteImageConfirmComponent] = useDeleteMutationFHG(
      CONCLUSION_DELETE_IMAGE, undefined,
      {titleKey: 'answer.delete.title', confirmKey: 'answer.deletePhoto.confirmation'});

   // Set the values from the report.
   useEffect(() => {
      if (!!report) {
         setConclusion(report.conclusion || undefined);
         updatePhotos(report);
      }
      // eslint-disable-next-line
   }, [report]);

   // Update the photo array from the new answer imageData.
   const updatePhotos = (report) => {
      const newPhotos = [];
      const imageData = get(report, 'imageData', []);
      if (imageData && imageData.length > 0) {
         for (const [i, image] of imageData.entries()) {
            newPhotos.push({imageS3: image.imageS3, src: image.imageS3, index: i});
         }
      }
      setPhotos(newPhotos);
   };

   /**
    * Submit the changes to the server.
    * @param report The report being changed.
    * @param conclusion The edited conclusion.
    * @return {Promise<void>}
    */
   const submitChange = async (conclusion) => {
      await conclusionUpdate({
         variables: {
            id: report.id,
            conclusion,
         }
      });
   };

   /**
    * Upload the new and updated photos to the S3 bucket. Set the S3 photos in the answer.
    *
    * @param photo The photo to upload.
    * @param isNew Indicates if the photo is new.
    * @return {Promise} The promise for the uploaded photo.
    */
   const uploadPhoto = async (photo, isNew) => {
      if (photo.blob) {
         const imagePath = `upload/Photo_${Date.now()}.jpg`;
         try {
            await Storage.put(imagePath, photo.blob,
               {level: 'public', contentType: get(photo, 'blob.type', 'image/jpeg')});
            photo.imageS3 = `${BUCKET_NAME}/${imagePath}`;

            if (!isNew) {
               conclusionReplaceImage({
                  variables: {
                     id: report.id,
                     index: photo.index,
                     imageBucket: photo.imageS3,
                  }
               }).then(result => {
                  handlePhotoSuccess(result);
               });
            } else {
               conclusionUpdate({
                  variables: {
                     id: report.id,
                     imageS3Data: [{imageBucket: photo.imageS3}],
                  }
               }).then(result => {
                  handlePhotoSuccess(result);
               });
            }
         } catch (error) {
            console.log('Error uploading file', error);
         }
      } else {
         console.log('Error uploading file', 'No image to upload');
      }
   };

   // Debounce the submit changes call so that changes to text fields don't cause continual submits.
   const submitChangeDelayed = useRef(debounce(submitChange, SUBMIT_ANSWER)).current;

   useEffect(() => {
      return submitChangeDelayed.flush;
   }, []);

   /**
    * Handle changes to the edited values.
    * @param event The event that triggered the change.
    */
   const handleChange = event => {
      setConclusion(event.target.value);
      submitChangeDelayed(event.target.value);
   };

   /**
    * Handle changing the photo. Handles new or existing.
    * @param photo The new or replaced photo.
    * @param isNew Indicates if the photo is new or existing.
    */
   const handleChangePhoto = (photo, isNew) => {
      uploadPhoto(photo, isNew);

      if (isNew) {
         setPhotos([...photos, photo]);
      } else {
         setPhotos([...photos]);
      }
   };

   /**
    * Handle the actual deleting of the photo after successful delete from the server.
    *
    * @param result The result of the server delete.
    */
   const handlePhotoSuccess = (result) => {
      updatePhotos(result.data.answer);
   };

   /**
    * Handle the delete photo. Deletes from the server.
    * @param photo The photo to be deleted.
    */
   const handleDeletePhoto = photo => {
      conclusionDeleteImage({
         variables: {
            id: report.id,
            index: photo.index,
         }
      }, handlePhotoSuccess);
   };

   return (
      <Fragment>
         {deleteImageConfirmComponent}
         {conclusionUpdateStatusComponent || deleteImageStatusComponent || conclusionReplaceImageStatusComponent}
         <Form style={{width: '100%'}} submit={() => submitChangeDelayed.flush()}>
            <Grid name={'Conclusion Edit'} container fullWidth className={classes.root} direction={'column'}>
               <Grid name={'Conclusion Grid'} item xs={12} fullWidth>
                  <TextField
                     name='conclusion'
                     label={<Typography id={'conclusion.notes.label'}/>}
                     fullWidth
                     value={conclusion}
                     onChange={handleChange}
                     onBlur={() => submitChangeDelayed.flush()}
                     multiline
                     disabled={!isEditable}
                     rows={3}
                  />
               </Grid>
               <Grid name={'bottom row'} item resizable={false} fullWidth>
                  <ThumbnailList defaultImages={photos} onChange={handleChangePhoto} onDelete={handleDeletePhoto} isEditable={isEditable}/>
               </Grid>
            </Grid>
         </Form>
      </Fragment>
   );
}