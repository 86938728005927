/**
 * Creates a never changing function that will always execute the latest version of the callback.
 * Useful for callbacks in setInterval and event listeners where stale closures are an issue.
 *
 * @returns {Function} Function that always calls the latest version of the callback. Guaranteed to never change.
 */
import {useCallback, useEffect, useRef} from 'react';

export function useFunctionRef(callback) {
  const functionRef = useRef(callback);

  useEffect(() => {
    functionRef.current = callback;
  });

  return useCallback((...parameters) => {
    if (functionRef.current) {
      return functionRef.current(...parameters);
    }
  }, []);
}
