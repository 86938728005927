import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, {useState, Fragment, useEffect} from 'react';
import TextFieldFHG from '../../../components/TextField';
import {editChange} from '../../utils/Utils';
import Grid from '../Grid';
import TypographyFHG from '../Typography';

const useStyles = makeStyles(theme => ({
   textFieldStyle: {
      marginTop: 24,
      marginBottom: 4,
      '& label': {
         zIndex: 0,
         marginTop: -20,
         fontWeight: 400,
         fontSize: 14,
         color: 'black !important',
         transform: 'none !important',
      },
      '& legend': {
         display: 'none !important',
      },
      '& fieldset': {
         top: 0,
      },
      '& > div': {
         marginTop: '0 !important',
      },
      '& label div': {
         display: 'inline-block',
      }
   }
}));

/**
 * The TextField with preset formats.
 */
export default function PasswordTextField({password, confirm, margin, onChange, isNew, disabled,}) {
   const classes = useStyles();

   const [showPassword, setShowPassword] = useState(false);
   const [editValues, setEditValues] = useState({});

   useEffect(() => {
      const target = document.getElementById('confirm_password');
      if (target) {
         target.setCustomValidity(
            editValues.confirm !== editValues.password ? 'Confirm does not match the password.' : '');
      }
   }, [editValues.confirm, editValues.password, password, confirm]);

   const handleShowPasswordClick = () => {
      setShowPassword(!showPassword);
   };

   const handleChange = (event) => {
      setEditValues({...editValues, ...editChange(event)});
      onChange && onChange(event);
   };

   return (
      <Fragment>
         <Grid item xs={12} sm={6}>
            <TextFieldFHG
               name='password'
               inputProps={{
                  pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',
                  title: 'Password must contain at least 8 characters with one or more uppercase, lowercase, number and symbol.'
               }}
               labelKey={isNew ? 'user.password.label' : 'user.changePassword.label'}
               fullWidth
               required={isNew}
               disabled={disabled}
               type={showPassword ? 'text' : 'password'}
               autoComplete='current-password'
               onChange={handleChange}
               value={password}
               margin={margin}
               // eslint-disable-next-line
               InputProps={{
                  'aria-label': 'Password',
                  endAdornment: (
                     <InputAdornment position='end'>
                        <IconButton
                           aria-label='Toggle password visibility'
                           onMouseDown={handleShowPasswordClick}
                           disabled={disabled}
                        >
                           {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                     </InputAdornment>
                  )
               }}
            />
         </Grid>
         <Grid item xs={12} sm={6}>
            {!showPassword && (
               <Grid item fullWidth>
                  <TextFieldFHG
                     name='confirm'
                     label={<TypographyFHG className={classes.inlineStyle} variant='inherit'
                                           id={'user.confirmPassword.label'}>Confirm</TypographyFHG>}
                     type={'password'}
                     required={isNew}
                     onChange={handleChange}
                     value={confirm}
                     autoComplete='current-password'
                     fullWidth
                     disabled={disabled}
                     InputProps={{id: 'confirm_password'}}
                  />
               </Grid>
            )}
         </Grid>
      </Fragment>
   );
}
