import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import {lighten} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import {Delete, Clear} from '@material-ui/icons';
import {find} from 'lodash';
import * as PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import ConfirmButton from '../fhg/components/ConfirmButton';
import Grid from '../fhg/components/Grid';
import Typography from '../fhg/components/Typography';
import ValidateTarget from '../fhg/components/ValidateTarget';
import TextField from './TextField';

const useStyles = makeStyles(theme => ({
   menuItemStyle: {
      padding: '2px 2px 2px 16px',
   },
   stickyFooter: {
      position: 'sticky',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      // zIndex: 10000,
      '&:hover': {
         zIndex: 10000,
         backgroundColor: theme.palette.background.paper,

      }
   },
   deleteColorStyle: {
      color: theme.palette.error.main,
      '&:hover': {
         backgroundColor: lighten(theme.palette.error.light, 0.8),
      }
   },
}), {name: 'MenuWithAddDeleteStyles'});

MenuWithAddDelete.propTypes = {
   name: PropTypes.string,                      // Name of the component for editing.
   selectName: PropTypes.string,                // Name of the component for dropdown selection.
   selectLabelKey: PropTypes.string.isRequired, // Label key for the TextField when selecting an option.
   addLabelKey: PropTypes.string.isRequired,    // Label key for the TextField when editing.
   deleteMessageKey: PropTypes.string.isRequired,   // Confirm message key for deleting an item.
   deleteTitleKey: PropTypes.string.isRequired, // Confirm title for deleting the item.
   onSelectChange: PropTypes.func.isRequired,   // Callback when when a menu item was selected.
   onEditChange: PropTypes.func.isRequired,     // Callback when when the text field was edited.
   onDelete: PropTypes.func.isRequired,         // Callback when one of the items was deleted.
   isSelectingOption: PropTypes.bool,           // Indicates if the dropdown selection should be shown.
   options: PropTypes.array,                    // Array of options for the user to select. The options must have
                                                // label and value {i.e. {label: [item label], value: [item value]}}
   ...TextField.propTypes,                      // All the other properties of the TextField are passed to the internal
                                                // TextField
};

function MenuWithAddDelete({name, selectName, selectLabelKey, addLabelKey, addButtonKey, deleteTitleKey,
   deleteMessageKey, onSelectChange, onEditChange, onCreateChange, onDelete, options, selectValue, value, onAdd, onEdit,
   isAutoSelectFirst = true, defaultValue, required, autoFocus, isAddButton = false, buttonLabelKey,
   ...textFieldProps}) {
   const classes = useStyles();
   const theme = useTheme();

   const [isSelecting, setIsSelecting] = useState(options && options.length > 0);
   const [refresh, setRefresh] = useState(Date.now());

   useEffect(() => {
       if (!selectValue || defaultValue) {
          setRefresh(Date.now());
       }
   }, [selectValue, defaultValue]);

   useEffect(() => {
      if (!!options) {
         setIsSelecting( options.length > 0);
         setRefresh(Date.now());
         if (isAutoSelectFirst && options.length === 1 && onSelectChange) {
             onSelectChange(options[0].value, selectName || name, undefined);
         }
      }
   }, [options]);

   /**
    * Called when the the user clicks to add a new item and when the user clicks save for the new item.
    * @param event
    */
   const handleAdd = (event) => {
      if (onAdd) {
         onAdd && onAdd();
      } else {
         //If the user is saving the new item call the create callback.
         if (!isSelecting) {
            onCreateChange && onCreateChange(value, selectName, event);
            onEditChange && onEditChange(undefined, name);
            setIsSelecting(true);
         } else {
            onSelectChange && onSelectChange(undefined, selectName || name, event);
            onEditChange && onEditChange(undefined, name);
            setIsSelecting(false);
         }
      }
   };

   const handleChange = event => {
      const value = event.target.value;

      if (isSelecting) {
         if (value !== -1) {
            onSelectChange && onSelectChange(value, selectName || name, event);
         } else {
            handleAdd(event);
         }
      } else {
         onEditChange && onEditChange(value, name, event);
      }
   };

   const handleDelete = (id) => (event) => {
      onDelete && onDelete(id);
   };

   const handleClear = () => {
      onEditChange && onEditChange(undefined, name);
      setIsSelecting(true);
   };


   return (
      <Grid container item direction={'row'} alignItems={'center'} wrap={'nowrap'} spacing={1}>
         <Grid item resizable style={{position: 'relative'}}>
            <TextField
               key={'MenuWithAddDelete'+refresh}
               {...textFieldProps}
               required={required}
               defaultValue={isSelecting ? defaultValue : undefined}
               name={name}
               value={isSelecting ? selectValue : value}
               onChange={handleChange}
               label={<Typography variant='inherit' id={isSelecting ? selectLabelKey : addLabelKey}/>}
               select={isSelecting}
               autoFocus={!isSelecting || autoFocus}
               SelectProps={{
                  renderValue: value => value && options && options.length > 0 ?
                     find(options, {value}) ? find(options, {value}).label : '' : ''
               }}
               InputProps={!isSelecting && {
                  endAdornment: (
                     <InputAdornment position='end'>
                           <IconButton onMouseDown={handleClear} disabled={textFieldProps.disabled}>
                              <Clear/>
                           </IconButton>
                     </InputAdornment>
                  )
               }}
            >
               {isSelecting && (options || []).map(item => (
                  <MenuItem className={classes.menuItemStyle} value={item.value}>
                     <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}>
                        <Grid item>
                           <Typography>{item.label}</Typography>
                        </Grid>
                        <Grid item>
                           <ConfirmButton onConfirm={handleDelete(item.value)} buttonLabelKey={buttonLabelKey}
                                          style={{padding: 6, color: theme.palette.error.dark}} color={theme.palette.error.dark}
                                          component={IconButton} messageKey={deleteMessageKey}
                                          titleKey={deleteTitleKey} values={item} confirmProps={{submitColorStyle: classes.deleteColorStyle}}
                           >
                              <Delete/>
                           </ConfirmButton>
                        </Grid>
                     </Grid>
                  </MenuItem>
               ))}
               {!isAddButton && (
                  <MenuItem value={-1} className={classes.stickyFooter} onClick={handleAdd} color={'secondary'}>
                     <Grid container  fullWidth style={{zIndex:2000}}>
                        <Divider/>
                        <Typography color='secondary' id={addButtonKey} style={{zIndex:2000}}/>
                     </Grid>
                  </MenuItem>
               )}
            </TextField>
               {(isSelecting && required) && (<ValidateTarget name={'ValidateTarget'+name} top={-16} value={selectValue || defaultValue}/>)}
         </Grid>
         {isAddButton && (
            <Grid container direction={'row'} fullWidth={false}>
               {(isSelecting || onCreateChange) && (
                  <Grid item resizable={false}>
                     <Button key={'add' + isSelecting} onClick={handleAdd}
                             color={'secondary'}>
                        <Typography color='inherit' noWrap
                                    id={!isSelecting ? 'save.label' : addButtonKey}/>
                     </Button>
                  </Grid>
               )}
            </Grid>
         )}
      </Grid>
   );
}

export default MenuWithAddDelete;
