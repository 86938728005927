import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useRef, useState} from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {IconButton} from '@material-ui/core';
import classNames from 'classnames';
import useSizeToWindowHeight from '../../../fhg/hooks/useSizeToWindowHeight';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import gql from 'graphql-tag';
import useQueryFHG from '../../../fhg/components/data/useQueryFHG';
import Typography from '../../../fhg/components/Typography';
import Button from '@material-ui/core/Button';
import useMutationFHG from '../../../fhg/components/data/useMutationFHG';
import EquipmentInclusiveOpportunityCell from './EquipmentInclusiveOpportunityCell';
import EquipmentTextField from './EquipmentTextField';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmButton from '../../../fhg/components/ConfirmButton';
import InclusiveAuditChartsDialog from './InclusiveAuditChartsDialog';
import PieChartIcon from '@material-ui/icons/PieChart';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    backgroundColor: 'white',
    position: 'relative'
  },
  maximizeButton: {
    position: 'absolute',
    left: -5,
    top: -10,
    '& svg': {
      fontSize: 30
    }
  },
  chartButton: {
    position: 'absolute',
    right: -5,
    top: -10,
    '& svg': {
      fontSize: 27
    }
  },
  maximized: {
    position: 'fixed',
    top: 65,
    bottom: 0,
    left: 10,
    right: 10,
    '& $tableContainer': {
      height: '100%'
    }
  },
  groupHeaderCell: {
    zIndex: 3,
    border: '1px solid white',
    color: 'white',
    backgroundColor: 'white',
    padding: 5
  },
  maximizeButtonCell: {
    zIndex: 2
  },
  headerCell: {
    zIndex: 2,
    top: 36,
    border: '1px solid white',
    color: 'white',
    backgroundColor: 'white',
    padding: 5,
    width: 45,
    verticalAlign: 'bottom',
    whiteSpace: 'pre'
  },
  headerCellText: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)'
  },
  blankHeaderCell: {
    zIndex: 1
  },
  equipmentRow: {
    '&:nth-of-type(odd) td': {
      backgroundColor: '#f2f2ff'
    }
  },
  typeDeleteEquipmentCell: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    color: 'black',
    backgroundColor: '#d2d2d2',
    padding: 0,
    width: 50,
    minWidth: 50
  },
  typeCountCell: {
    position: 'sticky',
    left: 50,
    zIndex: 1,
    color: 'black',
    backgroundColor: '#d2d2d2',
    padding: 0,
    width: 70,
    minWidth: 70
  },
  typeNameCell: {
    color: 'black',
    backgroundColor: '#d2d2d2',
    position: 'sticky',
    left: 120,
    zIndex: 1,
    padding: '0 0 0 5px',
    whiteSpace: 'pre',
    minWidth: 200
  },
  typeBackgroundCell: {
    backgroundColor: '#d2d2d2',
    padding: 0
  },
  addEquipmentButton: {
    marginLeft: 10
  },
  deleteEquipmentCell: {
    width: 50,
    height: 50,
    position: 'sticky',
    left: 0,
    backgroundColor: 'white',
    padding: 0,
    zIndex: 1
  },
  equipmentCountCell: {
    width: 70,
    minWidth: 70,
    position: 'sticky',
    left: 50,
    backgroundColor: 'white',
    padding: 0,
    zIndex: 1
  },
  equipmentNameCell: {
    minWidth: 200,
    position: 'sticky',
    left: 120,
    backgroundColor: 'white',
    zIndex: 1,
    padding: 0
  }
}));

export const REPORT_QUERY = gql`
  query getInclusiveAuditData($reportId: Int!) {
    opportunities: opportunity_All(includeDeleted: false) {
      id
      name
      opportunityTypeId
    }
    opportunityTypes: opportunityType_All(includeDeleted: false) {
      id
      name
      color
    }
    gridEquipmentTypes: GridEquipment_TypeList
    gridEquipment: gridEquipment_AllWhere(includeDeleted: false, gridEquipmentSearch: {reportId: [$reportId]}) {
      id
      name
      quantity
      type
    }
    gridAnswers: gridAnswer_AllWhere(includeDeleted: false, gridAnswerSearch: {reportId: [$reportId]}) {
      id
      equipmentId
      opportunityId
      entry
      opportunity {
        opportunityTypeId
      }
    }
  }
`;

const EQUIPMENT_CREATE = gql`
  mutation createEquipment($templateId: Int, $reportId: Int, $type: String, $name: String!) {
    gridEquipment: gridEquipment_Create(gridEquipment: {templateId: $templateId, reportId: $reportId, name: $name, type: $type}) {
      id
      name
      type
    }
  }
`;

const EQUIPMENT_DELETE = gql`
  mutation deleteEquipment($id: Int!) {
    gridEquipment_Delete(gridEquipmentId: $id)
  }
`;

/**
 * Component to display a comprehensive inclusive audit.
 */
export default function ComprehensiveInclusiveAudit(props) {
  const classes = useStyles();
  const tableRef = useRef();
  const height = useSizeToWindowHeight(tableRef);
  const [isMaximized, setIsMaximized] = useState(false);
  const [gridEquipment, setGridEquipment] = useState([]);
  const [isChartsOpen, setIsChartsOpen] = useState(false);
  const {loading, data, statusComponent} = useQueryFHG(REPORT_QUERY, {
    variables: {reportId: props.report.id},
    fetchPolicy: 'cache-and-network'
  });
  const [equipmentCreate, equipmentCreateStatusComponent] = useMutationFHG(EQUIPMENT_CREATE);
  const [equipmentDelete, equipmentDeleteStatusComponent] = useMutationFHG(EQUIPMENT_DELETE);

  /**
   * Copies over grid equipment from the fetched data.
   */
  useEffect(() => {
    if (!gridEquipment.length && data?.gridEquipment?.length) {
      setGridEquipment(data.gridEquipment);
    }
  }, [data?.gridEquipment]);

  /**
   * Toggles whether the table is maximized.
   */
  function toggleIsMaximized() {
    setIsMaximized(!isMaximized);
  }

  /**
   * Gets the opportunity types header row element.
   *
   * @returns {JSX.Element} Opportunity types header row element.
   */
  function getOpportunityTypesHeaderRowElement() {
    return (
        <TableRow>
          <TableCell className={classNames(classes.groupHeaderCell, classes.maximizeButtonCell)} colSpan={3}>
            <IconButton onClick={toggleIsMaximized} className={classes.maximizeButton}>
              {isMaximized ? <FullscreenExitIcon/> : <FullscreenIcon/>}
            </IconButton>
            <IconButton onClick={showCharts} className={classes.chartButton}>
              <PieChartIcon/>
            </IconButton>
          </TableCell>
          {data.opportunityTypes.map((opportunityType) => {
            const opportunities = getOpportunities(opportunityType);

            return opportunities.length ? (
                <TableCell
                    key={opportunityType.id}
                    colSpan={opportunities.length}
                    align="center"
                    className={classes.groupHeaderCell}
                    style={{backgroundColor: opportunityType.color}}
                >
                  {opportunityType.name}
                </TableCell>
            ) : null;
          })}
        </TableRow>
    );
  }

  /**
   * Gets the opportunities header row element.
   *
   * @returns {JSX.Element} Opportunities header row element.
   */
  function getOpportunitiesHeaderRowElement() {
    return (
        <TableRow>
          <TableCell className={classNames(classes.headerCell, classes.blankHeaderCell)} colSpan={3}></TableCell>
          {data.opportunityTypes.map((opportunityType) => getOpportunities(opportunityType).map((opportunity) => (
              <TableCell
                  key={opportunity.id}
                  className={classes.headerCell}
                  style={{backgroundColor: opportunityType.color}}
              >
                <span className={classes.headerCellText}>{opportunity.name}</span>
              </TableCell>
          )))}
        </TableRow>
    );
  }

  /**
   * Create grid equipment.
   *
   * @param {string} gridEquipmentType Grid equipment type.
   */
  async function createGridEquipment(gridEquipmentType) {
    try {
      const result = await equipmentCreate({
        variables: {
          templateId: props.report.template.id,
          reportId: props.report.id,
          name: '',
          type: gridEquipmentType
        }
      });

      if (result?.data?.gridEquipment) {
        setGridEquipment((originalGridEquipment) => [
          ...originalGridEquipment,
          result.data.gridEquipment
        ]);
      }
    } catch (error) {
      console.log('Error creating grid equipment', error);
    }
  }

  /**
   * Delete grid equipment.
   *
   * @param {Object} equipment Grid equipment.
   */
  async function deleteGridEquipment(equipment) {
    try {
      await equipmentDelete({
        variables: {
          id: equipment.id
        }
      });

      setGridEquipment((originalGridEquipment) => {
        const newGridEquipment = [...originalGridEquipment];
        const index = newGridEquipment.indexOf(equipment);
        newGridEquipment.splice(index, 1);
        return newGridEquipment;
      });
    } catch (error) {
      console.log('Error delete grid equipment', error);
    }
  }

  /**
   * Gets the row elements.
   *
   * @returns {JSX.Element[]} Row elements.
   */
  function getRowElements() {
    return data.gridEquipmentTypes.map((gridEquipmentType) => (
        <Fragment key={gridEquipmentType}>
          <TableRow>
            <TableCell className={classes.typeDeleteEquipmentCell}></TableCell>
            <TableCell className={classes.typeCountCell}>
              #
            </TableCell>
            <TableCell className={classes.typeNameCell} colSpan={gridEquipment.length ? 10 : 0}>
              {gridEquipmentType}
              {props.isEditable && (
                  <Button className={classes.addEquipmentButton} onClick={() => createGridEquipment(gridEquipmentType)}>
                    <Typography color="secondary" id="equipment.add.label"/>
                  </Button>
              )}
            </TableCell>
            <TableCell className={classes.typeBackgroundCell} colSpan={100}></TableCell>
          </TableRow>
          {getEquipmentRowElements(gridEquipmentType)}
        </Fragment>
    ));
  }

  /**
   * Gets the equipment row elements.
   *
   * @param {string} gridEquipmentType Grid equipment type.
   * @returns {JSX.Element[]} equipment elements.
   */
  function getEquipmentRowElements(gridEquipmentType) {
    return gridEquipment.filter((equipment) => equipment.type === gridEquipmentType).map((equipment) => (
        <TableRow key={equipment.id} className={classes.equipmentRow}>
          <TableCell className={classes.deleteEquipmentCell}>
            {props.isEditable && (
                <ConfirmButton
                    onConfirm={() => deleteGridEquipment(equipment)}
                    buttonLabelKey="delete.button"
                    component={IconButton}
                    messageKey="inspect.deleteRow.confirm"
                    titleKey="inspect.deleteRow.title"
                >
                  <DeleteIcon/>
                </ConfirmButton>
            )}
          </TableCell>
          <TableCell className={classes.equipmentCountCell}>
            <EquipmentTextField
                report={props.report}
                equipment={equipment}
                field="quantity"
                isEditable={props.isEditable}
                isNumber={true}
            />
          </TableCell>
          <TableCell className={classes.equipmentNameCell}>
            <EquipmentTextField report={props.report} equipment={equipment} field="name" isEditable={props.isEditable}/>
          </TableCell>
          {data.opportunityTypes.map((opportunityType) => getOpportunities(opportunityType).map((opportunity) => (
              <EquipmentInclusiveOpportunityCell
                  key={`${equipment.id}-${opportunity.id}`}
                  report={props.report}
                  opportunity={opportunity}
                  equipment={equipment}
                  gridAnswer={getGridAnswer(opportunity.id, equipment.id)}
                  isEditable={props.isEditable}
                  color={opportunityType.color}
              />
          )))}
        </TableRow>
    ));
  }

  /**
   * Gets the grid answer.
   *
   * @param {string} opportunityId Opportunity id.
   * @param {number} equipmentId Equipment id.
   * @returns {Object} Grid answer.
   */
  function getGridAnswer(opportunityId, equipmentId) {
    return data.gridAnswers.find(
        (gridAnswer) => gridAnswer.opportunityId === opportunityId && gridAnswer.equipmentId === equipmentId);
  }

  /**
   * Gets the opportunities in an opportunity type.
   *
   * @param {Object} opportunityType Opportunity type.
   * @returns {Object[]} Opportunities.
   */
  function getOpportunities(opportunityType) {
    return data.opportunities.filter((opportunity) => opportunity.opportunityTypeId === opportunityType.id);
  }

  /**
   * Shows the charts.
   */
  function showCharts() {
    setIsChartsOpen(true);
  }

  /**
   * Hides the charts.
   */
  function closeCharts() {
    setIsChartsOpen(false);
  }

  if (loading) {
    return statusComponent;
  }

  return data ? (
      <>
        {isChartsOpen && (
            <InclusiveAuditChartsDialog report={props.report} onClose={closeCharts}/>
        )}
        <div className={classNames({
          [classes.maximized]: isMaximized
        })}>
          {equipmentCreateStatusComponent || equipmentDeleteStatusComponent}
          <TableContainer className={classes.tableContainer} ref={tableRef} style={{maxHeight: height}}>
            <Table stickyHeader>
              <TableHead>
                {getOpportunityTypesHeaderRowElement()}
                {getOpportunitiesHeaderRowElement()}
              </TableHead>
              <TableBody>
                {getRowElements()}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
  ) : null;
}

ComprehensiveInclusiveAudit.propTypes = {
  report: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired
};
