import compareAsc from 'date-fns/compareAsc';
import {compact, isArray, isNil, forOwn, toNumber as _toNumber, isFunction, delay} from 'lodash';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isObjectLike from 'lodash/isObjectLike';
import {getAccessToken} from '../../index';

/**
 * Format the message for localization. The default message has the id appended in non-production versions.
 *
 * @param intl             // Intl for localization.
 * @param id               // Message ID from localization file.
 * @param defaultMessage   // Default message to use if id cannot be found in localization file.
 * @param values           // Values to insert in the localized message.
 * @return {string}        // Localized message.
 */
export function formatMessage(intl, id, defaultMessage, values) {
   const newDefaultMessage = process.env.NODE_ENV === 'production' ? defaultMessage : `${defaultMessage} (${id})`;

   if (id) {
      return intl ? intl.formatMessage({ id, defaultMessage: newDefaultMessage }, values) : newDefaultMessage;
   } else {
      return '';
   }
}

/**
 * Converts any boolean type string to a boolean value.
 * @param string The string to convert.
 * @return {*} The boolean value or the original string if the string wasn't a boolean type string.
 */
export const stringToBoolean = (string) => {
   if (string) {
      switch (string.toLocaleLowerCase().trim()) {
         case 'true':
         case 'yes':
            return true;
         case 'false':
         case 'no':
            return false;
         default:
            return string;
      }
   } else {
      return string;
   }
};

/**
 * b64 encoding for a string containing unicode characters.
 * @param str The string to encode.
 * @return {string} The encoded string.
 */
export function b64EncodeUnicode (str) {
   // first we use encodeURIComponent to get percent-encoded UTF-8,
   // then we convert the percent encodings into raw bytes which
   // can be fed into btoa.
   return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
         return String.fromCharCode('0x' + p1);
      }));
}

/**
 * Sorts the dates using date-fns.
 * @param a
 * @param b
 * @return {number}
 */
export const sortDate = (a, b) => {

   if (a === b) {
      return 0;
   }
   if (a === undefined || a === 'Invalid date' || b === undefined || b === 'Invalid date') {
      return (a === undefined || a === 'Invalid date') ? -1 : 1;
   }
   return compareAsc(a, b);
};

/**
 * Sorts the dates firestore timestamp objects.
 * @param a
 * @param b
 * @return {number}
 */
export const sortTimestamp = (a, b) => {

   if (isEqual(a, b)) {
      return 0;
   }
   if (a === undefined || b === undefined ) {
      return a === undefined ? -1 : 1;
   }
   return ((a.seconds > b.seconds) || ((a.seconds === b.seconds) && (a.nanoseconds > b.nanoseconds))) ? 1 : -1


};

export function removeOne(array, index) {
   if (index >= 0 && array && array.length) {
      let len = array.length;
      if (!len) {
         return;
      }
      len -= 1;
      while (index < len) {
         array[index] = array[index + 1];
         index++;
      }
      array.length--;
   }
   return array;
}

export const emptyFunction = () => {};

/**
 * Determines if the item has a value (i.e. not undefined, not null, nor empty string).
 *
 * @param item The item to check.
 * @return {boolean} True if the item is not undefined, not null, and not the empty string.
 */
export function hasValue(item) {
   return !isNil(item) && item !== '' && (!isArray(item) || item.length > 0) && (!isObjectLike(item) || Object.keys(item).length > 0);
}

/**
 * If items both have values and they are equal or if they both don'have values, they are equivalent.
 * @param item1 First item to check
 * @param item2 Second item to check.
 * @return {boolean} True if the two items have the same value or both don't have a value.
 */
export function isEquivalent(item1, item2) {
   return (!hasValue(item1) && !hasValue(item2)) || isEqual(item1, item2);
}

/**
 * Get an object with the property set to the changed value or undefined, if the property hasn't changed.
 *
 * @param changedItem The possibly changed object to compare properties.
 * @param originalItem The original object to compare properties.
 * @param changedProperty The property of the changed item to compare.
 * @param originalProperty The property of the original item to compare.
 * @param isCompactArray True to compact the array type properties.
 * @return {boolean|{}} The new object with the property set if changed, or undefined if not changed.
 */
export function getChangedProperty(changedItem, originalItem, changedProperty, originalProperty,
   isCompactArray = false) {
   originalProperty = originalProperty || changedProperty;
   const changedItemProperty = isCompactArray && isArray(changedItem[changedProperty]) ?
      compact(changedItem[changedProperty]) : changedItem[changedProperty];
   return !isEquivalent(changedItemProperty, originalItem[originalProperty]) &&
      {[originalProperty]: changedItemProperty};
}

/**
 * Get an object with the property set to the changed value or undefined, if the property hasn't changed. The changed
 * property is passed directly instead of as part of an object for getChangedProperty.
 *
 * @param changedProperty The possibly changed property to compare.
 * @param originalItem The original object to compare properties.
 * @param originalProperty The property of the original item to compare.
 * @return {boolean|{}} The new object with the property set if changed, or undefined if not changed.
 */
export function getCustomChanged(changedProperty, originalItem, originalProperty) {
   return !isEquivalent(changedProperty, originalItem[originalProperty]) && {[originalProperty]: changedProperty};
}

/**
 * Get an object with all the properties set to the changed properties. Only changed properties will be in the returned
 * object.
 *
 * @param changedItem The possibly changed object to compare properties.
 * @param originalItem The original object to compare properties.
 * @param changedProperties The list of properties of the changed item to compare.
 * @param convertCallback The function to convert from the view value to the save object type.
 * @param mapChangedPropertiesToOriginal The mapping from changed property names to original properties. For example:
 *    {
 *       changedItemPropertyName: 'originalProperyName',
 *       changedItemPropertyName2: 'originalProperyName2',
 *    }
 */
export function getChangedObject(changedItem, originalItem, changedProperties, convertCallback, mapChangedPropertiesToOriginal = {}) {
   const changed = {};
   for (const property of changedProperties) {
      const originalProperty = mapChangedPropertiesToOriginal[property] || property;
      const changedField = getChangedProperty(changedItem, originalItem, property, originalProperty);
      if (changedField) {
         changed[originalProperty] = convertCallback ? convertCallback(changedItem[property]) : changedItem[property];
      }
   }
   return changed;
}

export function hasOwnToObject (object) {
   const result = {};

   forOwn(object, function(value, key) {
      if (key !== 'undefined') {
         result[key] = value;
      }
   });

   return result;
}

export function blobToBase64(blob) {
   return new Promise(async (resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function () {
         var dataUrl = reader.result;
         var base64 = dataUrl.split(',')[1];
         resolve(base64);
      };
      reader.onerror = function (error) {
         reject(error);
      };
      reader.readAsDataURL(blob);
   });
}

export function dataURItoBlob(dataURI) {
   // convert base64 to raw binary data held in a string
   // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
   var byteString =  atob(dataURI.split(',')[1]);

   // separate out the mime component
   var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

   // write the bytes of the string to an ArrayBuffer
   var ab = new ArrayBuffer(byteString.length);

   // create a view into the buffer
   var ia = new Uint8Array(ab);

   // set the bytes of the buffer to the correct values
   for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
   }

   // write the ArrayBuffer to a blob, and you're done
   var blob = new Blob([ab], {type: mimeString});
   return blob;

}

/**
 * Convert the image into the api call to get a "local" image to avoid cross domain issues.
 * @param image The image to convert.
 * @return {string|undefined|*} The relative image location.
 */
export const convertImageToWrapper = (image) => {
   if (image) {
      if (process.env.NODE_ENV === 'production') {
         //https://(bucketName).s3.us-east-2.amazonaws.com/(key)
         var regex = /https:\/\/([^.]*)[^\/]*.amazonaws.com\/(.*)/;
         const found = image.match(regex);

         if (found && found.length > 0) {
            const bucketName = found[1];
            const key = found[2];
            return `/image?bucket=${bucketName}&key=${key}`;
         }
         console.log('Image URL could not be parsed', image);
         return image;
      } else {
         return image;
      }
   }
   return undefined;
};

export async function fetchData(url = '', method = 'GET', data, contentType='application/json', isAuthenticated) {
   let accesstoken;

   if (isAuthenticated) {
      accesstoken = await getAccessToken();
   }

   const response = await fetch(url, {
      method,
      headers: {
         accesstoken,
         'Content-Type': contentType,
      },
      body: method !== 'GET' && data ? JSON.stringify(data) : undefined,
   });

   if (contentType === 'application/json') {
      return response.json(); // parses JSON response into native JavaScript objects
   } else if (contentType === 'application/blob'){
      return response.blob();
   }
}

export const editChange = (event, value, reason, isComponent = true, newValue, name) => {
   let nextValue;
   let componentName = name;

   // Retrofitting to work with existing ReactSelect that is a multiple selection.
   if (reason === true && isArray(event)) {
      nextValue = event;
      componentName = value;
   }
   else if (newValue === undefined) {
      if (value && (reason === 'blur' || reason === 'create-option' || reason === 'select-option')) {
         nextValue = typeof value === 'string' ? value : value.id;
         componentName = get(event, 'target.parentElement.dataset.optionname') ||
            get(event, 'target.firstElementChild.dataset.optionname') || event.target.name;
      } else if (value && reason === 'date-picker') {
         nextValue = value;
         componentName = event.target.name;
      } else {
         if (event && event.target) {
            switch (event.target.type) {
               case 'number':
                  nextValue = event.target.valueAsNumber;
                  break;
               case 'checkbox':
                  nextValue = event.target.checked;
                  break;
               case 'date-range':
                  nextValue = event.target.date;
                  break;
               case 'react-select':
                  nextValue = event.target.value;
                  break;
               default:
                  const type = get(event, 'target.dataset.type');
                  if (type === 'number') {
                     nextValue = toNumber(event.target.value);
                  } else {
                     nextValue = value || event.target.value;
                  }
                  break;
            }
            componentName = event.target.name;
         } else {
            console.log('event.target is null');
         }
      }
   }

   if (newValue) {
      return isComponent ? newValue : {componentName, newValue: newValue[name]};
   } else if (isComponent) {
      return {[componentName]: nextValue};
   }
   return {componentName, newValue: nextValue};
};

export function toNumber(value) {
   if (value === null || value === undefined) {
      return null;
   } else {
      return _toNumber(value);
   }
}

export function toString(value) {
   if (value === null || value === undefined) {
      return null;
   } else {
      return value.toString();
   }
}

export function scrollIntoView(elementName, delayAmount = 300, scrollToTop = true) {
   delay(() => {
      const elements = document.getElementsByName(elementName);

      if (elements && elements[0]) {
         if (isFunction(elements[0].scrollIntoViewIfNeeded)) {
            elements[0].scrollIntoViewIfNeeded();
         } else {
            elements[0].scrollIntoView(scrollToTop);
         }
      }
   }, delayAmount);
}

export function scrollIntoViewOnly(elementName, delayAmount = 300, scrollToTop = true) {
   delay(() => {
      const elements = document.getElementsByName(elementName);

      if (elements && elements[0]) {
         elements[0].scrollIntoView(scrollToTop);
      }
   }, delayAmount);
}

export function isPDFNotSupported() {
   let agent = (navigator.userAgent||navigator.vendor||window.opera).toLowerCase();

   return agent.indexOf("android") > -1 && agent.indexOf("chrome") > -1;
}
