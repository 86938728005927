import CircularProgress from '@material-ui/core/CircularProgress';
import domtoimage from 'dom-to-image';
import React from 'react';
import DisplayError from '../DisplayError';
import {dataURLToBlob} from '../Video';

export const loadingAndError = (loading, error) => {
   if (loading) {
      return <CircularProgress className={'progress'}/>;
   } else if (error) {
      console.log(error);
      return <DisplayError error={error} errorId='fetch.error' values={error}/>
   }
};

export const saveImage = (elementId, imageBlobName, width = 640, height = 480) => {
   return new Promise(async (resolve, reject) => {
      const imageEl = document.getElementById(elementId);

      if (imageEl) {
         domtoimage.toBlob(imageEl)
            .then(function (blob) {
               blob.name = imageBlobName;
               const src = URL.createObjectURL(blob);

               const div = document.createElement("div");
               div.id = "cloned_img_div";
               document.getElementById('clone').append(div)
               const imageEl1 = document.getElementById('cloned_img_div');
               imageEl1.style.width = `${width}px`;
               imageEl1.style.height = `${height}px`;
               imageEl1.style.backgroundImage = `url(${src})`;
               imageEl1.style.backgroundRepeat = `no-repeat`;
               imageEl1.style.backgroundPosition = 'center';
               imageEl1.style.backgroundSize = 'cover';

               domtoimage.toBlob(imageEl1)
                  .then(function (blob) {
                     imageEl1.remove();
                     blob.name = imageBlobName;
                     const src = URL.createObjectURL(blob);
                     resolve({src, blob});
                  }
               )
            })
      } else {
         console.log('No image element');
         reject('No image element.');
      }
   });
};
