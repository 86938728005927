import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import { debounce } from 'lodash';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import TextField from '../../components/TextField';
import { SUBMIT_ANSWER } from '../../Constants';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import Form from '../../fhg/components/Form';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import { REPORT_FRAGMENT } from './InspectionChecklist';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0, 2, 0, 5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 0.5, 0, 1),
    },
  },
}));

export const REPORT_UPDATE = gql`
  mutation UpdateReportForSummary($id: Int!, $summary: String) {
    report: report_Update(reportId: $id, report: { summary: $summary }) {
      ...reportInfoForChecklist
    }
  }
  ${REPORT_FRAGMENT}
`;

/**
 * Component to edit summary.
 *
 * Reviewed:
 */
export default function Summary({ report, isEditable }) {
  const classes = useStyles();

  const [summary, setSummary] = useState('');
  const [summaryUpdate, summaryUpdateStatusComponent] = useMutationFHG(
    REPORT_UPDATE,
    undefined,
    'summaryUpdate'
  );

  // Set the values from the report.
  useEffect(() => {
    if (!!report) {
      setSummary(report.summary || undefined);
    }
    // eslint-disable-next-line
  }, [report]);

  /**
   * Submit the changes to the server.
   * @param summary The edited summary.
   * @return {Promise<void>}
   */
  const submitChange = async (summary) => {
    await summaryUpdate({
      variables: {
        id: report.id,
        summary,
      }
    });
  };

  // Debounce the submit changes call so that changes to text fields don't cause continual submits.
  const submitChangeDelayed = useRef(
    debounce(submitChange, SUBMIT_ANSWER)
  ).current;

  useEffect(() => {
    return submitChangeDelayed.flush;
  }, []);

  /**
   * Handle changes to the edited values.
   * @param event The event that triggered the change.
   */
  const handleChange = event => {
    setSummary(event.target.value);
    submitChangeDelayed(event.target.value);
  };

  return (
    <Fragment>
      {summaryUpdateStatusComponent}
      <Form
        style={{ width: '100%' }}
        submit={() => submitChangeDelayed.flush()}
      >
        <Grid
          name={'Summary Edit'}
          container
          fullWidth
          className={classes.root}
          direction={'column'}
        >
          <TextField
            name='summary'
            label={<Typography id={'summary.notes.label'} />}
            fullWidth
            value={summary}
            onChange={handleChange}
            onBlur={() => submitChangeDelayed.flush()}
            multiline
            disabled={!isEditable}
            rows={3}
          />
        </Grid>
      </Form>
    </Fragment>
  );
}
