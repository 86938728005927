import Amplify from '@aws-amplify/core';
import withStyles from '@material-ui/core/styles/withStyles';
import {jssPreset, StylesProvider} from '@material-ui/styles';
import {I18n} from 'aws-amplify';
import {Authenticator, SignIn, ForgotPassword, VerifyContact} from 'aws-amplify-react';
import {create} from 'jss';
import jssCompose from 'jss-compose';
import React, {useEffect, Suspense} from 'react';
import {IntlProvider} from 'react-intl';
import {LOGIN_HEADER_BACKGROUND, LOGIN_PAGE_GRAPHIC} from '../Constants';
import AppearanceSettingProvider from '../fhg/components/AppearanceSettingProvider';
import Loading from '../fhg/components/Loading';
// import {LOGIN_BACKGROUND, LOGIN_HEADER_BACKGROUND} from '../Constants';
import Main from './Main';
import CustomSignIn from './CustomSignIn';

// Configure JSS
const jss = create({plugins: [...jssPreset().plugins, jssCompose()]});

const authScreenLabels = {
   en: {
      'Sign Up': 'Create new account',
      'Sign Up Account': 'Create a new account',
      'Sign in to your account': ' ',
      'No account? ': 'Don\'t have an account? ',
      'Create account': 'Sign Up!',
      'Account recovery requires verified contact information': 'Verify Email (click email and verify)',
      // 'Username': ' ',
      // 'Password': ' ',
      // 'Enter your password': 'Password',
      // 'Enter your username': 'Username',
   }
};

I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

let Auth;
if (process.env.REACT_APP_POOL === 'production') {
   Auth = {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-2:0b3bdd64-37d8-406b-9dc5-789f6c9d99f1',
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-2',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-2_48CwQwBBO',
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: '66kooti4pe260o3u8rqdldsrp5',
   };
} else if (process.env.REACT_APP_POOL === 'test') {
   Auth = {
      // REQUIRED - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-2:b981376f-69d1-41c9-a2b6-4d4eaefd5971',
      // REQUIRED - Amazon Cognito Region
      region: 'us-east-2',
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-2_zGOQpfFLn',
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: '7e7u8ah5a61r5h06i2ia9s5qrc',
   };
}

Amplify.configure({
   Auth,
   Storage: {
      bucket: 'parkpro-images', //REQUIRED -  Amazon S3 bucket
      region: 'us-east-2',
      customPrefix: {
         public: '',
         protected: '',
         private: ''
      }
   },
   Analytics: {
      // OPTIONAL - disable Analytics if true
      disabled: true,
   },
});

export const MyTheme = {
   button: {

      padding: '6px 16px',
      fontSize: '0.875rem',
      minWidth: 64,
      boxSizing: 'border-box',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      lineHeight: 1.75,
      fontFamily: '"prometo", "Montserrat", "Arial", "Helvetica Neue", "Helvetica", sans-serif',
      fontWeight: 500,
      borderRadius: 0,
      textTransform: 'none',
      color: '#fff',
      width: '100%',
      backgroundColor: '#1796a4',
   },
   input: {
      borderRadius: 0,
   },
   sectionHeader: {
      background: `url(${LOGIN_HEADER_BACKGROUND}) no-repeat`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      height: 187,
      marginBottom: 48,
      color: '#fff',
   },
   sectionBody: {
      margin: '12px 50px',
   },
   //TODO Add this back when we have real photo.
   container: {
      // background: `url(${LOGIN_BACKGROUND}) no-repeat`,
      // backgroundSize: 'cover',
      height: '100vh',
      width: '100vw',
   },
   formContainer: {
      margin: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyItems: 'center',
      height: '100%',
      background: `url(${LOGIN_PAGE_GRAPHIC}) no-repeat`,
      backgroundPosition: 'bottom',
   },
   formSection: {
      margin: 'auto',
      padding: 0,
      width: 360,
      minWidth: 360,
      borderRadius: 0,
      background: 'transparent',
      boxShadow: 'none',
   },
   sectionFooterPrimaryContent: {
      width: '100%',
   },
   a: {
      color: '#e87c24',
      // cursor: 'pointer',
   },
   hint: {
      color: '#1596a1',
      fontSize: '14px',
   },
   inputLabel: {
      color: '#e87c24'
   },
   sectionFooter: {
      // fontSize: '16px',
      flexDirection: 'column',
      // alignItems: 'center',
      padding: '0 50px 40px',
      margin: 'auto',
      // width: '100%'
   },
   sectionFooterSecondaryContent: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 8,
   }
};

const styles = {
   forgotPasswordStyles: {
      '& [data-test*="forgot"] span': {
         position: 'absolute',
         top: 187,
         left: '26%',
      }
   },
   verifyStyles: {
      '& [data-test*="verify"] span': {
         position: 'absolute',
         top: 200,
         left: '10%',
      },
      '& [class^=Section__sectionBody] label': {
         position: 'absolute',
         marginTop: 4,
         color: '#fff',
         // left: '26%',
      }
   },
};

const messageLoader = {
   en: () => import('../messages/en-US')
}

function App(props) {
   const {classes} = props;
   const [messages, setMessages] = React.useState();

   useEffect(() => {
      messageLoader.en().then((messages) => {
         setMessages(messages);
      });
   }, []);

   if (messages) {
      return (
         <Suspense fallback={<Loading isLoading/>}>
            <IntlProvider messages={messages} locale={'en'}>
               <AppearanceSettingProvider>
                  <StylesProvider jss={jss}>
                     <div className={classes.forgotPasswordStyles}>
                        <div className={classes.verifyStyles}>
                           <Authenticator hideDefault={true} theme={MyTheme}>
                              <Main/>
                              <VerifyContact className={classes.verifyStyles}/>
                              {/* <SignIn/> */}
                              <CustomSignIn />
                              <ForgotPassword className={classes.forgotPasswordStyles}/>
                           </Authenticator>
                        </div>
                     </div>
                  </StylesProvider>
               </AppearanceSettingProvider>
            </IntlProvider>
         </Suspense>
      );
   } else {
      return null;
   }
}

export default withStyles(styles)(App);
