import L from 'leaflet';
import { forwardRef, useEffect, useRef } from 'react';
import { useImperativeHandle } from 'react';
import React, { useState, Fragment } from 'react';
import { Map as MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { MAP_FOOTBALL_ICON, MAP_PARK_ICON, MAP_PIN_ICON, MAP_STAR_ICON, MAP_WRENCH_ICON, MAX_ZOOM_DEFAULT, MIN_ZOOM_DEFAULT } from '../../../Constants';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import { saveImage } from '../data/DataUtils';
import DraggableMarker from './DraggableMarker';
import { isArray } from 'lodash';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({ iconRetinaUrl, iconUrl, shadowUrl });

const starIcon = new L.Icon({
  iconUrl: MAP_STAR_ICON,
  iconSize: [24, 24],
  iconAnchor: [32, 12],
  popupAnchor: [10, 0],
  shadowSize: [41, 41],
});

const picnicTableIcon = new L.Icon({
  iconUrl: MAP_PARK_ICON,
  iconSize: [24, 24],
  iconAnchor: [32, 12],
  popupAnchor: [10, 0],
  shadowSize: [41, 41],
});

const ballIcon = new L.Icon({
  iconUrl: MAP_FOOTBALL_ICON,
  iconSize: [24, 24],
  iconAnchor: [32, 12],
  popupAnchor: [10, 0],
  shadowSize: [41, 41],
});

const wrenchIcon = new L.Icon({
  iconUrl: MAP_WRENCH_ICON,
  iconSize: [24, 24],
  iconAnchor: [32, 12],
  popupAnchor: [10, 0],
  shadowSize: [41, 41],
});

const pinIcon = new L.Icon({
  iconUrl: MAP_PIN_ICON,
  iconSize: [18, 28],
  iconAnchor: [32, 12],
  popupAnchor: [10, 0],
  shadowSize: [41, 41],
});

const MapFHG = forwardRef(function MapFHG(
  {
    defaultCenter,
    defaultZoom,
    maxZoom = MAX_ZOOM_DEFAULT,
    minZoom = MIN_ZOOM_DEFAULT,
    onChange,
    disabled,
    onClickOnMap,
    current,
    makerLabel,
    markers,
    hideCenter,
    showPopup,
  },
  ref,
) {
  const mapRef = useRef(null);
  const [location, setLocation] = useState();

  useEffect(() => {
    if (isArray(markers) && markers.length) {
      const bounds = markers.map((marker) => [marker.lat, marker.lng]);
      const map = mapRef.current.leafletElement;
      map.fitBounds(bounds);
    }
  }, [markers]);

  useImperativeHandle(ref, () => ({
    async getImage() {
      const image = await saveImage('map', 'Photo_' + new Date().toLocaleDateString(), 640, 480);
      return { image, location };
    },
  }));

  const handleDragEnd = (event) => {
    setLocation(event.target.getLatLng());
    onChange?.(event.target.getLatLng());
  };

  const handleMarkerChange = (marker) => {
    setLocation(marker?.getLatLng());
    onChange?.(marker?.getLatLng());
  };

  const getIconByCategory = (category) => {
    switch (category) {
      case 'star':
        return starIcon;
      case 'picnic':
        return picnicTableIcon;
      case 'ball':
        return ballIcon;
      case 'wrench':
        return wrenchIcon;
      case 'generic':
        return pinIcon;
      default:
        return pinIcon;
    }
  };

  return (
    <Fragment>
      <div id="map" key={'Map'} style={{ height: '100%', width: '100%' }}>
        <MapContainer
          ref={mapRef}
          name="map"
          center={defaultCenter}
          zoom={defaultZoom}
          scrollWheelZoom={true}
          minZoom={minZoom}
          maxZoom={maxZoom}
          style={{ height: '100%', width: '100%', minHeight: 150 }}
          zoomControl={false}
          dragging={!disabled}
          noMoveStart={disabled}
          onClick={
            !disabled
              ? onClickOnMap
              : () => {
                  //
                }
          }
        >
          <TileLayer
            key={'tileLayer'}
            attribution={'Google.com'}
            url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
            subdomains={['mt0', 'mt1']}
          />
          {!hideCenter && (
            <DraggableMarker
              isDraggable={!disabled}
              location={current || defaultCenter}
              onDragEnd={handleDragEnd}
              onChange={handleMarkerChange}
              label={makerLabel}
              icon={getIconByCategory('generic')}
            />
          )}
          {markers?.map((marker, index) => (
            <DraggableMarker
              key={index}
              isDraggable={false}
              location={marker}
              label={marker.name}
              showPopup={showPopup}
              icon={getIconByCategory(marker?.pinType)}
            />
          ))}
        </MapContainer>
      </div>
    </Fragment>
  );
});

export default MapFHG;
