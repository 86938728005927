/**
 * Gets the maximum the height of the element that will fit within the screen without triggering a vertical scrollbar.
 */
import {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useFunctionRef} from './useFunctionRef';

export default function useSizeToWindowHeight(ref) {
  const [height, setHeight] = useState('0px');
  const updateHeightFunctionRef = useFunctionRef(updateHeight);
  const bottomOffset = 25;

  useEffect(() => {
    const intervalId = setInterval(updateHeightFunctionRef, 500);

    return () => {
      clearInterval(intervalId);
    };
  }, [ref]);

  /**
   * Update the height to fit within the screen without triggering a vertical scrollbar.
   */
  function updateHeight() {
    if (ref && ref.current) {
      const newHeight = `calc(100vh - ${
          Math.floor(bottomOffset +
              ref.current.getBoundingClientRect().top +
              document.documentElement.scrollTop)
      }px)`;

      if (height !== newHeight) {
        setHeight(newHeight);
      }
    }
  }

  return height;
}

useSizeToWindowHeight.propTypes = {
  ref: PropTypes.any.isRequired
};

