export function imageCaptureInitialize() {
   let ImageCapture = window.ImageCapture;

   if (typeof ImageCapture === 'undefined') {
      ImageCapture = class {

         /**
          * TODO https://www.w3.org/TR/image-capture/#constructors
          *
          * @param {MediaStreamTrack} videoStreamTrack - A MediaStreamTrack of the 'video' kind
          */
         constructor(videoStreamTrack) {
            if (videoStreamTrack.kind !== 'video') {
               throw new DOMException('NotSupportedError');
            }

            this._videoStreamTrack = videoStreamTrack;
            if (!('readyState' in this._videoStreamTrack)) {
               // Polyfill for Firefox
               this._videoStreamTrack.readyState = 'live';
            }

            // MediaStream constructor not available until Chrome 55 -
            // https://www.chromestatus.com/feature/5912172546752512
            this._previewStream = new MediaStream([videoStreamTrack]);
            this.videoElement = document.createElement('video');
            this.videoElementPlaying = new Promise(resolve => {
               this.videoElement.addEventListener('playing', resolve);
            });
            if (HTMLMediaElement) {
               this.videoElement.srcObject = this._previewStream;  // Safari 11 doesn't allow use of createObjectURL for
                                                                   // MediaStream
            } else {
               this.videoElement.src = URL.createObjectURL(this._previewStream);
            }
            this.videoElement.muted = true;
            this.videoElement.setAttribute('playsinline', ''); // Required by Safari on iOS 11. See
                                                               // https://webkit.org/blog/6784
            this.videoElement.play();

            this.canvasElement = document.createElement('canvas');
            // TODO Firefox has https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas
            this.canvas2dContext = this.canvasElement.getContext('2d');
         }

         /**
          * https://w3c.github.io/mediacapture-image/index.html#dom-imagecapture-videostreamtrack
          * @return {MediaStreamTrack} The MediaStreamTrack passed into the constructor
          */
         get videoStreamTrack() {
            return this._videoStreamTrack;
         }

         /**
          * TODO
          * Implements https://www.w3.org/TR/image-capture/#dom-imagecapture-takephoto
          * @return {Promise<Blob>} Fulfilled promise with [Blob](https://www.w3.org/TR/FileAPI/#blob)
          * argument on success; rejected promise on failure
          */
         takePhoto() {
            const self = this;
            return new Promise(function executorTP(resolve, reject) {
               // `If the readyState of the MediaStreamTrack provided in the constructor is not live,
               // return a promise rejected with a new DOMException whose name is "InvalidStateError".`
               if (self._videoStreamTrack.readyState !== 'live') {
                  return reject(new DOMException('InvalidStateError'));
               }
               self.videoElementPlaying.then(() => {
                  try {
                     self.canvasElement.width = self.videoElement.videoWidth;
                     self.canvasElement.height = self.videoElement.videoHeight;
                     self.canvas2dContext.drawImage(self.videoElement, 0, 0);
                     self.canvasElement.toBlob(resolve);
                  } catch (error) {
                     reject(new DOMException('UnknownError'));
                  }
               });
            });
         }
      };
   }

   window.ImageCapture = ImageCapture;
}