import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Menu from '@material-ui/core/Menu/Menu';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {sortBy} from 'lodash';
import * as _ from 'lodash';
import React, {useState, useEffect, useMemo} from 'react';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import ItemMenu from './ItemMenu';

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
   contentStyle: {
      overflow: 'hidden'
   },
   buttonStyle: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      position: 'sticky',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      bottom: 0,
      '&:hover': {
         backgroundColor: theme.palette.background.paper,
      },
      zIndex: 1,
   },
   selectAllStyle: {
      backgroundColor: theme.palette.background.paper,
      width: '100%',
      position: 'sticky',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      top: 0,
      '&:hover': {
         backgroundColor: theme.palette.background.paper,
      },
      zIndex: 1000,
   },
   checkboxStyle: {
      padding: 2,
   }
}));

export default function ItemMenuLazy({
   open,
   anchorEl,
   onClose,
   onAddItems,
   items,
   onLoad,
   nameProperty = 'name',
   itemName,
   useAddAll,
   operationName,
}) {

   useEffect(() => {
      if (open && onLoad) {
         onLoad();
      }
   }, [open, onLoad]);

   if (items) {
      return (
         <ItemMenu open={open} anchorEl={anchorEl} onClose={onClose} items={items} operationName={operationName}
                   onAddItems={onAddItems} itemName={itemName} nameProperty={nameProperty}
                   useAddAll={useAddAll}/>
      );
   } else {
      return null;
   }
}
