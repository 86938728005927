import Auth from '@aws-amplify/auth';
import React from 'react';
import ReactDOM from 'react-dom';
import {ENDPOINT} from './Constants';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import './index.css';
import {ClientImpersonationContextProvider, useClientImpersonationContextState} from './ClientImpersonationContext';
import {ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {RetryLink} from '@apollo/client/link/retry';

export const getAccessToken = () => {
  return Auth.currentSession()
    .then(session => {
      return session.accessToken.jwtToken;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};
function ApolloAppProvider({ children }) {
  const { clientId } = useClientImpersonationContextState();

  const uri = ENDPOINT;

  const httpLink = createHttpLink({ uri });
  const retryLink = new RetryLink();

  const authLink = setContext(async (_, { headers }) => {
    let token;
    token = await getAccessToken();

    const authHeaders = { ...headers };
    if (clientId) {
      authHeaders['x-client-id'] = clientId;
    }

    return {
      headers: {
        ...authHeaders,
        accesstoken: token || '',
      }
    };
  });

  const client = new ApolloClient({
    link: ApolloLink.from([retryLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  // Add the format command for adding parameters to strings. For Example:
  //    'This is a test: {testName}'.format({testName: 'Test Hello World'})
  if (!String.prototype.format) {
    // eslint-disable-next-line
    String.prototype.format = function (values) {
      return this.replace(/{(\w+)}/g, function (match, key) {
        return typeof values[key] !== 'undefined' ? values[key] : match;
      });
    };
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

ReactDOM.render(
  <ClientImpersonationContextProvider>
    <ApolloAppProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloAppProvider>
  </ClientImpersonationContextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
