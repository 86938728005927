import {Storage} from 'aws-amplify';
import get from 'lodash/get';
import {BUCKET_NAME} from '../../Constants';

export const uploadPhotoS3 = async (image) => {
   if (image) {
      const imagePath = `upload/${Date.now()}_${image.name || image?.blob?.name}`;

      return Storage.put(imagePath, image.blob, {level: 'public', contentType: get(image, 'blob.type', image.type)})
         .then(() => {
            return `${BUCKET_NAME}/${imagePath}`;
         })
         .catch(error => {
            console.log('Error uploading file', error);
            throw error;
         })
   }
};

export const uploadFileS3 = async (file) => {
   if (file) {
      const imagePath = `upload/${Date.now()}_${file.name}`;

      try{
         await Storage.put(imagePath, file, {level: 'public', contentType: file.type})
         return `${BUCKET_NAME}/${imagePath}`;
      } catch (error) {
         console.log('Error uploading file', error);
         throw error;
      }
   }
};
