import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import {dataURLToBlob} from 'blob-util/';
import domtoimage from 'dom-to-image';
import {debounce, delay} from 'lodash';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {PureComponent, lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import Camera, {FACING_MODES, IMAGE_TYPES} from 'react-html5-camera-photo';
// import 'react-html5-camera-photo/build/css/index.css';
import {PLACEHOLDER_IMAGE} from '../../Constants';
import {convertImageToWrapper} from '../utils/Utils';
import Grid from './Grid';
import {imageCaptureInitialize} from './ImageCapture';
import Loading from './Loading';
import {renderHighlight, renderEditor, RenderContent, renderSelector} from './RedAnnotation';
import Typography from './Typography';
import './Video.css';

const Annotation = lazy(() => import('../../react-image-annotation-edit-delete-annotations/src/components/Annotation'));

const styles = {
   photo: {
      backgroundColor: '#FFF',
      position: 'relative',
      top: 0,
      left: 0,
      objectFit: 'contain',
      width: '100%',
      // height: '100%',
      paddingBottom: '75%',
      maxWidth: 640,
      maxHeight: 480,
   },
   video: {
      width: '100%',
      height: '100%',
   },
   progress: {
      position: 'relative',
      zIndex: 1001,
      display: 'table',
      transform: 'translateY(-50%) translateX(-50%)',
      top: '50%',
      left: '50%',
   },
   annotationStyle: {
      // width: 320,
      // height: 240,
      position: 'relative',
      width: "100% !important",
      // paddingBottom: '75%',
      //
      // // objectFit: 'contain',
      height: '100%  !important',
   },
   annotationFrameStyle: {
      position: 'relative',
      flex: '0 0 auto',
      height: 'calc(100% - 47px)',
      width: '100% !important',
      margin: 'auto',
      flexDirection: 'column',
      maxWidth: 640,
      maxHeight: 640,
      display: 'flex',
      '& > div': {
         flex: '0 0 auto',
         overflow: 'hidden',
      },
   },
   annotationFrameStyle2: {
      overflow: 'visible',
      position: 'relative',
      flex: '0 0 auto',
      margin: 'auto',
      height: '100% !important',
      width: '100%  !important',
      maxWidth: 640,
      maxHeight: 640,
      display: 'flex',
   },
   noAnnotationsStyle: {
     display: 'none',
   },
   annotationFrameStyle3: {
      position: 'relative',
      flex: '0 0 auto',
      // width: 640,
      width: '100% !important',
      height: '100% !important',
      display: 'flex',
      '& > div': {
         flex: '0 0 auto',
         // overflow: 'hidden',
      },
   },
   cameraStyle: {
      height: '100%',
      width: '100%',
      '& .react-html5-camera-photo ': {
         height: '100%',
         width: '100%',
      },
      '& .react-html5-camera-photo>video': {
         height: '100%',
         width: '100%',
         maxHeight: 640,
         maxWidth: 640,
         objectFit: 'contain',
      },
   },
   saveProgressStyle: {
      marginLeft: 'auto',
      position: 'relative',
      marginRight: 'auto',
   },
};

imageCaptureInitialize();

class Video extends PureComponent {

   static propTypes = {
      useAudio: PropTypes.bool,
      useVideo: PropTypes.bool,
      placeHolderImage: PropTypes.string,
      image: PropTypes.string,
      annotations: PropTypes.array,
      direction: PropTypes.string,
      allowAnnotations: PropTypes.bool,
   };

   static defaultProps = {
      useAudio: false,
      useVideo: true,
      placeHolderImage: PLACEHOLDER_IMAGE,
      direction: 'column',
      annotations: [],
      allowAnnotations: true,
   };

   constructor(props) {
      super(props);

      this.state = {
         stream: undefined,
         refresh: Date.now(),
         image: convertImageToWrapper(props.image),
         annotations: get(props, 'annotations', []),
         annotation: {},
      };
   }

   componentDidMount() {
      this.handleResize();
      window.addEventListener('resize', this.handleDebounceResize);
   }

   componentWillUnmount() {
      window.removeEventListener('resize', this.handleDebounceResize);
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.image !== this.props.image && nextProps.image) {
         this.setState({image: nextProps.image}, () => {
            this.img.src = convertImageToWrapper(nextProps.image);
            setTimeout(() => {
               this.handleResize();
            }, 500);
         });
      }
   }


   onChange = (annotation) => {
      this.setState({annotation});
   };

   saveImage = () => {
      return new Promise(async (resolve, reject) => {
         this.setState({isSaving: true});
         const { allowAnnotations, annotations, image } = this.state;
         const annotationEl = document.getElementsByName('Annotation Image');
         if (annotations && annotations.length > 0) {
            if (annotationEl && annotationEl.length > 0) {
               this.containerEl = document.createElement('div');
               document.body.appendChild(this.containerEl);
               let imageWidth = this.img.naturalWidth;
               let imageHeight = this.img.naturalHeight;
               if (imageWidth > 640 || imageHeight > 640) {
                  if (imageWidth > imageHeight) {
                     imageWidth = 640;
                     imageHeight = 480;
                  } else {
                     imageWidth = 480;
                     imageHeight = 640;
                  }
               }
                  // annotationEl[0].setAttribute("style", `width:${imageWidth}px; height: ${imageHeight}px;`);
               this.containerEl.setAttribute("style", `width:${imageWidth}px; height: ${imageHeight}px;`);
               this.containerEl.appendChild(annotationEl[0]);
               this.setState({showExternal: true, marginHeight: 0, marginWidth: 0, width: imageWidth, height: imageHeight}, () => {
                  const annotationEl = document.getElementsByName('Annotation Image');
                  domtoimage.toBlob(annotationEl[0], {width: imageWidth, height: imageHeight})
                     .then(function (blob) {
                        blob.name = 'Photo_' + Date.now();
                        const src = URL.createObjectURL(blob);
                        resolve({src, blob});
                        // this.setState({isSaving: false});
                     })
                     .finally(() => {
                        try{
                           document.body.removeChild(this.containerEl);
                        } catch (e) {
                           console.log (e);
                        }
                     })
               });
            } else {
               console.log('No annotation element');
               this.setState({isSaving: false});
               reject('No annotation element.');
            }
         } else {
            this.setState({isSaving: false});
            resolve({src: image, blob: dataURLToBlob(image)});
         }
      });
   };

   handleAnnotationSubmit = (annotation) => {
      const { onAnnotationUpdate } = this.props;
      const {geometry, data} = annotation;
      const useAnnotations = this.state.annotations || [];

      onAnnotationUpdate && onAnnotationUpdate();
      this.setState({
         annotation: {},
         annotations: useAnnotations.concat({
            geometry,
            data: {
               ...data,
               id: Math.random()
            }
         })
      });
   };

   onUpdate = () => {
      const { onAnnotationUpdate } = this.props;
      const {annotations, annotation} = this.state;
      let oldIndex = annotations.findIndex((a) => {
         return a.data.id === annotation.data.id;
      });
      if (oldIndex > -1) {
         annotations[oldIndex] = {
            geometry: {...annotation.geometry},
            data: {
               ...annotation.data,
               id: annotation.data.id
            }
         };
      }
      onAnnotationUpdate && onAnnotationUpdate();
      this.setState({annotation: {}, annotations: annotations});
   };

   onDelete = () => {
      const {annotations, annotation} = this.state;
      let keepAnnotations = annotations.filter((a) => {
         return a.data.id !== annotation.data.id;
      });

      this.setState({annotation: {}, annotations: keepAnnotations});
   };

   handleTakePhoto = (dataUri) => {
      this.img.src = dataUri;
      this.setState({image: this.img.src, annotations: [], annotation: {}}, () => {
         this.handleResize();
         this.props.onTakePhoto(dataUri);
      });
   };

   handleResize = () => {
      if (this.mainRef) {
         this.mainNode = ReactDOM.findDOMNode(this.mainRef);
         let height, width, marginHeight, marginWidth;
         const mainNode = this.mainNode;
         const image = get(this, 'img');
         const panelWidth = Math.min(mainNode.parentNode.clientWidth, 640);
         const panelHeight = Math.min(mainNode.parentNode.clientHeight, 640);

         if (image) {
            if (image.naturalHeight === 0 && image.naturalWidth === 0) {
               return;
            }
            // Image is landscape and dialog is landscape
            if (image.naturalWidth >= image.naturalHeight && panelWidth >= panelHeight) {
               const calcHeight = panelWidth * 0.75;

               if (calcHeight > panelHeight) {
                  width = panelHeight / 0.75;
                  height = panelHeight;
               } else {
                  width = panelWidth;
                  height = calcHeight;
               }
               marginHeight = (mainNode.clientHeight - height) / 2;
               marginWidth = (mainNode.clientWidth - width) / 2;

               // Image is portrait and dialog is landscape
            } else if (image.naturalWidth <= image.naturalHeight && panelWidth >= panelHeight){
               width = panelHeight * 0.75;

               marginWidth = (mainNode.clientWidth - width) / 2;
               marginHeight = 0;

               // Image is landscape and dialog is portrait
            } else if (image.naturalWidth >= image.naturalHeight && panelWidth <= panelHeight) {
               height = panelWidth * 0.75;

               marginWidth = 0;
               marginHeight = (mainNode.clientHeight - height) / 2;

               // Image is portrait and dialog is portrait
         } else {
               marginWidth = 0;
               marginHeight = 0;
            }
         } else if (panelWidth > panelHeight) {
            const calcHeight = panelWidth * 0.75;

            if (calcHeight > panelHeight){
               width = panelHeight / 0.75;
               marginHeight = 0;
               marginWidth = (panelWidth - width) / 2;
            } else {
               width = panelWidth;
               height = calcHeight;
               marginHeight = (panelHeight - height) / 2;
               marginWidth = (panelWidth - width) / 2;
            }
            } else {
               marginWidth = 0;
               marginHeight = 0;
         }

         this.setState({width:mainNode.clientWidth, height:mainNode.clientHeight, marginWidth, marginHeight});
      }
   };

   /**
    * Handle the window resizing after a debounce.
    */
   handleDebounceResize = debounce(this.handleResize, 250);

   /**
    * Callback when the camera is started. Use cache the media stream to use for zooming.
    *
    * @param mediaStream Media stream for the camera.
    */
   handleCameraStart = mediaStream => {
      // Once crbug.com/711524 is fixed, we won't need to wait anymore. This is
      // currently needed because capabilities can only be retrieved after the
      // device starts streaming. This happens after and asynchronously w.r.t.
      // getUserMedia() returns.
      delay(() => {
         const track = mediaStream.getVideoTracks()[0];
         const capabilities = track.getCapabilities();
         const settings = track.getSettings();

         if (!('zoom' in capabilities)) {
            return;
         }
         const input = document.querySelector('input[type="range"]');
         const element = document.getElementById('camera.zoom.label');
         input.min = capabilities.zoom.min;
         input.max = capabilities.zoom.max;
         input.step = capabilities.zoom.step;
         input.value = settings.zoom;
         input.oninput = function (event) {
            mediaStream.getVideoTracks()[0].applyConstraints({advanced: [{zoom: event.target.value}]});
         };
         input.hidden = false;
         element.hidden = false;
      }, 1000);
   };

      render()
      {
         const {style, classes, direction, allowAnnotations} = this.props;
         const {refresh, image, showExternal, width, height, marginHeight, marginWidth, isSaving} = this.state;

         return (
            <Grid container style={style} direction={direction}>
               {isSaving && (
                  <Grid container alignItems={'center'} justify={'center'} direction={'column'}>
                     <CircularProgress className={classes.saveProgressStyle}/>
                  </Grid>
               )}
               <Grid item resizable={true} className={classes.cameraStyle}>
                  {!image ? (
                     <div name={'Camera'} className={classes.annotationFrameStyle} >
                        <Camera
                           onTakePhoto={this.handleTakePhoto}
                           idealFacingMode={FACING_MODES.ENVIRONMENT}
                           idealResolution={{width: 640, height: 480}}
                           imageType={IMAGE_TYPES.JPG}
                           imageCompression={1}
                           isDisplayStartCameraError={true}
                           isFullscreen={false}
                           isImageMirror={false}
                           onCameraStart={this.handleCameraStart}
                        />
                        <Typography id={'camera.zoom.label'} gutterBottom hidden={true}/>
                        <input type='range' hidden={true}/>
                     </div>
                  ) : (
                     <div name={'Annotation Image'} className={classes.annotationFrameStyle2}
                          ref={ref => (this.mainRef = ref)}>
                        <div className={!showExternal ? classes.annotationFrameStyle : classes.annotationFrameStyle3}
                             style={{
                                width,
                                height,
                                padding: `${showExternal ? 0 : marginHeight}px ${showExternal ? 0 : marginWidth}px`
                             }}>
                           <Suspense fallback={<Loading isLoading/>}>
                              <Annotation
                                 src={image}
                                 alt='Image'
                                 disableAnnotation={!allowAnnotations || showExternal}
                                 disableOverlay={!allowAnnotations || showExternal}
                                 className={classes.annotationStyle}
                                 annotations={this.state.annotations}
                                 // type={PointSelector.TYPE}
                                 value={this.state.annotation || []}
                                 allowTouch={true}
                                 onChange={this.onChange}
                                 onSubmit={this.handleAnnotationSubmit}
                                 renderSelector={renderSelector}
                                 renderEditor={renderEditor}
                                 renderHighlight={renderHighlight}
                                 renderContent={RenderContent}
                                 onCreate={this.handleAnnotationSubmit}
                                 onUpdate={this.onUpdate}
                                 onDelete={this.onDelete}
                              />
                           </Suspense>
                        </div>
                     </div>
                  )}
                  <img key={'key' + refresh} id='image' alt='Camera' className={classes.photo} src={image}
                       style={{display: 'none'}}
                       ref={img => this.img = img} onError={(e) => {
                     // eslint-disable-next-line
                     this.props.placeHolderImage ? e.target.src = this.props.placeHolderImage : undefined
                  }}/>
               </Grid>
            </Grid>
         );
      }
   }

export default withStyles(styles)(Video);
