import React from "react";

export const DARK_MODE = 'darkMode';
export const LIGHT_MODE = 'lightMode';
export const AUTO_MODE = 'autoMode';

export default React.createContext({
   isDarkMode: false,
   appearanceSetting: AUTO_MODE,
   setAppearanceSetting: (appearanceSetting) => {},
});
