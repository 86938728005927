import Button from '@material-ui/core/Button';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary';
import {withStyles} from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import gql from 'graphql-tag';
import {find} from 'lodash';
import get from 'lodash/get';
import React, {Fragment, useState, useMemo} from 'react';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import useQueryFHG from '../../fhg/components/data/useQueryFHG';
import Grid from '../../fhg/components/Grid';
import Typography from '../../fhg/components/Typography';
import {cacheAdd} from '../../fhg/utils/DataUtil';
import {cacheDelete} from '../../fhg/utils/DataUtil';
import {scrollIntoViewOnly} from '../../fhg/utils/Utils';
import PerimeterConcernEdit from '../inspect/PerimeterConcernEdit';
import {PERIMETER_CONCERN_FRAGMENT} from '../Types';

const useStyles = makeStyles(theme => ({
   extraLargeLeftSpace: {
      marginLeft: theme.spacing(2),
   },
   actionButtonStyle: {
      minWidth: 'max-content',
   },
   buttonPanelStyle: {
      padding: theme.spacing(2),
      zIndex: 1000,
      position: 'sticky',
      bottom: 0,
      backgroundColor: theme.palette.background.default
   },
}));
export const ExpansionPanelSummary = withStyles({
   root: {
      // backgroundColor: '#',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
         minHeight: 56,
      },
   },
   content: {
      '&$expanded': {
         margin: '12px 0',
      },
   },
   expanded: {},
})(MuiExpansionPanelSummary);

export const ExpansionPanel = withStyles({
   root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
         borderBottom: 0,
      },
      '&:before': {
         display: 'none',
      },
      '&$expanded': {
         margin: 'auto',
      },
   },
   expanded: {},
})(MuiExpansionPanel);

export const ExpansionPanelDetails = withStyles(theme => ({
   root: {
      padding: theme.spacing(2),
   },
}))(MuiExpansionPanelDetails);

export const PERIMETER_CONCERN_CREATE = gql`
   mutation PerimeterConcernCreate($reportId: Int!, $comments: String, $distance: String, $perimeterConcernTypeId: Int, $priorityId: Int) {
      perimeterConcern:perimeterConcern_Create(perimeterConcern: {reportId: $reportId, comments: $comments, distance: $distance, perimeterConcernTypeId: $perimeterConcernTypeId, priorityId: $priorityId }) {
         ...perimeterConcernInfo
      }
   }
   ${PERIMETER_CONCERN_FRAGMENT}
`;

export const PERIMETER_CONCERN_UPDATE = gql`
   mutation PerimeterConcernUpdate($id: Int!, $reportId: Int!, $comments: String, $distance: String, $perimeterConcernTypeId: Int, $priorityId: Int) {
      perimeterConcern:perimeterConcern_Update(perimeterConcernId: $id, perimeterConcern: {reportId: $reportId, comments: $comments, distance: $distance, perimeterConcernTypeId: $perimeterConcernTypeId, priorityId: $priorityId }) {
         ...perimeterConcernInfo
      }
   }
   ${PERIMETER_CONCERN_FRAGMENT}
`;

export const PERIMETER_CONCERN_DELETE = gql`
   mutation perimeterConcernDelete($id: Int!)
   {
      perimeterConcern_Delete(perimeterConcernId: $id)
   }
`;

const PERIMETER_CONCERN_ALL_WHERE_QUERY = gql`
   query getPerimeterConcernAllWhere($reportId: [Int]) {
      perimeterConcerns:perimeterConcern_AllWhere(perimeterConcernSearch: {reportId: $reportId}) {
         ...perimeterConcernInfo
      }
   }
   ${PERIMETER_CONCERN_FRAGMENT}
`;

export const getPerimeterConcernsCacheQueries = (reportId) => {
   return reportId ?
      [{query: PERIMETER_CONCERN_ALL_WHERE_QUERY, variables: {reportId}, queryPath: 'perimeterConcerns'}] : [];
}

const PERIMETER_CONCERN_TYPE_ALL_QUERY = gql`
   query getPerimeterConcernTypeAll {
      perimeterConcernTypes:perimeterConcernType_All {
         id
         value:id
         name
         label:name
         isDeleted
      }
   }
`;

/**
 * Component To build the list of Perimeter Concerns.
 */
export default function PerimeterConcernsBuilder({report, isEditable,}) {
   const classes = useStyles();
   const [expanded, setExpanded] = useState();

   const {data, statusComponent} = useQueryFHG(PERIMETER_CONCERN_ALL_WHERE_QUERY, {variables: {reportId: report?.id}});
   const perimeterConcerns = data?.perimeterConcerns || []
   const [perimeterConcernDelete, deletePerimeterConcernStatusComponent] = useMutationFHG(PERIMETER_CONCERN_DELETE);
   const [perimeterConcernCreate, perimeterConcernCreateStatusComponent] = useMutationFHG(
      PERIMETER_CONCERN_CREATE, undefined,
      'perimeterConcernCreate');

   const {data: perimeterConcernTypesData, statusComponent: perimeterConcernTypesDataStatusComponent} = useQueryFHG(
      PERIMETER_CONCERN_TYPE_ALL_QUERY, {fetchPolicy: 'cache-first'});
   const perimeterConcernTypes = useMemo(() => get(perimeterConcernTypesData, 'perimeterConcernTypes'),
      [perimeterConcernTypesData]);
   const [isSaving, setIsSaving] = useState(false);

   const handleDelete = perimeterConcern => async event => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      try {
         setIsSaving(true);
         if (perimeterConcern?.id) {
            await perimeterConcernDelete({
               variables: {id: perimeterConcern.id},
               optimisticResponse: {perimeterConcern_Delete: 1},
               update: cacheDelete(getPerimeterConcernsCacheQueries(report?.id), perimeterConcern.id),
            });
         } else {
            console.log('Could not delete the perimeter concern that did not have an id', perimeterConcern);
         }
      } catch (error) {
         console.log(error);
      } finally {
         setIsSaving(false);
      }
   };

   /**
    * Handle expanding the panel.
    * @return {Function} The callback for the expanding.
    * @param id The id of the expanded panel.
    */
   const handleExpand = id => () => {
      setExpanded(id);
   };

   const handleAdd = async (event) => {
      if (event) {
         event.stopPropagation();
         event.preventDefault();
      }
      try {
         setIsSaving(true);
         const result = await perimeterConcernCreate({
            variables: {reportId: report?.id},
            update: cacheAdd(getPerimeterConcernsCacheQueries(report?.id), 'perimeterConcern'),
         });
         setExpanded(result.data?.perimeterConcern.id);
         scrollIntoViewOnly(`perimeterConcernDetails ${result.data?.perimeterConcern.id}`, 600);
      } catch (error) {
         console.log(error);
      } finally {
         setIsSaving(false);
      }
   };

   return (
      <Fragment>
         {deletePerimeterConcernStatusComponent || perimeterConcernCreateStatusComponent || statusComponent ||
         perimeterConcernTypesDataStatusComponent}
         {perimeterConcerns.map(perimeterConcern => {
            return (
               <ExpansionPanel key={`perimeterConcern ${perimeterConcern.id}`}
                               name={`perimeterConcern ${perimeterConcern.id}`}
                               expanded={expanded === perimeterConcern?.id}
                               onChange={handleExpand(perimeterConcern.id)}>
                  <ExpansionPanelSummary
                     key={`perimeterConcernSummary ${perimeterConcern.id}`}
                     name={`perimeterConcernSummary ${perimeterConcern.id}`}
                     aria-controls='panel1a-content'
                  >
                     <Grid container direction={'row'} wrap={'nowrap'} alignItems={'center'}
                           justify={'space-between'} overflow={'visible'}>
                        <Grid item resizable={true} overflow={'visible'}>
                           {!perimeterConcern?.id ? (
                              <Typography>New</Typography>
                           ) : (
                              <Fragment>
                                 <Typography>{find(perimeterConcernTypes,
                                    {id: perimeterConcern?.perimeterConcernTypeId})?.name}</Typography>
                                 <Typography
                                    className={classes.extraLargeLeftSpace}><b>Distance</b> {perimeterConcern?.distance}
                                 </Typography>
                                 <Typography
                                    className={classes.extraLargeLeftSpace}><b>Priority</b> {perimeterConcern?.priorityId}
                                 </Typography>
                                 <Typography
                                    className={classes.extraLargeLeftSpace}><b>Comment</b> {perimeterConcern?.comments}
                                 </Typography>
                              </Fragment>
                           )}
                        </Grid>
                        {perimeterConcern?.id && (
                           <Grid item resizable={false}>
                              <Button onClick={handleDelete(perimeterConcern)} color={'secondary'}
                                      className={classes.actionButtonStyle} disabled={isSaving || !isEditable}>
                                 <Typography color='inherit' id={'propertyQuestions.delete.button'}/>
                              </Button>
                           </Grid>
                        )}
                     </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails key={`perimeterConcernDetails ${perimeterConcern.id}`}
                                         name={`perimeterConcernDetails ${perimeterConcern.id}`}>
                     <PerimeterConcernEdit report={report} perimeterConcern={perimeterConcern}
                                           perimeterConcernTypes={perimeterConcernTypes}
                                           isEditable={!isSaving && isEditable}/>
                  </ExpansionPanelDetails>
               </ExpansionPanel>
            )
         })}
         <Grid item className={classes.buttonPanelStyle}>
            <Button key={'add'} onClick={handleAdd} color={'secondary'} disabled={isSaving || !isEditable}>
               <Typography color='inherit' noWrap id={'perimeterConcern.add.label'}/>
            </Button>
         </Grid>
      </Fragment>
   );
}
