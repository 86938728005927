import React from 'react';
import { useMemo } from 'react';
import { useRef } from 'react';
import { Tooltip, Marker } from 'react-leaflet';

export default function DraggableMarker({
  location,
  isDraggable = true,
  onDragEnd,
  onChange,
  label,
  showPopup,
  icon
}) {
  const markerRef = useRef(null);

  const [showTooltip, setShowTooltip] = React.useState(false);

  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        onChange?.(marker);
      },
    }),
    [],
  );

  return (
    <Marker
      ref={markerRef}
      draggable={isDraggable}
      eventHandlers={eventHandlers}
      position={location}
      onDragEnd={onDragEnd}
      icon={icon}
      onMouseOver={() => {
        setShowTooltip(true)
      }}
      onMouseOut={() => {
        setShowTooltip(false)
      }}
    >
      {showTooltip && label !== '-' && (
        <Tooltip direction="right" offset={[-8, -2]} opacity={1} permanent>
          <span>{label || 'Playground Location'}</span>
        </Tooltip>
      )}
    </Marker>
  );
}
