import MuiGrid from '@material-ui/core/Grid/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {Delete} from '@material-ui/icons';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SaveAlt from '@material-ui/icons/SaveAlt';
import PropTypes from 'prop-types';
import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {THUMBNAIL_SIZE, PLACEHOLDER_IMAGE} from '../../Constants';
import Grid from '../../fhg/components/Grid';
import CameraDialog from './CameraDialog';

const useStyles = makeStyles(theme => ({
   verticalThumbs: {
      height: '100%',
      width: THUMBNAIL_SIZE + 8,
      display: 'block',
      padding: '0 4px',
      marginLeft: '4px !important',
      marginTop: '0px !important',
      borderLeft: `1px solid ${theme.palette.divider}`,
   },
   horizontalThumbs: {
      height: THUMBNAIL_SIZE + 10,
      width: '100%',
      flexWrap: 'nowrap',
      flex: '0 0 auto',
      paddingTop: 4,
      marginLeft: '0px !important',
      marginTop: '4px !important',
      marginBottom: '7px !important',
      borderTop: `1px solid ${theme.palette.divider}`,
   },
   titleBar: {
      background:
         'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
   },
   moreTitleBar: {
      background: 'transparent',
      width: '100%',
   },
   titleText: {
      // fontSize: `${theme.size.font['item-title']}rem`,
      // lineHeight: `${theme.size.lineheight['item-title']}rem`,
      // letterSpacing: `${theme.size.letterspacing['item-title']}rem`,
   },
   actionIconStyle: {
      width: '100%',
   },
   titleWrap: {
      display: 'none',
   },
   buttonStyle: {
      backgroundColor: 'rgba(255,255,255,0.4)',
      '-webkit-filter': 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      filter: 'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2))',
      '&:hover': {
         backgroundColor: 'rgba(255,255,255,0.2) !important',
      },
   },

}));

function ThumbnailList({defaultImages, onDelete, onDownloadPhoto, onChange, allowAnnotations, isEditable}) {
   const [isCameraOpen, setCameraOpen] = useState(false);
   const [selectedImage, setSelectedImage] = useState();
   const [images, setImages] = useState([]);
   const classes = useStyles();

   useEffect(() => {
      setImages(defaultImages || []);
   }, [defaultImages]);

   const handleCameraOpen = () => {
      setSelectedImage(undefined);
      setCameraOpen(true);
   };

   const handleSelect = image => () => {
      if (image && image.index !== undefined) {
         setSelectedImage(image);
         setCameraOpen(true);
      }
   };

   const handleCameraClose = () => {
      setCameraOpen(false);
   };

   const handleCameraSubmit = (image, isNewImage, annotations) => {

      if (image) {
         if (!isNewImage) {
            selectedImage.src = image.src;
            if (image.blob) {
               selectedImage.blob = image.blob;
            }
         }
         onChange(image, isNewImage);
      }
      setSelectedImage(undefined);
      setCameraOpen(false);
   };

   const handleDeletePhoto = photo => () => {
      onDelete && onDelete(photo);
   };

   return (
      <Grid container direction={'row'} alignItems={'center'} overflow={'auto'}>
         <MuiGrid item>
            <GridList id='thumbnailListId' className={classes.horizontalThumbs} cols={images.length} cellHeight={THUMBNAIL_SIZE}>
               {isCameraOpen && (
                  <CameraDialog
                     onClose={handleCameraClose}
                     onSubmit={handleCameraSubmit}
                     image={selectedImage}
                     annotations={selectedImage && selectedImage.__annotations}
                     allowAnnotations={allowAnnotations}/>
               )}
               {images.map((image, index) => (
                  // eslint-disable-next-line
                  <GridListTile key={index} style={{position: 'relative'}}>
                     <img src={image.src} onClick={handleSelect(image)} alt='' style={{width: THUMBNAIL_SIZE, height: THUMBNAIL_SIZE}}
                          onError={(e) => {e.target.src = PLACEHOLDER_IMAGE}}/>
                     {image.src !== PLACEHOLDER_IMAGE && (
                        <GridListTileBar
                           titlePosition='top'
                           actionPosition='left'
                           className={classes.moreTitleBar}
                           classes={{actionIcon: classes.actionIconStyle, titleWrap: classes.titleWrap}}
                           // onClick={() => console.log(`Click on title bar - ${Date.now()}`)}
                           actionIcon={
                              <Grid container direction={'row'} justify={'space-between'}>
                                 {image.index !== undefined && ([
                                    <IconButton className={classes.buttonStyle} target={'_blank'} href={image.src}
                                       // key={`map-maker ${property.id}`}
                                                aria-label='Map Marker'
                                                onClick={onDownloadPhoto}
                                    >
                                       <SaveAlt/>
                                    </IconButton>,
                                    <IconButton aria-label='Property image' onClick={handleDeletePhoto(image)}
                                                className={classes.buttonStyle} disabled={!isEditable}>
                                       <Delete/>
                                    </IconButton>
                                 ])}
                              </Grid>
                           }
                        />
                     )}
                  </GridListTile>
               ))}
            </GridList>
         </MuiGrid>
         <MuiGrid item >
            <IconButton
               // key={`map-maker ${property.id}`}
               aria-label='Map Marker'
               disabled={!isEditable}
               onClick={handleCameraOpen}
            >
               <PhotoCameraIcon/>
            </IconButton>
         </MuiGrid>
      </Grid>
   );
}

ThumbnailList.propTypes = {
   thumbColumns: PropTypes.number,
   defaultImages: PropTypes.array,
   onDeletePhoto: PropTypes.func,
   onSelect: PropTypes.func,
   allowAnnotations: PropTypes.bool,
};

ThumbnailList.defaultProps = {
   thumbColumns: 4,
   allowAnnotations: true,
};

export default withRouter(ThumbnailList);
